import flatten from "lodash.flatten";
import get from "lodash.get";
import set from "lodash.set";
import uniq from "lodash.uniq";
import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { useQuery } from "react-query";
import { Link, useHistory, useParams } from "react-router-dom";
import * as api from "../../api";
import Banner from "../../components/Banner";
import Button from "../../components/Button";
import DescriptionText from "../../components/DescriptionText";
import Footer from "../../components/Footer";
import Form from "../../components/Form";
import H3 from "../../components/H3";
import InfoTooltip from "../../components/InfoTooltip";
import InputSelect from "../../components/InputSelect";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { ACTIONS, QUOTE_STATUSES, ROUTES } from "../../constants";
import useFormContext from "../../context/Form";
import { currencyFormatter, numberFormatter } from "../../formatters";
import { useAuth, useContractQuery, useModal, useQuoteQuery } from "../../hooks";
import WillBeReferredWarningModal from "../../modals/WillBeReferredWarningModal";
import TableRow from "./TableRow";

const getTotalBi = (locationsSchedule) =>
  locationsSchedule
    .map((item) => {
      const biAmount = get(item, "businessInterruption.amount", 0);

      return biAmount;
    })
    .reduce((prev, next) => prev + next, 0);

const ContractStepperSublimits = ({ contractData }) => {
  const { isBroker, tenants, checkPermissions } = useAuth(contractData);
  const { contractId, contractType } = useParams();
  const history = useHistory();
  const { modal, showModal, closeModal } = useModal();
  const formContext = useFormContext();
  const { handleSubmitContract, isSubmittingContract } = useContractQuery();
  const { handleSubmitQuote, isSubmittingQuote } = useQuoteQuery();

  const wouldBeReferredData = get(contractData, "wouldBeReferred", []);
  const wouldBeReferred = wouldBeReferredData.length > 0;

  const prevStepPath = compile(ROUTES.CONTRACT_STEPPER_REQUIREMENTS)({ contractId, contractType });
  const nextPath = compile(ROUTES.CONTRACT)({ contractId, contractType });

  const sublimitOptions = get(contractData, "product.sublimitOptions", {});

  const groupedSublimitTypes = get(sublimitOptions, "allSublimitTypes", { pd: [], bi: [] });
  const sublimitTypes = [...groupedSublimitTypes.pd, ...groupedSublimitTypes.bi];

  const groupedSublimitRates = get(sublimitOptions, "allSublimitRates", []);

  const isMicro = get(contractData, "product.businessLevel") === "Micro";

  const locationsSchedule = get(contractData, "product.locationsSchedule", []);
  const totalBi = getTotalBi(locationsSchedule);

  /* https://trello.com/c/jaJRgfvG/180-263-bi-tiv-sublimit-rule */
  const scenario21 = isMicro && totalBi < groupedSublimitRates[0].amount;
  const scenario22 = isMicro && totalBi >= groupedSublimitRates[0].amount;
  const isSpecialScenario = scenario21 || scenario22;
  const isSingle = Object.keys(groupedSublimitRates).length === 1;

  const sublimitRates = groupedSublimitRates.reduce((prev, next) => {
    const rates = [...get(next, "rates.pd", []), ...get(next, "rates.bi", [])];
    const result = rates.reduce(
      (prevRate, nextRate) => ({ ...prevRate, [nextRate.type]: get(nextRate, "rate.amount") }),
      {}
    );

    return { ...prev, [next.amount]: result };
  }, {});

  const mainSublimits = Object.keys(sublimitRates);
  const mainSublimitsCount = mainSublimits.length;

  const deductiblesData = get(sublimitOptions, "deductibles", []);

  const existingSublimits = get(contractData, "product.sublimits", { pd: [], bi: [] });
  const flattenedExistingSublimits = [...existingSublimits.pd, ...existingSublimits.bi];

  const activeQuote =
    contractType === "quotes"
      ? get(contractData, "quotes", []).find((item) => item.status !== QUOTE_STATUSES.ARCHIVED)
      : get(contractData, "activeEndorsement.quote");

  const existingDeductible = get(activeQuote, "deductible.amount");

  const [formValues, setFormValues] = useState({ deductible: existingDeductible });
  const hasDeductible = Boolean(formValues.deductible);
  const deductible = { amount: formValues.deductible, currency: "GBP" };

  const defaultSpecial = { pd: [], bi: [] };

  groupedSublimitRates.forEach((item) => {
    item.rates.pd.forEach((rate) => {
      const newitem = {
        limit: { amount: item.amount, currency: "GBP" },
        type: rate.type,
      };

      set(defaultSpecial, "pd", [...get(defaultSpecial, "pd"), newitem]);
    });
    item.rates.bi.forEach((rate) => {
      const newitem = {
        limit: { amount: item.amount, currency: "GBP" },
        type: rate.type,
      };

      set(defaultSpecial, "bi", [...get(defaultSpecial, "bi"), newitem]);
    });
  });

  const defaultSelected =
    isSpecialScenario || isSingle
      ? [...defaultSpecial.pd, ...defaultSpecial.bi]
      : flattenedExistingSublimits;

  /* selectedCovers */

  const [selectedCovers, setSelectedCovers] = useState(defaultSelected);

  const parsedSelectedCovers = selectedCovers.map((item) => ({
    ...item,
    limit: { ...item.limit, amount: parseInt(item.limit.amount, 10) },
  }));

  const mappedSelectedCovers = { pd: [], bi: [] };

  parsedSelectedCovers.forEach((item) => {
    if (groupedSublimitTypes.pd.includes(item.type)) {
      set(mappedSelectedCovers, "pd", [...get(mappedSelectedCovers, "pd", []), item]);
    }

    if (groupedSublimitTypes.bi.includes(item.type)) {
      set(mappedSelectedCovers, "bi", [...get(mappedSelectedCovers, "bi", []), item]);
    }
  });

  const applicableRates = groupedSublimitRates.map((item) => {
    return [...item.rates.pd.map((a) => a.type), ...item.rates.bi.map((a) => a.type)];
  });

  const applicableTypes = uniq(flatten(applicableRates));

  const hasAllSelectedCovers = applicableTypes.reduce((prev, next) => {
    return Boolean(selectedCovers.find((item) => item.type === next));
  }, false);

  const isFormValid = hasAllSelectedCovers && hasDeductible;
  const contractAddtlPremiumQuery = useQuery(
    ["addtlPremium", { contractId, data: { sublimits: mappedSelectedCovers, deductible } }],
    api.getContractAddtlPremiums,
    { enabled: isFormValid }
  );

  const totalAddtlAmount = get(contractAddtlPremiumQuery, "data.data.totalSublimitRate");
  const netAmount = get(contractAddtlPremiumQuery, "data.data.net");

  const totalAddtl = totalAddtlAmount >= 0 && currencyFormatter(totalAddtlAmount);
  const net = netAmount >= 0 && currencyFormatter(netAmount);

  const isSubmitDisabled =
    !isFormValid ||
    contractAddtlPremiumQuery.isLoading ||
    isSubmittingContract ||
    isSubmittingQuote;

  const handleSublimitChange = ([mainSublimitAmount, sublimitType]) => {
    const filteredSelectedCovers = selectedCovers.filter((item) => item.type !== sublimitType);
    const newSelectedCover = {
      limit: { amount: mainSublimitAmount, currency: "GBP" },
      type: sublimitType,
    };
    const result = [...filteredSelectedCovers, newSelectedCover];

    formContext.setValue({ isDirty: true });

    setSelectedCovers(result);
  };

  const handleFormSubmit = async () => {
    const tenant = tenants.find((item) => item.id === contractData.tenantId);
    const product = get(tenant, "products[0]");
    const commissionPercentage = Number(product.commission);

    await handleSubmitContract({ sublimits: mappedSelectedCovers }, contractData);
    await handleSubmitQuote({ commissionPercentage, deductible }, activeQuote);

    if (contractType === "endorsements") {
      const nextEndoPath = compile(ROUTES.CONTRACT)({
        contractId,
        contractType: "policies",
        tab: "endorsements",
      });

      return history.push(nextEndoPath, { isForced: true });
    }

    return history.push(nextPath, { isForced: true });
  };

  const handleBeforeFormSubmit = () => {
    if (isBroker && wouldBeReferred) {
      return showModal("WillBeReferredWarningModal");
    }

    return handleFormSubmit();
  };

  return (
    <>
      {modal === "WillBeReferredWarningModal" && (
        <WillBeReferredWarningModal
          contractData={contractData}
          handleClose={closeModal}
          handleConfirm={handleFormSubmit}
          isSubmitDisabled={isSubmitDisabled}
        />
      )}

      {isBroker && wouldBeReferred && (
        <Banner headingText="Important notice" className="mb-6" color="orange">
          <p>
            This quote will be referred to the underwriter. Please submit your coverage requirements
            and continue.
          </p>
        </Banner>
      )}

      <Form onSubmit={handleBeforeFormSubmit} hasContext>
        <H3 className="mb-6" id="deductibleLabel">
          Deductible
        </H3>

        <InputSelect
          aria-labelledby="deductibleLabel"
          className="max-w-xl mb-6"
          descriptionText="The amount the insurer will deduct from each occurrence before paying up to the policy limits. The deductible amount specified will be subtracted from each covered loss when determining the amount of the insured's loss recovery."
          formatter={currencyFormatter}
          initialSelectedItem={formValues.deductible}
          isReadOnly={!checkPermissions(ACTIONS.EDIT_DEDUCTIBLE)}
          isRequired
          items={deductiblesData}
          name="deductible"
          onSelect={(value) => setFormValues({ ...formValues, deductible: value })}
        />

        <H3 className="mb-3">Sublimits</H3>

        <DescriptionText className="mb-6 max-w-xl">
          Sublimits are any one occurrence and in the aggregate
        </DescriptionText>

        <div className="max-w-4xl mb-8">
          <Table>
            <Table.Head>
              <Table.Header>Sublimits</Table.Header>
              <Table.Header colSpan={mainSublimitsCount}>Cover amount</Table.Header>
            </Table.Head>

            <Table.Body>
              {!isSpecialScenario && (
                <>
                  <Table.Row className="border border-gray-500">
                    <Table.Data className="border border-gray-500 w-84" />

                    {mainSublimits.map((mainSublimitAmount) => (
                      <Table.Data
                        className="transition-colors duration-75 ease-in border border-gray-500 w-48 text-center"
                        key={mainSublimitAmount}
                      >
                        <H3>{numberFormatter(mainSublimitAmount)}</H3>
                      </Table.Data>
                    ))}
                  </Table.Row>

                  {sublimitTypes.map((sublimitType, sublimitTypeIndex) => (
                    <TableRow
                      checkPermissions={checkPermissions}
                      handleSublimitChange={handleSublimitChange}
                      isSpecialScenario={isSpecialScenario}
                      key={sublimitType}
                      mainSublimits={mainSublimits}
                      selectedCovers={selectedCovers}
                      sublimitRates={sublimitRates}
                      sublimitType={sublimitType}
                      sublimitTypeIndex={sublimitTypeIndex}
                    />
                  ))}
                </>
              )}

              {isSpecialScenario &&
                groupedSublimitRates
                  .slice()
                  .reverse()
                  .map((item) => (
                    <Fragment key={item.amount}>
                      <Table.Row className="border border-gray-500">
                        <Table.Data className="border border-gray-500 w-84" />

                        <Table.Data className="border border-gray-500 w-48 text-center">
                          <H3>{numberFormatter(item.amount)}</H3>
                        </Table.Data>
                      </Table.Row>

                      {[...item.rates.pd, ...item.rates.bi].map((rate, sublimitTypeIndex) => (
                        <TableRow
                          checkPermissions={checkPermissions}
                          handleSublimitChange={handleSublimitChange}
                          isSpecialScenario={isSpecialScenario}
                          key={rate.type}
                          mainSublimits={[item.amount]}
                          selectedCovers={selectedCovers}
                          sublimitRates={sublimitRates}
                          sublimitType={rate.type}
                          sublimitTypeIndex={sublimitTypeIndex}
                        />
                      ))}
                    </Fragment>
                  ))}

              <Table.Row>
                <Table.Data className="w-84" />
                <Table.Data className="border border-gray-500" colSpan={mainSublimitsCount}>
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      <p>Additional sublimit premium</p>
                    </div>
                    <>
                      {contractAddtlPremiumQuery.isLoading && <Loader size="small" />}

                      {!contractAddtlPremiumQuery.isLoading && !isFormValid && <p>Choose cover</p>}

                      {!contractAddtlPremiumQuery.isLoading && isFormValid && (
                        <p>{checkPermissions(ACTIONS.VIEW_SUBLIMITS) ? totalAddtl : "--"}</p>
                      )}
                    </>
                  </div>
                </Table.Data>
              </Table.Row>

              <Table.Row>
                <Table.Data className="w-84" />
                <Table.Data className="w-48 border border-gray-500" colSpan={mainSublimitsCount}>
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="flex items-center">
                        <p className="font-semibold">Net premium</p>
                      </div>

                      <p className="text-sm">(excluding tax & commission)</p>
                    </div>
                    <>
                      {contractAddtlPremiumQuery.isLoading && <Loader size="small" />}

                      {!contractAddtlPremiumQuery.isLoading && !isFormValid && (
                        <p className="font-semibold">Choose cover</p>
                      )}

                      {!contractAddtlPremiumQuery.isLoading && isFormValid && (
                        <div className="flex items-center">
                          <p className="font-semibold">
                            {checkPermissions(ACTIONS.VIEW_SUBLIMITS) ? net : "--"}
                          </p>

                          {netAmount <= 50 && (
                            <InfoTooltip
                              content="The minimum discounted premium is £50.00"
                              className="ml-2"
                            />
                          )}
                        </div>
                      )}
                    </>
                  </div>
                </Table.Data>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>

        <Footer>
          <div className="flex">
            <Button as={Link} to={ROUTES.HOME} className="mr-4">
              Exit
            </Button>

            <Button as={Link} to={prevStepPath}>
              Go back
            </Button>
          </div>

          <Button isDisabled={isSubmitDisabled} kind="primary" type="submit">
            Save
          </Button>
        </Footer>
      </Form>
    </>
  );
};

ContractStepperSublimits.propTypes = {
  contractData: PropTypes.object.isRequired,
};

export default ContractStepperSublimits;
