import PropTypes from "prop-types";
import React from "react";
import Card from "../../components/Card";
import InputCheckbox from "../../components/InputCheckbox";
import { ACTIONS } from "../../constants";
import { useAuth } from "../../hooks";

const FlagPolicy = ({ handleAction, contractData, isLoading }) => {
  const { checkPermissions, isBroker } = useAuth(contractData);

  return (
    <Card headingText="Flag policy">
      <div className="px-4 py-6 -mx-1">
        {(checkPermissions(ACTIONS.EDIT_CLAIM_FLAG) ||
          checkPermissions(ACTIONS.VIEW_CLAIM_FLAG)) && (
          <div className="mb-4">
            <InputCheckbox
              labelText="Active claim against policy"
              name="claimed"
              isChecked={contractData.claimed}
              isDisabled={isLoading || !checkPermissions(ACTIONS.EDIT_CLAIM_FLAG)}
              onChange={(event) =>
                handleAction({ type: ACTIONS.EDIT_CLAIM_FLAG, isChecked: event.target.checked })
              }
            />
          </div>
        )}

        {(checkPermissions(ACTIONS.EDIT_PREMIUM_PAID_FLAG) ||
          checkPermissions(ACTIONS.VIEW_PREMIUM_PAID_FLAG)) && (
          <div>
            <InputCheckbox
              labelText="Premium paid"
              name="premiumPaid"
              isChecked={contractData.premiumPaid}
              isDisabled={
                isLoading ||
                !checkPermissions(ACTIONS.EDIT_PREMIUM_PAID_FLAG) ||
                (isBroker && contractData.premiumPaid)
              }
              onChange={(event) =>
                handleAction({
                  type: ACTIONS.EDIT_PREMIUM_PAID_FLAG,
                  isChecked: event.target.checked,
                })
              }
            />
          </div>
        )}
      </div>
    </Card>
  );
};

FlagPolicy.propTypes = {
  contractData: PropTypes.object.isRequired,
  handleAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default FlagPolicy;
