import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";
import Table from "../components/Table";
import TableDataPoint from "../components/TableDataPoint";
import { QUOTE_STATUSES } from "../constants";
import { prepareData } from "../helpers";
import { useAuth, useQuoteQuery } from "../hooks";
import { priceDataPointsMap } from "../modules/ContractDetails/config";

const BindEndorsementPriceModal = ({ handleClose, contractData, quoteData, quoteIndex }) => {
  const { checkPermissions } = useAuth(contractData);

  const { handleSubmitQuote, isSubmittingQuote } = useQuoteQuery();

  const data = prepareData({
    checkPermissions,
    contractData,
    dataPoints: priceDataPointsMap,
    params: { index: quoteIndex },
  });

  const handleClick = async () => {
    await handleSubmitQuote({ status: QUOTE_STATUSES.BOUND }, quoteData);

    handleClose();
  };

  return (
    <Modal handleClose={handleClose} headingText={`Bind price - ${quoteData.ref}`}>
      <div className="p-6 w-160">
        <Table className="mb-4">
          <Table.Body>
            <TableDataPoint {...data.deductible} />
            <TableDataPoint {...data.gross} isStriped />
            <TableDataPoint {...data.commission} />
            <TableDataPoint {...data.ipt} isStriped />
            <TableDataPoint {...data.total} />
          </Table.Body>
        </Table>

        <div className="flex">
          <Button
            kind="primary"
            className="mr-4"
            isDisabled={isSubmittingQuote}
            onClick={handleClick}
          >
            Bind quote
          </Button>

          <Button kind="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

BindEndorsementPriceModal.propTypes = {
  contractData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  quoteData: PropTypes.object.isRequired,
  quoteIndex: PropTypes.number.isRequired,
};

export default BindEndorsementPriceModal;
