import clsx from "clsx";
import get from "lodash.get";
import isFinite from "lodash.isfinite";
import moment from "moment";
import React from "react";

export const currencyFormatter = (values) => {
  const [amount, currency] = Array.isArray(values)
    ? values
    : [values.amount !== undefined ? values.amount : values, values.currency];
  const parsedAmount = Number.parseFloat(amount);

  if (!isFinite(parsedAmount)) {
    return false;
  }

  return Number(parsedAmount).toLocaleString("en-GB", {
    style: "currency",
    currency: currency || "GBP",
    currencyDisplay: "symbol",
  });
};

export const signedCurrencyFormatter = (values) => {
  const [amount, currency] = Array.isArray(values)
    ? values
    : [values.amount !== undefined ? values.amount : values, values.currency];
  const parsedAmount = Number.parseFloat(amount);
  const isPositive = parsedAmount > 0;
  const prefix = isPositive ? "+" : "";

  if (!isFinite(parsedAmount)) {
    return false;
  }

  const formatted = Number(parsedAmount).toLocaleString("en-GB", {
    style: "currency",
    currency: currency || "GBP",
    currencyDisplay: "symbol",
  });

  return (
    <span className={clsx({ "text-green-1000": isPositive, "text-red-1000": !isPositive })}>
      {prefix}
      {formatted}
    </span>
  );
};

export const numberFormatter = (value) => {
  const parsedAmount = Number.parseFloat(value);

  if (!isFinite(parsedAmount)) {
    return value;
  }

  return Intl.NumberFormat("en-GB", {
    notation: "compact",
    compactDisplay: "short",
    style: "currency",
    currency: "GBP",
  }).format(value);
};

export const commissionFormatter = ([amount, currency, commissionPercentage]) => {
  if (!isFinite(amount)) {
    return false;
  }

  return `${currencyFormatter([amount, currency])} @ ${commissionPercentage}%`;
};

export const coverTypeFormatter = ({ isCommercial }) => (values) => {
  const [type] = values;

  if (type === "BusinessInterruption") {
    if (isCommercial) {
      return "Property Damage and Business Interruption";
    }

    if (!isCommercial) {
      return "Property Damage and Rental Income";
    }
  }

  return "Property Damage";
};

export const addressFormatter = (separator) => (values) => values.filter(Boolean).join(separator);

export const dateFormatter = (values) => {
  const [date] = Array.isArray(values) ? values : [values];

  return moment.utc(date).format("DD-MM-YYYY");
};

export const postCodeFormatter = (values) => {
  const [postCode] = Array.isArray(values) ? values : [values];

  /* Replace whitespace with non breaking space */
  return postCode.split(" ").join("\u00a0");
};

export const fullNameFormatter = (values) => {
  const [firstName, lastName] = Array.isArray(values)
    ? values
    : [values.firstName, values.lastName];

  return `${firstName} ${lastName}`;
};

export const insuredFormatter = (insured) => {
  if (insured.Individual) {
    return fullNameFormatter(get(insured, "Individual"));
  }

  if (insured.Company) {
    return get(insured, "Company.name");
  }

  return "Cannot find insured";
};
