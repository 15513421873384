import get from "lodash.get";
import unset from "lodash.unset";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import * as api from "../../api";
import Button from "../../components/Button";
import Fieldset from "../../components/Fieldset";
import Footer from "../../components/Footer";
import Form from "../../components/Form";
import H3 from "../../components/H3";
import InputAddress from "../../components/InputAddress";
import InputDate from "../../components/InputDate";
import InputText from "../../components/InputText";
import { ROUTES } from "../../constants";
import { createIndividualSchema } from "./schema";

const IndividualCreateForm = ({ initialValues, submitInsuredForm, isSubmittingContract }) => {
  const [isAddressFormVisible, setIsAddressFormVisible] = useState();
  const [formValues, setFormValues] = useState(initialValues);
  const [createIndividual, createIndividualQuery] = useMutation(api.createIndividual);
  const isFormValid = createIndividualSchema.isValidSync({
    ...formValues,
    dob: new Date(formValues.year, formValues.month - 1, formValues.day),
  });

  const handlePlaceChange = (newPlace) => {
    setFormValues({
      ...formValues,
      ...newPlace,
      addressLine1: newPlace.line1,
      addressLine2: newPlace.line2,
    });
    setIsAddressFormVisible(true);
  };

  const handleChange = (event) =>
    setFormValues({ ...formValues, [event.target.name]: event.target.value });

  const handleFormSubmit = async () => {
    const castValues = createIndividualSchema.cast(formValues);
    const { day, month, year } = castValues;
    const dob = moment({ day, month: parseInt(month, 10) - 1, year }).format("DD/MM/YYYY");

    if (castValues.addressLine2 === "") {
      unset(castValues, "addressLine2");
    }

    const createIndividualRes = await createIndividual({ data: { ...castValues, dob } });
    const individualId = get(createIndividualRes, "data");

    if (individualId) {
      submitInsuredForm({ IndividualRef: individualId });
    }

    return false;
  };

  return (
    <div className="w-160">
      <Form id="createIndividualForm" onSubmit={handleFormSubmit} hasContext>
        <H3 className="mb-6">Create a new individual</H3>

        <Fieldset form="insuredForm" name="individualDetails" hasContext>
          <InputText
            className="mb-6"
            isRequired
            labelText="First Name"
            name="firstName"
            onChange={handleChange}
            value={formValues.firstName}
          />

          <InputText
            className="mb-6"
            isRequired
            labelText="Last Name"
            name="lastName"
            onChange={handleChange}
            value={formValues.lastName}
          />

          <InputDate
            className="mb-6"
            descriptionText="Format: DD/MM/YYYY"
            errors={get(createIndividualQuery, "error.response.data.errors.dob")}
            isRequired
            labelText="Date of Birth"
            name="dob"
            onChange={handleChange}
            placeholderText={["DD", "MM", "YYYY"]}
            values={formValues}
          />
        </Fieldset>

        <H3 className="mb-6">Address details</H3>

        <InputAddress
          className="mb-6"
          labelText="Search for address"
          name="addressSearch"
          onPlaceChange={handlePlaceChange}
          placeholderText="Start typing here"
        />

        {!isAddressFormVisible && (
          <Button kind="primary" onClick={() => setIsAddressFormVisible(true)}>
            Input manually
          </Button>
        )}

        {isAddressFormVisible && (
          <Fieldset form="insuredForm" name="individualAddress" hasContext>
            <InputText
              className="mb-6"
              isRequired
              labelText="Address line 1"
              name="addressLine1"
              onChange={handleChange}
              value={formValues.addressLine1}
            />

            <InputText
              className="mb-6"
              labelText="Address line 2 (optional)"
              name="addressLine2"
              onChange={handleChange}
              value={formValues.addressLine2}
            />

            <InputText
              className="mb-6"
              isRequired
              labelText="Town / City"
              name="city"
              onChange={handleChange}
              value={formValues.city}
            />

            <InputText
              className="mb-6"
              errors={get(createIndividualQuery, "error.postCode")}
              isRequired
              labelText="Postal code"
              name="postCode"
              onChange={handleChange}
              value={formValues.postCode}
            />

            <InputText
              className="mb-6"
              isRequired
              labelText="County"
              name="county"
              onChange={handleChange}
              value={formValues.county}
            />

            <input
              name="country"
              onChange={handleChange}
              type="hidden"
              value={formValues.country}
            />
          </Fieldset>
        )}

        <Footer>
          <Button as={Link} to={ROUTES.HOME}>
            Exit
          </Button>

          <Button
            isDisabled={!isFormValid || createIndividualQuery.isLoading || isSubmittingContract}
            kind="primary"
            type="submit"
          >
            Save
          </Button>
        </Footer>
      </Form>
    </div>
  );
};

IndividualCreateForm.propTypes = {
  initialValues: PropTypes.object,
  isSubmittingContract: PropTypes.bool,
  submitInsuredForm: PropTypes.func,
};

IndividualCreateForm.defaultProps = {
  initialValues: undefined,
  isSubmittingContract: undefined,
  submitInsuredForm: undefined,
};

export default IndividualCreateForm;
