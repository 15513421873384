import moment from "moment";
import "moment/locale/en-gb";
import PropTypes from "prop-types";
import React from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import MomentLocaleUtils, { formatDate, parseDate } from "react-day-picker/moment";
import Input from "./Input";
import "./InputCalendar.css";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const InputCalendar = ({
  className,
  dayPickerProps,
  descriptionText,
  errors,
  id,
  isReadOnly,
  isRequired,
  labelText,
  name,
  onChange,
  placeholder,
  size,
  value,
  format,
  ...props
}) => {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.includes("android");

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      labelText={labelText}
      iconName="calendar"
      name={name}
      {...props}
    >
      {!isReadOnly && (
        <div className="relative">
          {isAndroid && (
            <Input
              className="aui-input-date"
              type="date"
              placeholder="DD/MM/YYYY"
              onChange={(event) => onChange(moment(event.target.value, "YYYY-MM-DD").toDate())}
              value={moment(value).format("YYYY-MM-DD")}
            />
          )}

          {!isAndroid && (
            <DayPickerInput
              component={Input}
              dayPickerProps={{
                locale: "en-gb",
                localeUtils: MomentLocaleUtils,
                showOutsideDays: true,
                ...dayPickerProps,
              }}
              format={format}
              formatDate={formatDate}
              onDayChange={(date) => onChange(date, name)}
              parseDate={parseDate}
              placeholder={placeholder}
              value={value}
              inputProps={{ name, autocomplete: "chrome-off" }}
            />
          )}
        </div>
      )}

      {isReadOnly && (
        <InputReadOnly {...props} defaultValue={value && moment.utc(value).format("DD/MM/YYYY")} />
      )}
    </InputField>
  );
};

InputCalendar.propTypes = {
  className: PropTypes.string,
  dayPickerProps: PropTypes.object,
  descriptionText: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal"]),
  value: PropTypes.instanceOf(Date),
  format: PropTypes.string,
};

InputCalendar.defaultProps = {
  format: "DD/MM/YYYY",
  className: undefined,
  dayPickerProps: {},
  descriptionText: undefined,
  errors: undefined,
  id: undefined,
  isReadOnly: undefined,
  isRequired: undefined,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
  placeholder: undefined,
  size: "normal",
  value: undefined,
};

export default InputCalendar;
