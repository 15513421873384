import get from "lodash.get";
import set from "lodash.set";
import moment from "moment";
import PropTypes from "prop-types";
import React, { memo, useState } from "react";
import Accordion from "../../components/Accordion";
import Banner from "../../components/Banner";
import Button from "../../components/Button";
import H3 from "../../components/H3";
import { ACTIONS, POLICY_STATUSES, QUOTE_STATUSES, STATUSES } from "../../constants";
import { currencyFormatter } from "../../formatters";
import { useAuth, useContractQuery, useModal, useQuoteQuery } from "../../hooks";
import AcceptTechnicalPriceModal from "../../modals/AcceptTechnicalPriceModal";
import BindEndorsementPriceModal from "../../modals/BindEndorsementPriceModal";
import BindPriceModal from "../../modals/BindPriceModal";
import ChangeCommissionModal from "../../modals/ChangeCommissionModal";
import CreateCommercialPriceModal from "../../modals/CreateCommercialPriceModal";
import DeclineToQuoteModal from "../../modals/DeclineToQuoteModal";
import FlagPolicyModal from "../../modals/FlagPolicyModal";
import RejectLastPriceModal from "../../modals/RejectLastPriceModal";
import RemovePolicyFlagModal from "../../modals/RemovePolicyFlagModal";
import CoverageSummary from "./CoverageSummary";
import EndorsementPrice from "./EndorsementPrice";
import FlagPolicy from "./FlagPolicy";
import InsuredDetails from "./InsuredDetails";
import LocationSchedule from "./LocationSchedule";
import NewPrice from "./NewPrice";
import PolicyDocuments from "./PolicyDocuments";
import Price from "./Price";
import RejectedPrices from "./RejectedPrices";
import Sublimits from "./Sublimits";

const ContractDetails = ({
  contractData,
  id,
  isCurrent,
  isEndorsement,
  patchData,
  refData,
  showDiff,
}) => {
  const { isBroker, checkPermissions } = useAuth(contractData);
  const { modal, showModal, closeModal } = useModal();
  const [selectedQuoteId, setSelectedQuoteId] = useState();
  const [selectedAction, setSelectedAction] = useState();
  const { handleSubmitQuote } = useQuoteQuery();
  const { handleSubmitContract, isSubmittingContract } = useContractQuery();

  const contractId = contractData.id;
  const { status, endorsementStatus, cancellationDate } = contractData;
  const isPolicy = POLICY_STATUSES.includes(status);
  const isBound = status === STATUSES.BOUND;
  const isInforce = status === STATUSES.INFORCE;
  const isCancelled = status === STATUSES.CANCELLED;
  const willBeCancelled = (isBound || isInforce) && cancellationDate;
  const isReferred = status === STATUSES.REFERRED;
  const isNtu = status === STATUSES.NOT_TAKEN_UP;
  const isDtq = status === STATUSES.DECLINE_TO_QUOTE;
  const isSubmitted = status === STATUSES.SUBMITTED;
  const isReferredQuoted = status === STATUSES.REFERRED_QUOTED;
  const isEndorsementQuoted = endorsementStatus === STATUSES.ENDORSEMENT_QUOTED;
  const quotesData = get(contractData, "quotes", []);
  const hasQuotes = quotesData.length > 0;
  const hasActiveClaim = contractData.claimed;
  const hasPremiumPaid = contractData.premiumPaid;

  const checkSubmitted = (quoteData) => quoteData.status === QUOTE_STATUSES.SUBMITTED;
  const checkBound = (quoteData) => quoteData.status === QUOTE_STATUSES.BOUND;
  const checkNonArchived = (quoteData) => quoteData.status !== QUOTE_STATUSES.ARCHIVED;

  const totalNonArchivedQuotesCount = quotesData.filter(checkNonArchived).length;
  const hasActiveQuotes = totalNonArchivedQuotesCount > 0;

  const submittedQuotesData = quotesData.filter(checkSubmitted);
  const selectedQuoteData = quotesData.find((quote) => quote.id === selectedQuoteId);
  const selectedQuoteIndex = quotesData.findIndex((quote) => quote.id === selectedQuoteId);
  const wouldBeReferred = get(contractData, "wouldBeReferred", []);
  const hasRefund = Boolean(get(contractData, "refund.amount"));
  const hasCommercialPrice = Boolean(contractData.commercial);
  const hasTechnicalPrice = !hasCommercialPrice;

  const sublimitOptions = get(contractData, "product.sublimitOptions", {});
  const deductiblesData = get(sublimitOptions, "deductibles", []);

  const handleAction = async (action) => {
    const flags = [ACTIONS.EDIT_CLAIM_FLAG, ACTIONS.EDIT_PREMIUM_PAID_FLAG];

    setSelectedQuoteId(action.quoteId);
    setSelectedAction(action);

    if (flags.includes(action.type) && !action.isChecked) {
      showModal("RemovePolicyFlagModal");
    }

    if (flags.includes(action.type) && action.isChecked) {
      showModal("FlagPolicyModal");
    }

    if (action.type === ACTIONS.CHANGE_COMMISSION) {
      showModal("ChangeCommissionModal");
    }

    if (action.type === ACTIONS.ACCEPT_TECHNICAL_PRICE) {
      showModal("AcceptTechnicalPriceModal");
    }

    if (action.type === ACTIONS.DECLINE_TO_QUOTE) {
      showModal("DeclineToQuoteModal");
    }

    if (action.type === ACTIONS.BIND_PRICE) {
      showModal("BindPriceModal");
    }

    if (action.type === ACTIONS.BIND_ENDORSEMENT_PRICE) {
      showModal("BindEndorsementPriceModal");
    }

    if (action.type === ACTIONS.CREATE_COMMERCIAL_PRICE) {
      showModal("CreateCommercialPriceModal");
    }

    if (action.type === ACTIONS.REJECT_PRICE) {
      if (submittedQuotesData.length === 1) {
        return showModal("RejectLastPriceModal");
      }

      return handleSubmitQuote({ status: QUOTE_STATUSES.NOT_TAKEN_UP }, action.quoteData);
    }

    if (action.type === ACTIONS.REJECT_ENDORSEMENT_PRICE) {
      return showModal("RejectLastPriceModal");
    }

    return false;
  };

  const handleConfirmFlagChange = async () => {
    const formData = {};

    if (selectedAction.type === ACTIONS.EDIT_CLAIM_FLAG) {
      set(formData, "claimFlag", !hasActiveClaim);
    }

    if (selectedAction.type === ACTIONS.EDIT_PREMIUM_PAID_FLAG) {
      set(formData, "premiumPaidFlag", !hasPremiumPaid);
    }

    await handleSubmitContract(formData, contractData);

    closeModal();
  };

  return (
    <>
      {modal === "AcceptTechnicalPriceModal" && (
        <AcceptTechnicalPriceModal
          contractData={contractData}
          handleClose={closeModal}
          quoteData={selectedQuoteData}
        />
      )}

      {modal === "CreateCommercialPriceModal" && (
        <CreateCommercialPriceModal handleClose={closeModal} contractData={contractData} />
      )}

      {modal === "BindPriceModal" && (
        <BindPriceModal
          contractData={contractData}
          handleClose={closeModal}
          quoteData={selectedQuoteData}
          quoteIndex={selectedQuoteIndex}
        />
      )}

      {modal === "BindEndorsementPriceModal" && (
        <BindEndorsementPriceModal
          contractData={contractData}
          handleClose={closeModal}
          quoteData={selectedQuoteData}
          quoteIndex={selectedQuoteIndex}
        />
      )}

      {modal === "ChangeCommissionModal" && (
        <ChangeCommissionModal
          contractData={contractData}
          handleClose={closeModal}
          quoteData={selectedQuoteData}
          quoteIndex={selectedQuoteIndex}
        />
      )}

      {modal === "RejectLastPriceModal" && (
        <RejectLastPriceModal
          handleClose={closeModal}
          contractData={contractData}
          quoteData={selectedQuoteData}
          refData={refData}
        />
      )}

      {modal === "DeclineToQuoteModal" && (
        <DeclineToQuoteModal
          contractData={contractData}
          handleClose={closeModal}
          refData={refData}
        />
      )}

      {modal === "FlagPolicyModal" && (
        <FlagPolicyModal
          handleClose={closeModal}
          handleConfirm={handleConfirmFlagChange}
          isLoading={isSubmittingContract}
        />
      )}

      {modal === "RemovePolicyFlagModal" && (
        <RemovePolicyFlagModal
          handleClose={closeModal}
          handleConfirm={handleConfirmFlagChange}
          isLoading={isSubmittingContract}
        />
      )}

      {willBeCancelled && (
        <Banner className="mb-8" color="red" headingText="This policy will be cancelled.">
          <p>Refund amount: {hasRefund && currencyFormatter(contractData.refund)}</p>
          <p>Cancellation date: {moment.utc(contractData.cancellationDate).format("DD-MM-YYYY")}</p>
          <p>Cancellation reason: {contractData.cancellation.reason}</p>
        </Banner>
      )}

      {isCancelled && (
        <Banner className="mb-8" color="red" headingText="This policy has been cancelled.">
          <p>Refund amount: {hasRefund && currencyFormatter(contractData.refund)}</p>
          <p>Cancellation date: {moment.utc(contractData.cancellationDate).format("DD-MM-YYYY")}</p>
          <p>Cancellation reason: {contractData.cancellation.reason}</p>
        </Banner>
      )}

      {isNtu && (
        <Banner
          className="mb-8"
          color="red"
          headingText="This submission was set to not taken up for the following reason(s):"
        >
          <div className="mb-4">
            <p className="font-medium">Reason</p>
            <p>{get(contractData, "notTakenUp.reason")}</p>
          </div>

          {get(contractData, "notTakenUp.note") && (
            <div>
              <p className="font-medium">Note</p>
              <p>{get(contractData, "notTakenUp.note")}</p>
            </div>
          )}
        </Banner>
      )}

      {isDtq && (
        <Banner
          className="mb-8"
          color="red"
          headingText="This submission was set to declined for the following reason(s):"
        >
          <div className="mb-4">
            <p className="font-medium">Reason</p>
            <p>{get(contractData, "declineToQuote.reason")}</p>
          </div>

          {get(contractData, "declineToQuote.note") && (
            <div>
              <p className="font-medium">Note</p>
              <p>{get(contractData, "declineToQuote.note")}</p>
            </div>
          )}
        </Banner>
      )}

      {isReferred && (
        <Banner
          className="mb-6"
          color="orange"
          headingText="This submission was set to referred for the following reason(s):"
        >
          <ul className="list-disc list-inside">
            {wouldBeReferred.map((reason) => (
              <li key={reason}>{reason}</li>
            ))}
          </ul>
        </Banner>
      )}

      {isBroker && hasCommercialPrice && isReferredQuoted && (
        <Banner
          className="mb-6"
          color="orange"
          headingText="Underwriter submitted a commercial price"
        />
      )}

      {isBroker && hasTechnicalPrice && isReferredQuoted && (
        <Banner
          className="mb-6"
          color="orange"
          headingText="Underwriter submitted a technical price"
        />
      )}

      {isBroker && hasActiveClaim && (
        <Banner className="mb-8" color="orange" headingText="Active claim against policy">
          <p>You cannot modify this policy whilst there is an active claim.</p>
        </Banner>
      )}

      <div className="mb-8 xl:flex xl:-mx-3">
        <div className="mb-8 xl:w-1/2 xl:mx-3">
          <div className="mb-8">
            <H3 className="mb-8">Price summary</H3>

            {isReferred && !hasActiveQuotes && (
              <Banner className="mb-8" color="blue" headingText="No prices have been created">
                <p>The quote has no prices generated yet.</p>
              </Banner>
            )}

            {isSubmitted && !hasQuotes && (
              <Banner className="mb-8" color="blue" headingText="No prices have been created">
                <p>The quote has no prices generated yet.</p>
              </Banner>
            )}

            {isNtu && !hasQuotes && (
              <Banner className="mb-8" color="blue" headingText="No prices have been created">
                <p>The quote has been set to “Not taken up” before a price has been generated</p>
              </Banner>
            )}

            {isCurrent && checkPermissions(ACTIONS.CREATE_PRICE) && (
              <NewPrice
                contractData={contractData}
                deductiblesData={deductiblesData}
                key={quotesData}
                quotesData={quotesData}
              />
            )}

            {quotesData.map((quoteData, index) => {
              const predicate = isBound ? checkBound : checkNonArchived;

              if (!predicate(quoteData)) {
                return false;
              }

              return (
                <Price
                  contractData={contractData}
                  handleAction={handleAction}
                  index={index}
                  isContractCurrent={isCurrent}
                  key={quoteData.id}
                  patchData={patchData}
                  quoteData={quoteData}
                  showDiff={showDiff}
                />
              );
            })}

            {isCurrent && hasActiveQuotes && (
              <div className="flex">
                {checkPermissions(ACTIONS.CREATE_COMMERCIAL_PRICE) && (
                  <Button
                    className="mr-4"
                    kind="tertiary"
                    onClick={() => handleAction({ type: ACTIONS.CREATE_COMMERCIAL_PRICE })}
                  >
                    Create new commercial price
                  </Button>
                )}

                {checkPermissions(ACTIONS.DECLINE_TO_QUOTE) && (
                  <Button
                    kind="danger-secondary"
                    onClick={() => handleAction({ type: ACTIONS.DECLINE_TO_QUOTE })}
                  >
                    Decline submission
                  </Button>
                )}
              </div>
            )}
          </div>

          {isCurrent && isEndorsementQuoted && isEndorsement && (
            <div className="mb-8">
              <H3 className="mb-8">Change in premium</H3>

              <EndorsementPrice
                contractData={contractData}
                handleAction={handleAction}
                isContractCurrent={isCurrent}
                quoteData={quotesData[0]}
              />
            </div>
          )}

          {isCurrent &&
            (checkPermissions(ACTIONS.VIEW_CLAIM_FLAG) ||
              checkPermissions(ACTIONS.VIEW_PREMIUM_PAID_FLAG)) && (
              <div className="mb-8">
                <H3 className="mb-8">Policy actions</H3>

                <FlagPolicy
                  contractData={contractData}
                  handleAction={handleAction}
                  isLoading={isSubmittingContract}
                />
              </div>
            )}
        </div>

        <div className="mb-8 xl:w-1/2 xl:mx-3">
          {isPolicy && <H3 className="mb-8">Policy details</H3>}

          {!isPolicy && <H3 className="mb-8">Quote details</H3>}

          <Accordion id={id || contractId}>
            <InsuredDetails
              contractData={contractData}
              id="insured"
              patchData={patchData}
              showDiff={showDiff}
            />

            <CoverageSummary
              contractData={contractData}
              id="coverage"
              patchData={patchData}
              showDiff={showDiff}
            />

            <Sublimits
              contractData={contractData}
              hasCommercialPrice={hasCommercialPrice}
              id="sublimits"
              patchData={patchData}
              showDiff={showDiff}
            />

            <LocationSchedule
              contractData={contractData}
              id="location"
              patchData={patchData}
              showDiff={showDiff}
            />

            {checkPermissions(ACTIONS.VIEW_POLICY_DOCUMENTS_TABLE) && (
              <PolicyDocuments id="policyDocuments" contractData={contractData} />
            )}

            {checkPermissions(ACTIONS.VIEW_REJECTED_PRICES_TABLE) && (
              <RejectedPrices contractData={contractData} id="rejected" />
            )}
          </Accordion>
        </div>
      </div>
    </>
  );
};

ContractDetails.propTypes = {
  contractData: PropTypes.object.isRequired,
  id: PropTypes.string,
  isCurrent: PropTypes.bool,
  isEndorsement: PropTypes.bool,
  patchData: PropTypes.array,
  refData: PropTypes.object.isRequired,
  showDiff: PropTypes.bool,
};

ContractDetails.defaultProps = {
  id: undefined,
  isCurrent: true,
  isEndorsement: false,
  patchData: [],
  showDiff: undefined,
};

const areEqual = (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps);

export default memo(ContractDetails, areEqual);
