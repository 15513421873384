import React from "react";

const LogoAlt = () => (
  <div
    className=" bg-cover bg-center"
    style={{ width: "400px", height: "150px", backgroundImage: "url(/Chaucer_Bronze_Logo.png)" }}
  />
);

export default LogoAlt;
