import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../../api";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Dropdown from "../../components/Dropdown";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import TableDataPoint from "../../components/TableDataPoint";
import { ACTIONS, QUOTE_STATUSES } from "../../constants";
import { downloadDocument, prepareData } from "../../helpers";
import { useAuth } from "../../hooks";
import { quotesRatesDiffDataPointsMap } from "./config";

const EndorsementPrice = ({ contractData, handleAction, isContractCurrent, quoteData }) => {
  const { contractId } = useParams();
  const { checkPermissions } = useAuth(contractData);
  const quoteId = quoteData.id;
  const isPriceSubmitted = quoteData.status === QUOTE_STATUSES.SUBMITTED;

  const quoteLinkQuery = useQuery(
    ["quoteLink", { contractId, priceId: quoteId }],
    api.getQuoteLink,
    { retry: 6, enabled: checkPermissions(ACTIONS.VIEW_QUOTE_DOCUMENT) && isContractCurrent }
  );

  const hasData = get(quoteLinkQuery, "data.data.url");
  const isDisabled = !hasData || quoteLinkQuery.isLoading;

  const onSelect = async (item) => {
    if (item.value === ACTIONS.VIEW_QUOTE_DOCUMENT) {
      const { url, fileName } = get(quoteLinkQuery, "data.data", {});
      return downloadDocument(url, fileName);
    }

    return handleAction({ type: item.value, quoteId, quoteData });
  };

  const data = prepareData({
    contractData,
    dataPoints: quotesRatesDiffDataPointsMap,
  });

  return (
    <Card key={quoteData.ref} className="mb-8" headingText="Endorsement price">
      <Table>
        <Table.Body>
          <TableDataPoint {...data.gross} />
          <TableDataPoint {...data.commission} isStriped />
          <TableDataPoint {...data.ipt} />
          <TableDataPoint {...data.total} isStriped />
        </Table.Body>
      </Table>

      {isContractCurrent && isPriceSubmitted && checkPermissions(ACTIONS.BIND_ENDORSEMENT_PRICE) && (
        <div className="flex p-4">
          <Button
            kind="primary"
            onClick={() => handleAction({ type: ACTIONS.BIND_ENDORSEMENT_PRICE, quoteId })}
            className="mr-4"
          >
            Bind
          </Button>

          <Dropdown
            labelText="Actions"
            items={[
              {
                label: "Reject price",
                value: ACTIONS.REJECT_ENDORSEMENT_PRICE,
              },
              {
                disabled: isDisabled,
                label: (
                  <div className="flex items-center">
                    {isDisabled && <Loader size="small" className="mr-2" />}
                    <span>View quote document</span>
                  </div>
                ),
                value: ACTIONS.VIEW_QUOTE_DOCUMENT,
              },
            ]}
            onSelect={onSelect}
          />
        </div>
      )}
    </Card>
  );
};

EndorsementPrice.propTypes = {
  contractData: PropTypes.object.isRequired,
  handleAction: PropTypes.func.isRequired,
  isContractCurrent: PropTypes.bool,
  quoteData: PropTypes.object.isRequired,
};

EndorsementPrice.defaultProps = {
  isContractCurrent: false,
};

export default EndorsementPrice;
