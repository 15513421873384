import clsx from "clsx";
import { dict, get, has } from "json-pointer";
import _get from "lodash.get";
import intersection from "lodash.intersection";
import isObject from "lodash.isobject";
import mapKeys from "lodash.mapkeys";
import set from "lodash.set";
import unset from "lodash.unset";
import moment from "moment";
import { compile, match } from "path-to-regexp";
import qs from "qs";

export const isBeforeToday = (date) => moment.utc(date, "DD/MM/YYYY").isBefore(moment.utc(), "day");

export const stringifyParams = (params) => qs.stringify(params, { addQueryPrefix: true });

export const parseParams = (search) => qs.parse(search, { ignoreQueryPrefix: true });

export const buildUrl = ({ baseURL, url, params }) => `${baseURL}/${url}${stringifyParams(params)}`;

export const downloadDocument = (url, fileName) => {
  const downloadLink = document.createElement("a");

  downloadLink.href = url;
  downloadLink.download = fileName;
  downloadLink.click();
};

export const commaSeparate = (arr) => arr.filter(Boolean).join(", ");

export const pickCommaSeparate = (obj, arr) => commaSeparate(arr.map((item) => _get(obj, item)));

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const dataString = reader.result.split(",")[1];

      resolve(dataString);
    };
    reader.onerror = (error) => reject(error);
  });

const normalizePatches = (patchData = [], op) => {
  const result = patchData
    .filter((patch) => patch.op === op)
    .map((patch) => {
      if (isObject(patch.value)) {
        return mapKeys(dict(patch.value), (value, key) => patch.path + key);
      }

      return { [patch.path]: patch.value };
    })
    .reduce((prev, next) => ({ ...prev, ...next }), {});

  return Object.keys(result);
};

export const prepareData = (args) => {
  const { dataPoints, contractData, patchData, params, checkPermissions, showDiff } = args;
  const addedPaths = normalizePatches(patchData, "add");
  const updatedPaths = normalizePatches(patchData, "replace");
  const removedPaths = normalizePatches(patchData, "remove");
  const newMap = {};

  Object.keys(dataPoints).forEach((key) => {
    const props = dataPoints[key];
    const paths = Array.isArray(props.path) ? props.path : [props.path];
    const compiledPaths = paths.map((path) => compile(path)(params));
    const isAdded = intersection(compiledPaths, addedPaths).length > 0;
    const isRemoved = intersection(compiledPaths, removedPaths).length > 0;
    const isUpdated = intersection(compiledPaths, updatedPaths).length > 0;
    const values = compiledPaths.map((path) => has(contractData, path) && get(contractData, path));
    const hasValues = values.filter(Boolean).length > 0;

    let children = hasValues ? values : "[empty]";

    if (props.formatter && hasValues) {
      children = props.formatter(values);
    }

    if (props.permissions && !checkPermissions(props.permissions)) {
      children = "--";
    }

    if (props.obfuscate && props.obfuscate({ contractData })) {
      children = "--";
    }

    const bgClassName =
      showDiff &&
      clsx(props.className, {
        "bg-yellow-100": isUpdated,
        "bg-red-100": isRemoved,

        /*
        Address keys can be added and replaced within the same patch, so in that case we won't
        show that change as "add" but rather as "update".
      */

        "bg-green-200": isAdded && !isUpdated,
      });

    set(newMap, key, { ...props, bgClassName, children });
  });

  return newMap;
};

export const checkUpdated = (dataPoints, contractData, patchData, params) => {
  const addedPaths = normalizePatches(patchData, "add");
  const updatedPaths = normalizePatches(patchData, "replace");
  const removedPaths = normalizePatches(patchData, "remove");

  const pathsFromObject =
    isObject(dataPoints) &&
    !Array.isArray(dataPoints) &&
    Object.keys(dataPoints)
      .map((key) => {
        const { path } = dataPoints[key];

        return Array.isArray(path) ? path : [path];
      })
      .reduce((prev, next) => {
        return [...prev, ...next];
      }, []);

  const pathsFromArray = Array.isArray(dataPoints) && dataPoints;
  const pathsFromString = !isObject(dataPoints) && [dataPoints];
  const finalPaths = pathsFromObject || pathsFromArray || pathsFromString;

  const compiledPaths = finalPaths.map((path) => compile(path)(params));

  const isAdded = intersection(compiledPaths, addedPaths).length > 0;
  const isUpdated = intersection(compiledPaths, updatedPaths).length > 0;
  const isRemoved = intersection(compiledPaths, removedPaths).length > 0;

  return isAdded || isUpdated || isRemoved;
};

export const checkRemoved = ({ path, patchData }) => {
  const removedPaths = normalizePatches(patchData, "remove");
  const someRemoved = removedPaths.some((removedPath) => match(path)(removedPath));

  return someRemoved;
};

export const getBusinessLevel = (policyLimit, refData) => {
  const foundRange = refData.sdRange.find((range) => {
    const min = _get(range, "getSDRange[0][0].amount");
    const max = _get(range, "getSDRange[0][1].amount");

    return policyLimit >= min && policyLimit <= max;
  });

  return _get(foundRange, "getSDRange[1].businessLevel");
};

export const getTivAmount = (locations, isCompany) =>
  locations
    .map((item) => {
      const pdAmount = _get(item, "propertyDamage.amount", 0);
      const biAmount = _get(item, "businessInterruption.amount", 0);

      return isCompany ? pdAmount + biAmount : pdAmount;
    })
    .reduce((prev, next) => prev + next, 0);

export const prepareContract = (contractData = {}, formValues) => {
  const newContract = JSON.parse(JSON.stringify(contractData));
  const isInceptionBeforeToday = isBeforeToday(formValues.inceptionDate);
  const companyId = _get(contractData, "insured.Company.number");
  const individualId = _get(contractData, "insured.Individual.id");

  if (companyId) {
    set(newContract, "insuredRef.CompanyRef", companyId);
  }

  if (individualId) {
    set(newContract, "insuredRef.IndividualRef", individualId);
  }

  if (formValues.policyLimit) {
    set(newContract, "policyLimit", formValues.policyLimit);
  }

  if (formValues.businessType) {
    set(newContract, "product.coverDetails.businessType", formValues.businessType);
  }

  if (formValues.locationsSchedule) {
    set(newContract, "product.locationsSchedule", formValues.locationsSchedule);
  }

  if (formValues.claimBasis) {
    set(newContract, "product.coverDetails.claimBasis", formValues.claimBasis);
  }

  if (formValues.indemnityPeriod) {
    set(newContract, "product.indemnityPeriod", formValues.indemnityPeriod);
  }

  if (formValues.policyTerm) {
    set(newContract, "product.policyTerm", formValues.policyTerm);
  }

  if (formValues.sublimits) {
    set(newContract, "product.sublimits", formValues.sublimits);
  }

  if (formValues.insuredRef) {
    set(newContract, "insuredRef", formValues.insuredRef);
  }

  if (formValues.status) {
    set(newContract, "status", formValues.status);
  }

  if (formValues.declineToQuote) {
    set(newContract, "declineToQuote", formValues.declineToQuote);
  }

  if (formValues.commercial || formValues.commercial === 0) {
    set(newContract, "commercial", formValues.commercial);
  }

  if (formValues.notTakenUp) {
    set(newContract, "notTakenUp", formValues.notTakenUp);
  }

  if (isInceptionBeforeToday) {
    set(newContract, "warrantyOfNoKnownClaims", formValues.warrantyOfNoKnownClaims);
  }

  if (formValues.inceptionDate) {
    const newPeriodStart = moment.utc(formValues.inceptionDate, "DD/MM/YYYY");

    set(newContract, "period.start", newPeriodStart.format());
    set(newContract, "period.end", newPeriodStart.add(1, "year").format());
  }

  if (formValues.cancellationDate) {
    set(newContract, "cancellationDate", formValues.cancellationDate);
  }

  if (formValues.cancellation) {
    set(newContract, "cancellation", formValues.cancellation);
  }

  unset(newContract, "aggregateValues");
  unset(newContract, "contactId");
  unset(newContract, "coverType");
  unset(newContract, "createdAt");
  unset(newContract, "currentEvent");
  unset(newContract, "id");
  unset(newContract, "insured");
  unset(newContract, "product.aggregateValues");
  unset(newContract, "product.businessLevel");
  unset(newContract, "product.coverType");
  unset(newContract, "product.sublimitOptions");
  unset(newContract, "product.outcodeExposures");
  unset(newContract, "quotes");
  unset(newContract, "quote");
  unset(newContract, "rates");
  unset(newContract, "ref");
  unset(newContract, "referralReason");
  unset(newContract, "referralResult");
  unset(newContract, "tenantId");
  unset(newContract, "updatedAt");
  unset(newContract, "wouldBeReferred");

  return newContract;
};

export const prepareEndorsement = (endorsementData = {}, formValues) => {
  const newEndo = prepareContract(endorsementData, formValues);

  unset(newEndo, "status");
  unset(newEndo, "appliedEndorsements");
  unset(newEndo, "activeEndorsement");
  unset(newEndo, "endorsementStatus");
  unset(newEndo, "period");
  unset(newEndo, "product.coverDetails.claimBasis");
  unset(newEndo, "product.indemnityPeriod");
  unset(newEndo, "product.policyTerm");
  unset(newEndo, "product.outcodeExposures");
  unset(newEndo, "warrantyOfNoKnownClaims");

  return newEndo;
};

export const prepareQuote = (quoteData = {}, formValues) => {
  const newQuote = JSON.parse(JSON.stringify(quoteData));

  unset(newQuote, "createdAt");
  unset(newQuote, "id");
  unset(newQuote, "rates");
  unset(newQuote, "ref");
  unset(newQuote, "updatedAt");

  if (formValues.deductible) {
    set(newQuote, "deductible", formValues.deductible);
  }

  if (formValues.commissionPercentage || formValues.commissionPercentage === 0) {
    set(newQuote, "commissionPercentage", formValues.commissionPercentage);
  }

  if (formValues.status) {
    set(newQuote, "status", formValues.status);
  }

  return newQuote;
};
