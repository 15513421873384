import get from "lodash.get";
import { compile } from "path-to-regexp";
import React from "react";
import { useQuery } from "react-query";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import * as api from "../../api";
import PageLoader from "../../components/PageLoader";
import Stepper from "../../components/Stepper";
import { ACTIONS, ROUTES, STEPS } from "../../constants";
import { useAuth } from "../../hooks";
import ContractStepperInsured from "../ContractStepperInsured";
import ContractStepperLocations from "../ContractStepperLocations";
import ContractStepperRequirements from "../ContractStepperRequirements";
import ContractStepperSublimits from "../ContractStepperSublimits";
import Header from "../Header";
import HistoryBlock from "../HistoryBlock";

const ContractStepper = () => {
  const params = useParams();
  const { step, contractId, contractType } = params;
  const isEndorsementsPath = contractType === "endorsements";
  const refDataQuery = useQuery(["refData"], api.getRefData);
  const refData = get(refDataQuery, "data.data");
  const contractQuery = useQuery(["contract", { contractId }], api.getContract, {
    enabled: contractId && !isEndorsementsPath,
  });

  const contractData = get(contractQuery, "data.data", {});
  const contractRef = get(contractData, "ref");
  const endorsementId = get(contractData, "activeEndorsement.id");

  const endorsementQuery = useQuery(["endorsement", { contractId }], api.getEndorsement, {
    enabled: contractId && isEndorsementsPath,
  });

  const endorsementData = get(endorsementQuery, "data.data", {});
  const finalContractData = isEndorsementsPath ? endorsementData : contractData;

  const { checkPermissions } = useAuth(finalContractData);
  const attachmentsQuery = useQuery(["attachments", { contractId }], api.getContractAttachments, {
    enabled: contractId,
  });

  const attachmentsData = get(attachmentsQuery, "data.data.documents");
  const canEdit = checkPermissions(ACTIONS.EDIT_CONTRACT);
  const canCreate = checkPermissions(ACTIONS.CREATE_NEW_QUOTE);
  const is404 = get(contractQuery, "error.response.data.message") === "Entity not found";
  const stepperPath = compile(ROUTES.CONTRACT_STEPPER);
  const activeIndex = Object.values(STEPS).findIndex((item) => item === step);

  if (
    attachmentsQuery.isFetching ||
    contractQuery.isFetching ||
    refDataQuery.isFetching ||
    endorsementQuery.isFetching ||
    (endorsementId && endorsementQuery.isIdle)
  ) {
    return <PageLoader />;
  }

  if (!contractId && !canCreate) {
    return <Redirect to={ROUTES.HOME} />;
  }

  if (contractId && !canEdit) {
    return <Redirect to={ROUTES.HOME} />;
  }

  if (is404) {
    return <Redirect to={ROUTES.NOT_FOUND} />;
  }

  return (
    <HistoryBlock>
      <Header
        attachmentsData={attachmentsData}
        contractData={finalContractData}
        headingText={
          (contractType === "quotes" && `Quote - ${contractRef}`) ||
          (contractType === "endorsements" && `Endorsement - Policy - ${contractRef}`) ||
          "New Quote"
        }
        refData={refData}
      />

      <div className="border-b border-solid border-gray-400 px-8">
        <Stepper activeIndex={contractId ? activeIndex : 0}>
          <Stepper.Step to={contractId && stepperPath({ ...params, step: "insured" })}>
            Insured
          </Stepper.Step>

          <Stepper.Step to={contractId && stepperPath({ ...params, step: "locations" })}>
            Locations
          </Stepper.Step>

          <Stepper.Step to={contractId && stepperPath({ ...params, step: "requirements" })}>
            Requirements
          </Stepper.Step>

          <Stepper.Step to={contractId && stepperPath({ ...params, step: "sublimits" })}>
            Sublimits
          </Stepper.Step>
        </Stepper>
      </div>

      <div className="p-8 pb-40">
        <Switch>
          <Route
            path={[ROUTES.NEW_QUOTE, ROUTES.CONTRACT_STEPPER_INSURED]}
            render={() => <ContractStepperInsured contractData={finalContractData} />}
          />

          <Route
            path={ROUTES.CONTRACT_STEPPER_LOCATIONS}
            render={() => (
              <ContractStepperLocations contractData={finalContractData} refData={refData} />
            )}
          />

          <Route
            path={ROUTES.CONTRACT_STEPPER_REQUIREMENTS}
            render={() => (
              <ContractStepperRequirements contractData={finalContractData} refData={refData} />
            )}
          />

          <Route
            path={ROUTES.CONTRACT_STEPPER_SUBLIMITS}
            render={() => (
              <ContractStepperSublimits contractData={finalContractData} refData={refData} />
            )}
          />

          <Redirect to={ROUTES.CONTRACT_STEPPER_INSURED} />
        </Switch>
      </div>
    </HistoryBlock>
  );
};

export default ContractStepper;
