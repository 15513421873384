import clsx from "clsx";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import Icon from "./Icon";

const sizes = {
  small: "h-10",
  normal: "h-12",
};

const Input = forwardRef(
  ({ className, placeholderText, size, isDisabled, isRequired, id, name, ...props }, ref) => (
    <div className={clsx("relative")}>
      <input
        className={clsx("aui-input aui-input-readonly", sizes[size], className)}
        disabled={isDisabled}
        id={id || name}
        placeholder={placeholderText}
        readOnly
        ref={ref}
        required={isRequired}
        {...props}
      />
      <div
        className={clsx(
          "flex w-10 z-10 items-center justify-center absolute right-0 top-0",
          sizes[size]
        )}
      >
        <Icon name="lock" className="w-3 fill-current text-gray-800" />
      </div>
    </div>
  )
);

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  placeholderText: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal"]),
};

Input.defaultProps = {
  className: undefined,
  id: undefined,
  isDisabled: undefined,
  isRequired: undefined,
  name: undefined,
  placeholderText: undefined,
  size: "normal",
};

export default Input;
