import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Modal from "../components/Modal";

const MightBeReferredWarningModal = ({ handleClose, handleConfirm, contractData }) => (
  <Modal handleClose={handleClose} headingText="Refer to underwriter">
    <div className="p-6 w-160">
      <p className="mb-4">
        This submission may now be referred to the underwriter. Please continue to complete Cover
        requirements or go back to amend Cover details
      </p>

      <Banner headingText="Referral reason(s):" className="mb-6" color="orange">
        <ul className="list-disc list-inside">
          {get(contractData, "wouldBeReferred", []).map((reason) => (
            <li key={reason}>{reason}</li>
          ))}
        </ul>
      </Banner>

      <div className="flex">
        <Button kind="primary" onClick={handleConfirm} className="mr-4">
          Continue to Cover requirements
        </Button>

        <Button onClick={handleClose}>Amend submission</Button>
      </div>
    </div>
  </Modal>
);

MightBeReferredWarningModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  contractData: PropTypes.object,
};

MightBeReferredWarningModal.defaultProps = {
  contractData: undefined,
};

export default MightBeReferredWarningModal;
