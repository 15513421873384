import get from "lodash.get";
import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import Fieldset from "../../components/Fieldset";
import Footer from "../../components/Footer";
import Form from "../../components/Form";
import H3 from "../../components/H3";
import InputCurrency from "../../components/InputCurrency";
import InputRadio from "../../components/InputRadio";
import { ACTIONS, ROUTES, STATUSES } from "../../constants";
import useFormContext from "../../context/Form";
import { currencyFormatter } from "../../formatters";
import { getBusinessLevel, getTivAmount } from "../../helpers";
import parseErrorMessages from "../../helpers/parseErrorMessages";
import { useAuth, useContractQuery, useModal } from "../../hooks";
import MightBeReferredWarningModal from "../../modals/MightBeReferredWarningModal";
import PolicyLimitWarningModal from "../../modals/PolicyLimitWarningModal";
import LocationsScheduleTable from "./LocationsScheduleTable";
import { currencySchema, locationsScheduleSchema, locationsSchema } from "./schema";

const ContractStepperLocations = ({ contractData, refData }) => {
  const history = useHistory();
  const { contractId, contractType } = useParams();
  const { isBroker, checkPermissions } = useAuth(contractData);
  const { modal, showModal, closeModal } = useModal();
  const [errorMessage, setErrorMessage] = useState();
  const isCompany = Boolean(get(contractData, "insured.Company"));
  const formContext = useFormContext();
  const { handleSubmitContract, isSubmittingContract } = useContractQuery();

  const existingLocations = get(contractData, "product.locationsSchedule", []);
  const existingLocationsWithIds = existingLocations.map((item) => ({ ...item, id: uuidv4() }));
  const [locations, setLocations] = useState(existingLocationsWithIds);
  const castLocations = locationsSchema.cast(locations, { context: { isCompany } });

  const hasSomeLocations = locations.length >= 1;

  const existingBusinessType = get(contractData, "product.coverDetails.businessType");
  const coercedBusinessType =
    existingBusinessType && !isCompany ? "Residential" : existingBusinessType;

  const [values, setValues] = useState({
    businessType: coercedBusinessType,
    policyLimit: {
      amount: get(contractData, "policyLimit.amount"),
      currency: get(contractData, "policyLimit.currency", "GBP"),
    },
  });

  const isCommercial = values.businessType === "Commercial";
  const tivAmount = getTivAmount(castLocations, isCompany);
  const tivValue = currencySchema.cast({ amount: tivAmount });
  const formattedTiv = currencyFormatter(tivValue);

  const areLocationsValid = locationsSchema.isValidSync(locations, {
    context: { isCommercial, isCompany },
  });

  const isLocationsScheduleFormValid = locationsScheduleSchema.isValidSync(values, {
    context: { isCompany, tivAmount },
  });

  const nextStepPath = compile(ROUTES.CONTRACT_STEPPER_REQUIREMENTS)({ contractId, contractType });
  const prevStepPath = compile(ROUTES.CONTRACT_STEPPER)({ contractId, contractType });

  const handleLocationsScheduleFormChange = (event) =>
    setValues({ ...values, [event.target.name]: event.target.value });

  const submitFormData = async () => {
    const castFormValues = locationsScheduleSchema.cast(values);
    const locationsWithoutIds = locationsSchema.cast(locations, {
      context: { stripIds: true, isCompany, isCommercial },
    });

    return handleSubmitContract(
      { ...castFormValues, locationsSchedule: locationsWithoutIds },
      contractData
    );
  };

  const handleAfterFormSubmit = (res) => {
    const wouldBeReferred = get(res, "data.wouldBeReferred", []);

    if (isBroker && wouldBeReferred.length > 0) {
      return showModal("MightBeReferredWarningModal");
    }

    return history.push(nextStepPath, { isForced: true });
  };

  const handleBeforeFormSubmit = async () => {
    const isReferred = get(contractData, "status") === STATUSES.REFERRED;
    const policyLimit = get(values, "policyLimit.amount");
    const existingBusinessLevel = get(contractData, "product.businessLevel");
    const nextBusinessLevel = getBusinessLevel(policyLimit, refData);

    if (isReferred && existingBusinessLevel !== nextBusinessLevel) {
      return showModal("PolicyLimitWarningModal");
    }

    try {
      const res = await submitFormData();

      handleAfterFormSubmit(res);
    } catch (err) {
      const msg = get(err, "response.data.error");

      const parsedMsg = parseErrorMessages(msg);

      setErrorMessage(parsedMsg);
    }

    return false;
  };

  return (
    <>
      {errorMessage && (
        <Alert headingText="Error" color="red" cb={() => setErrorMessage(false)}>
          {errorMessage}
        </Alert>
      )}

      {modal === "MightBeReferredWarningModal" && (
        <MightBeReferredWarningModal
          handleClose={closeModal}
          handleConfirm={() => {
            history.push(nextStepPath, { isForced: true });
          }}
          contractData={contractData}
        />
      )}

      {modal === "PolicyLimitWarningModal" && (
        <PolicyLimitWarningModal
          handleClose={closeModal}
          handleConfirm={async () => {
            const res = await submitFormData();

            handleAfterFormSubmit(res);
          }}
        />
      )}

      {checkPermissions(ACTIONS.VIEW_BUSINESS_TYPE) && (
        <Fieldset form="locationsScheduleForm" hasContext>
          <H3 className="mb-6">Property type</H3>

          <p className="mb-6">The primary usage of the location.</p>

          <InputRadio
            className="mb-6"
            descriptionText={
              !isCompany
                ? "Commercial option is not available for Individuals"
                : "Commercial Owners of non-Residential Property"
            }
            isChecked={values.businessType === "Commercial"}
            isDisabled={!isCompany || !checkPermissions(ACTIONS.EDIT_BUSINESS_TYPE)}
            labelText="Commercial"
            name="businessType"
            onChange={handleLocationsScheduleFormChange}
            value="Commercial"
          />

          <InputRadio
            className="mb-6"
            descriptionText="Commercial Owners of Residential Property"
            isChecked={values.businessType === "Residential"}
            isDisabled={!checkPermissions(ACTIONS.EDIT_BUSINESS_TYPE)}
            labelText="Residential"
            name="businessType"
            onChange={handleLocationsScheduleFormChange}
            value="Residential"
          />
        </Fieldset>
      )}

      {values.businessType && (
        <>
          <LocationsScheduleTable
            businessType={values.businessType}
            contractData={contractData}
            formattedTiv={formattedTiv}
            isCommercial={isCommercial}
            locations={locations}
            refData={refData}
            setLocations={setLocations}
          />

          {hasSomeLocations && (
            <Fieldset form="locationsScheduleForm" hasContext>
              <InputCurrency
                className="max-w-md"
                currencies={["GBP"]}
                descriptionText="The maximum amount per occurrence and during the policy period that the insurance policy will pay out in the event of an insured loss"
                errors={
                  values.policyLimit.amount > tivAmount && `Cannot be larger than ${formattedTiv}`
                }
                isReadOnly={!checkPermissions(ACTIONS.EDIT_POLICY_LIMIT)}
                labelText="Policy limit"
                name="policyLimit"
                onChange={(value, name) => setValues({ ...values, [name]: value })}
                value={values.policyLimit}
              />
            </Fieldset>
          )}
        </>
      )}

      <Form id="locationsScheduleForm" onSubmit={handleBeforeFormSubmit} hasContext>
        <Footer>
          <div className="flex">
            <Button as={Link} to={ROUTES.HOME} className="mr-4">
              Exit
            </Button>

            <Button as={Link} to={prevStepPath}>
              Go back
            </Button>
          </div>

          {((!formContext.isDirty && !isLocationsScheduleFormValid) || formContext.isDirty) && (
            <Button
              isDisabled={
                !areLocationsValid ||
                !hasSomeLocations ||
                !isLocationsScheduleFormValid ||
                isSubmittingContract
              }
              kind="primary"
              type="submit"
            >
              Save
            </Button>
          )}

          {!formContext.isDirty && isLocationsScheduleFormValid && (
            <Button as={Link} to={nextStepPath} kind="primary">
              Continue
            </Button>
          )}
        </Footer>
      </Form>
    </>
  );
};

ContractStepperLocations.propTypes = {
  contractData: PropTypes.object,
  refData: PropTypes.object,
};

ContractStepperLocations.defaultProps = {
  contractData: undefined,
  refData: undefined,
};

export default ContractStepperLocations;
