import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Banner from "./Banner";

const Alert = ({ cb, ...bannerProps }) => {
  useEffect(() => {
    const timeout = setTimeout(cb, 5000);

    return () => clearTimeout(timeout);
  });

  return (
    <div className={clsx("fixed bottom-0 right-0 mb-32 mr-8 max-w-sm w-full")}>
      <Banner {...bannerProps} className="shadow" />
    </div>
  );
};

Alert.propTypes = {
  cb: PropTypes.func.isRequired,
};

export default Alert;
