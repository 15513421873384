/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useStickyState } from "../hooks";
import Icon from "./Icon";

const Panel = ({ children, isExpanded, tag, headingText, onClick }) => (
  <div className="mb-4">
    <div
      onClick={onClick}
      className="p-3 rounded border border-gray-500 cursor-pointer select-none"
    >
      <div className="flex justify-between">
        <div className="flex items-center">
          <div className="font-medium leading-normal mr-4">{headingText}</div>
          {tag}
        </div>
        <div className="flex items-center">
          <Icon
            name={isExpanded ? "chevron-up" : "chevron-down"}
            className="mx-3 fill-current text-gray-800"
          />
        </div>
      </div>
    </div>
    {isExpanded && children}
  </div>
);

const Accordion = ({ className, children, initialActiveIds, id }) => {
  const [selectedIds, setSelectedIds] = useStickyState(initialActiveIds, id);

  const handleItemClick = (itemId, isExpanded) => (event) => {
    const nextIds = isExpanded
      ? selectedIds.filter((item) => item !== itemId)
      : [...selectedIds, itemId];

    event.stopPropagation();

    return setSelectedIds(nextIds);
  };

  return (
    <div className={clsx(className)}>
      {React.Children.toArray(children).map((child) => {
        const itemId = child.props.id || child.props.headingText;
        const isExpanded = selectedIds.includes(itemId);

        return React.cloneElement(child, {
          isExpanded,
          onClick: handleItemClick(itemId, isExpanded),
        });
      })}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
  headingText: PropTypes.string,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  tag: PropTypes.node,
};

Panel.defaultProps = {
  children: undefined,
  headingText: undefined,
  isExpanded: false,
  onClick: undefined,
  tag: undefined,
};

Accordion.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  initialActiveIds: PropTypes.arrayOf(PropTypes.string),
};

Accordion.defaultProps = {
  children: undefined,
  className: undefined,
  id: undefined,
  initialActiveIds: [],
};

Accordion.Panel = Panel;

export default Accordion;
