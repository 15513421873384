import { useState } from "react";

const useModal = () => {
  const [modal, setVisibleModal] = useState();

  const showModal = (name) => setVisibleModal(name);

  const closeModal = () => setVisibleModal(undefined);

  return { modal, showModal, closeModal };
};

export default useModal;
