import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

const Tab = ({ to, children, className }) => {
  const isActive = useRouteMatch(to);
  const handleMouseDown = (event) => event.preventDefault();

  return (
    <Link onMouseDown={handleMouseDown} tabIndex={0} to={to}>
      <div
        className={clsx(
          "pb-4 pt-1 px-10 border-b-4",
          isActive && "border-blue-600",
          !isActive && "border-gray-300",
          !isActive && "text-gray-700",
          className
        )}
      >
        {children}
      </div>
    </Link>
  );
};

const Tabs = ({ className, children }) => (
  <div className={clsx("flex items-center", className)}>{children}</div>
);

Tab.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
};

Tab.defaultProps = {
  children: undefined,
  className: undefined,
  to: undefined,
};

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Tabs.defaultProps = {
  children: undefined,
  className: undefined,
};

Tabs.Tab = Tab;

export default Tabs;
