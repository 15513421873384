import * as yup from "yup";

const today = new Date();
const minDate = new Date(today);
const maxDate = new Date(today);

minDate.setDate(minDate.getDate() - 30);
minDate.setHours(0, 0, 0, 0);
maxDate.setDate(maxDate.getDate() + 30);

export const coverRequirementsSchema = yup.object().shape({
  claimBasis: yup.string().required(),
  inceptionDate: yup.date().min(minDate).max(maxDate).required(),
  indemnityPeriod: yup
    .string()
    .when("$hasSomeBi", (hasSomeBi, schema) => (hasSomeBi ? schema.required() : schema)),
  policyTerm: yup.string().required(),
});
