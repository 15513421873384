import { Auth } from "aws-amplify";
import React, { Component } from "react";
import { queryCache } from "react-query";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../constants";

class Logout extends Component {
  componentDidMount() {
    queryCache.removeQueries("user");
    queryCache.clear();

    window.hideZe();
    Auth.signOut();
  }

  render() {
    return <Redirect to={ROUTES.LOGIN} />;
  }
}

export default Logout;
