import PropTypes from "prop-types";
import React from "react";
import Svg from "./Svg";

const Icon = ({ name, ...props }) => (
  <div {...props}>
    <Svg name={name} />
  </div>
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Icon;
