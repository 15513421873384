import { STATUSES } from "./statuses";

export const STATUS_TEXT_COLOR_MAP = {
  [STATUSES.BOUND]: "text-green-900",
  [STATUSES.DECLINE_TO_QUOTE]: "text-red-900",
  [STATUSES.NOT_TAKEN_UP]: "text-red-900",
  [STATUSES.QUOTED]: "text-green-900",
  [STATUSES.REFERRED]: "text-red-900",
  [STATUSES.REFERRED_QUOTED]: "text-green-900",
};

export default STATUS_TEXT_COLOR_MAP;
