import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMutation } from "react-query";
import * as api from "../api";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCalendar from "../components/InputCalendar";
import Modal from "../components/Modal";

const DownloadBordereauModal = ({ handleClose }) => {
  const [getBordereau, getBordereauQuery] = useMutation(api.getBordereau);

  const [formValues, setFormValues] = useState({ period: undefined });
  const onChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    const year = formValues.period.getFullYear();
    const month = formValues.period.toLocaleString("default", { month: "long" });

    const res = await getBordereau({ month, year });
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", `bordereau-${year}-${month}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Modal handleClose={handleClose} headingText="Download bordereau">
      <div className="p-6 w-160">
        <Form onSubmit={handleSubmit}>
          <InputCalendar
            className="mb-4"
            dayPickerProps={{
              fromMonth: new Date(2020, 9),
              toMonth: new Date(),
              month: new Date(),
              disabledDays: [{ before: new Date(2020, 9) }],
            }}
            isRequired
            labelText="Choose a date"
            placeholder="MM/YYYY"
            format="MM/YYYY"
            onChange={onChange}
            value={formValues.period}
            name="period"
          />

          <div className="flex">
            <Button
              className="mr-4"
              isDisabled={getBordereauQuery.isLoading || !formValues.period}
              kind="primary"
              type="submit"
            >
              Download
            </Button>

            <Button onClick={handleClose}>Cancel</Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

DownloadBordereauModal.propTypes = {
  handleClose: PropTypes.func,
};

DownloadBordereauModal.defaultProps = {
  handleClose: undefined,
};

export default DownloadBordereauModal;
