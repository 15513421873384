import PropTypes from "prop-types";
import React from "react";
import * as yup from "yup";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCurrency from "../components/InputCurrency";
import Modal from "../components/Modal";
import { STATUSES } from "../constants";
import { useContractQuery, useForm } from "../hooks";

const CreateCommercialPriceModal = ({ handleClose, contractData }) => {
  const { handleSubmitContract, isSubmittingContract } = useContractQuery();

  const handleSubmit = async (formValues) => {
    await handleSubmitContract(
      { status: STATUSES.REFERRED_QUOTED, commercial: formValues.commercialPrice },
      contractData
    );

    handleClose();
  };

  const { getFieldProps, canSubmit, onSubmit } = useForm({
    initialState: { commercialPrice: null },
    onSubmit: handleSubmit,
    validationSchema: yup.object().shape({
      commercialPrice: yup.object().shape({
        amount: yup.number().required(),
        currency: yup.string().required(),
      }),
    }),
  });

  return (
    <Modal handleClose={handleClose} headingText="Create commercial price">
      <div className="p-6 w-160">
        <Form onSubmit={onSubmit}>
          <p className="mb-4">Please provide premium net of commission and IPT</p>

          <InputCurrency
            className="mb-6"
            currencies={["GBP"]}
            labelText="New commercial price"
            name="commercialPrice"
            {...getFieldProps("commercialPrice")}
          />

          <div className="flex">
            <Button
              className="mr-4"
              isDisabled={isSubmittingContract || !canSubmit}
              kind="primary"
              type="submit"
            >
              Re-quote price
            </Button>

            <Button kind="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

CreateCommercialPriceModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  contractData: PropTypes.object.isRequired,
};

export default CreateCommercialPriceModal;
