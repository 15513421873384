import PropTypes from "prop-types";
import React from "react";
import Input from "./Input";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const InputText = ({
  className,
  descriptionText,
  icon,
  inputClassName,
  isReadOnly,
  labelText,
  link,
  ...props
}) => (
  <InputField
    className={className}
    descriptionText={descriptionText}
    icon={icon}
    labelText={labelText}
    link={link}
    {...props}
  >
    {!isReadOnly && <Input className={inputClassName} {...props} />}

    {isReadOnly && <InputReadOnly {...props} />}
  </InputField>
);

InputText.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  icon: PropTypes.node,
  inputClassName: PropTypes.string,
  isReadOnly: PropTypes.bool,
  labelText: PropTypes.string,
  link: PropTypes.node,
};

InputText.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  icon: undefined,
  inputClassName: undefined,
  isReadOnly: undefined,
  labelText: undefined,
  link: undefined,
};

export default InputText;
