import * as yup from "yup";

export const currencySchema = yup.object().shape({
  amount: yup.number().default(0).required(),
  currency: yup.mixed().oneOf(["GBP"]).default("GBP").required(),
});

export const addressSchema = yup.object().shape({
  city: yup.string().trim().required(),
  country: yup.string().trim().default("United Kingdom").required(),
  line1: yup.string().trim().required(),
  line2: yup.string().trim(),
  postCode: yup.string().trim().required(),
});

export const locationSchema = yup.object().shape({
  address: addressSchema,
  businessInterruption: currencySchema.when("$isCompany", (isCompany, schema) =>
    isCompany ? schema : schema.strip()
  ),
  id: yup.string().when("$stripIds", (stripIds, schema) => (stripIds ? schema.strip() : schema)),
  locationType: yup
    .mixed()
    .required()
    .when("$isCommercial", (isCommercial, schema) =>
      isCommercial ? schema.notOneOf(["Residential"]) : schema.oneOf(["Residential"])
    ),
  propertyDamage: currencySchema.required(),
  rates: currencySchema,
});

export const locationsSchema = yup.array().of(locationSchema);

export const locationsScheduleSchema = yup.object().shape({
  businessType: yup
    .mixed()
    .when("$isCompany", (isCompany, schema) =>
      isCompany
        ? schema.oneOf(["Commercial", "Residential"]).required()
        : schema.oneOf(["Residential"]).required()
    ),

  policyLimit: yup.object().shape({
    amount: yup.number().max(yup.ref("$tivAmount")).positive().required(),
    currency: yup.mixed().oneOf(["GBP"]).required(),
  }),
});
