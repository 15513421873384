import cloneDeep from "lodash.clonedeep";
import get from "lodash.get";
import set from "lodash.set";
import { useEffect, useRef, useState } from "react";

const useForm = ({ initialState, validationSchema, onSubmit, validationOptions }) => {
  const [isLoading, setLoading] = useState();
  const [formValues, setFormValues] = useState(initialState);
  const [formError, setFormError] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);

  const hasSchema = validationSchema && Object.keys(validationSchema).length > 0;
  const isValid = hasSchema && validationSchema.isValidSync(formValues, validationOptions);
  const canSubmit = !isLoading && isValid;

  const mountedRef = useRef(true);

  const onChange = (...args) => {
    const clonedFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;
      const isCheckbox = get(event, "target.type") === "checkbox";
      const value = isCheckbox ? event.target.checked : event.target.value;

      set(clonedFormValues, event.target.name, value);
    }

    if (args.length === 2) {
      const [value, name] = args;

      set(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await onSubmit(formValues);

      if (!mountedRef.current) {
        return false;
      }

      return setLoading(false);
    } catch (error) {
      setLoading(false);

      return setFormError(get(error, "message"));
    }
  };

  const getFieldProps = (...args) => {
    if (args.length === 1) {
      const [name] = args;

      return { name, value: get(formValues, name), isChecked: get(formValues, name), onChange };
    }

    if (args.length === 2) {
      const [name, value] = args;

      return { name, value, isChecked: get(formValues, name) === value, onChange };
    }

    return { ...args };
  };

  useEffect(() => {
    if (validationSchema) {
      try {
        validationSchema.validateSync(formValues, validationOptions);
        setValidationErrors([]);
      } catch (error) {
        setValidationErrors(error.errors);
      }
    }
  }, [formValues]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return {
    canSubmit,
    formError,
    formErrors,
    formValues,
    getFieldProps,
    isLoading,
    isValid,
    onSubmit: handleSubmit,
    setFormError,
    setFormErrors,
    setFormValues,
    validationErrors,
  };
};

export default useForm;
