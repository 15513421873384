import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Modal from "../components/Modal";

const WillBeReferredWarningModal = ({
  contractData,
  handleClose,
  handleConfirm,
  isSubmitDisabled,
}) => (
  <Modal handleClose={handleClose} headingText="Refer to underwriter">
    <div className="p-6 w-160">
      <p className="mb-4">
        This submission will be referred to the underwriter. You can either continue to referral or
        review the submission and amend as appropriate.
      </p>

      <Banner headingText="Referral reason(s):" className="mb-6" color="orange">
        <ul className="list-disc list-inside">
          {get(contractData, "wouldBeReferred", []).map((reason) => (
            <li key={reason}>{reason}</li>
          ))}
        </ul>
      </Banner>

      <div className="flex">
        <Button
          kind="primary"
          onClick={handleConfirm}
          className="mr-4"
          isDisabled={isSubmitDisabled}
        >
          Confirm referral
        </Button>

        <Button onClick={handleClose}>Amend submission</Button>
      </div>
    </div>
  </Modal>
);

WillBeReferredWarningModal.propTypes = {
  contractData: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  isSubmitDisabled: PropTypes.bool,
};

WillBeReferredWarningModal.defaultProps = {
  contractData: undefined,
  isSubmitDisabled: undefined,
};

export default WillBeReferredWarningModal;
