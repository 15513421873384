import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { ROUTES } from "../constants";

const SanctionsCheckNoResults = ({ handleClose, contractId, contractType }) => {
  const { push } = useHistory();
  const path = compile(ROUTES.CONTRACT_STEPPER)({ contractId, contractType, step: "locations" });

  return (
    <Modal handleClose={handleClose} headingText="Sanctions check - no results">
      <div className="p-6 w-160">
        <p className="mb-6">
          The sanctions check returned no results for your insured details. Please carry out a
          manual check outside of this system.
        </p>

        <div className="flex">
          <Button kind="primary" onClick={() => push(path, { isForced: true })} className="mr-4">
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};

SanctionsCheckNoResults.propTypes = {
  handleClose: PropTypes.func.isRequired,
  contractId: PropTypes.string.isRequired,
  contractType: PropTypes.string.isRequired,
};

export default SanctionsCheckNoResults;
