import { currencyFormatter } from "../../formatters";

export const columns = [
  {
    displayName: "Outcode",
    path: "outcode",
  },
  {
    displayName: "Exposure",
    path: "exposure",
    formatter: currencyFormatter,
  },
  {
    displayName: "Capacity",
    path: "capacity",
    formatter: currencyFormatter,
  },
  {
    displayName: "Saturated",
    path: "isSaturated",
  },
];
