import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Datapoint from "../components/Datapoint";
import Modal from "../components/Modal";
import { STATUSES } from "../constants";
import { currencyFormatter } from "../formatters";
import { useContractQuery } from "../hooks";

const AcceptTechnicalPriceModal = ({ handleClose, quoteData, contractData }) => {
  const { handleSubmitContract, isSubmittingContract } = useContractQuery(contractData);

  const handleSubmit = async () => {
    await handleSubmitContract({ status: STATUSES.REFERRED_QUOTED }, contractData);

    handleClose();
  };

  return (
    <Modal handleClose={handleClose} headingText="Accept technical price">
      <div className="p-6 w-160">
        <p className="mb-4">
          Are you sure you’d like to accept the technical price and submit it to the broker?
        </p>

        <Datapoint
          className="mb-4 bg-gray-100 px-6 py-4"
          contentClassName="font-semibold text-lg"
          labelText="Technical price (Net premium excl. tax & commission)"
        >
          {currencyFormatter(quoteData.rates.net)}
        </Datapoint>

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={isSubmittingContract}
            kind="primary"
            onClick={handleSubmit}
          >
            Accept price
          </Button>

          <Button kind="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AcceptTechnicalPriceModal.propTypes = {
  contractData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  quoteData: PropTypes.object.isRequired,
};

export default AcceptTechnicalPriceModal;
