import React from "react";
import Loader from "./Loader";

const PageLoader = () => (
  <div
    className="fixed inset-0 w-screen h-screen flex items-center justify-center"
    style={{ backgroundColor: "rgba(255, 255, 255, 0.6)" }}
  >
    <div className="text-center">
      <Loader className="inline-block" />
      <p className="mt-2 text-sm">Loading…</p>
    </div>
  </div>
);

export default PageLoader;
