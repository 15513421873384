import { STATUSES } from "./statuses";

export const POLICY_STATUSES = [
  STATUSES.BOUND,
  STATUSES.CANCELLED,
  STATUSES.INFORCE,
  STATUSES.LAPSED,
];

export default POLICY_STATUSES;
