import Tippy from "@tippyjs/react";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
/* eslint-disable-next-line import/no-extraneous-dependencies */
import "tippy.js/dist/tippy.css";
import Svg from "./Svg";

const InfoTooltip = ({ className, ...props }) => (
  <Tippy {...props}>
    <div className={clsx("w-4 h-4 fill-current text-gray-800", className)}>
      <Svg name="info" />
    </div>
  </Tippy>
);

InfoTooltip.propTypes = {
  className: PropTypes.string,
};

InfoTooltip.defaultProps = {
  className: undefined,
};

export default InfoTooltip;
