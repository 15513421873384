/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { KEY_CODES } from "../constants";
import Card from "./Card";
import Icon from "./Icon";

const Header = ({ handleClose, children }) => (
  <Card.Header className="relative">
    <div className="absolute top-0 right-0 mr-4 mt-4">
      <Icon
        className="w-6 h-6 p-1 hover:bg-gray-400 rounded-sm cursor-pointer fill-current text-gray-800"
        name="cross"
        onClick={handleClose}
        role="button"
        tabIndex="0"
      />
    </div>
    {children}
  </Card.Header>
);

const Modal = ({ handleClose, children, headingText, descriptionText }) => {
  const handleKeyDown = (event) => {
    if (event.keyCode === KEY_CODES.ESCAPE) {
      handleClose(event);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div className="fixed inset-0 z-30 flex justify-center items-center">
      <div
        className="fixed inset-0 z-30 "
        onClick={handleClose}
        role="presentation"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
        tabIndex="-1"
      />

      <Card
        className="z-30"
        role="dialog"
        aria-labelledby="dialogTitle"
        aria-describedby="dialogDescription"
      >
        {(headingText || descriptionText) && (
          <Header handleClose={handleClose}>
            {headingText && <Card.Heading id="dialogTitle">{headingText}</Card.Heading>}

            {descriptionText && (
              <Card.Description id="dialogDescription">{headingText}</Card.Description>
            )}
          </Header>
        )}
        {children}
      </Card>
    </div>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
};

Header.defaultProps = {
  children: undefined,
};

Modal.propTypes = {
  children: PropTypes.node,
  descriptionText: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  headingText: PropTypes.string,
};

Modal.defaultProps = {
  children: undefined,
  descriptionText: undefined,
  headingText: undefined,
};

Modal.Content = Card.Content;
Modal.Description = Card.Description;
Modal.Header = Header;
Modal.Heading = Card.Heading;

export default Modal;
