import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";

const EditQuoteModal = ({ handleClose, handleConfirm }) => (
  <Modal handleClose={handleClose} headingText="Edit quote - Warning">
    <div className="p-6 w-160">
      <p className="mb-6">
        Please note, you will lose all of the prices you’ve created on this quote so far.
      </p>

      <div className="flex">
        <Button kind="primary" onClick={handleConfirm} className="mr-4">
          Edit quote
        </Button>

        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </div>
  </Modal>
);

EditQuoteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default EditQuoteModal;
