/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import get from "lodash.get";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useQuery } from "react-query";
import * as api from "../../api";
import Button from "../../components/Button";
import Form from "../../components/Form";
import H3 from "../../components/H3";
import InputDate from "../../components/InputDate";
import InputText from "../../components/InputText";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { ACTIONS } from "../../constants";
import { useAuth } from "../../hooks";
import { individualColumns } from "./config";
import { searchIndividualSchema } from "./schema";

const IndividualSearchForm = ({
  contractData,
  selectedInsured,
  setSelectedInsured,
  handleCreateNewIndividualClick,
}) => {
  const { checkPermissions } = useAuth(contractData);
  const [searchQuery, setSearchQuery] = useState();

  const isExistingIndividual = get(contractData, "insured.Individual");
  const existingIndividualData = get(contractData, "insured.Individual", {});
  const hasExistingIndividualDob = existingIndividualData.dateOfBirth;
  const existingIndividualDob = moment.utc(existingIndividualData.dateOfBirth);

  const initialValues = {
    ...existingIndividualData,
    ...existingIndividualData.address,
    day: hasExistingIndividualDob && existingIndividualDob.format("DD"),
    month: hasExistingIndividualDob && existingIndividualDob.format("MM"),
    year: hasExistingIndividualDob && existingIndividualDob.format("YYYY"),
    dob: hasExistingIndividualDob && existingIndividualDob.format("DD/MM/YYYY"),
  };

  const [values, setValues] = useState(searchIndividualSchema.cast(initialValues));
  const isSearchQueryValid = searchIndividualSchema.isValidSync(values);
  const castValues = searchIndividualSchema.cast(values);

  const individualsQuery = useQuery(["individuals", searchQuery], api.getIndividuals, {
    enabled: searchQuery,
  });

  const initialIndividualsData = isExistingIndividual ? [initialValues] : [];
  const individualsData = get(individualsQuery, "data.data", initialIndividualsData);
  const mappedIndividualsData = individualsData.map((item) => ({
    ...item,
    line1: item.line1 || item.addressLine1,
    line2: item.line2 || item.addressLine2,
  }));

  const individualsCount = individualsData.length;
  const hasResults = searchQuery && individualsCount > 0 && individualsQuery.isSuccess;
  const isLoading = searchQuery && individualsQuery.isLoading;

  const columnsByRole = individualColumns.filter(
    (column) => !(column.permissions && !checkPermissions(column.permissions))
  );

  const handleFormSubmit = () => {
    const { day, month, year, firstName, lastName } = castValues;
    const dob = moment({ day, month: month - 1, year }).format("DD/MM/YYYY");

    setSearchQuery({ dob, firstName, lastName });
    setSelectedInsured(undefined);
  };

  const handleFormChange = (event) => {
    const newValues = { ...values, [event.target.name]: event.target.value };

    setValues(newValues);
  };

  const renderData = ({ row, column, value }) => {
    if (column.displayName === "Action") {
      if (row.id === selectedInsured) {
        return (
          <Button kind="secondary" size="small" className="-my-2 text-blue-600">
            Selected
          </Button>
        );
      }

      return (
        <Button
          kind="secondary"
          size="small"
          className="-my-2 "
          onClick={() => setSelectedInsured(row.id)}
        >
          Select
        </Button>
      );
    }

    return value;
  };

  return (
    <>
      {checkPermissions(ACTIONS.EDIT_INSURED) && (
        <Form name="individualSearchForm" onSubmit={handleFormSubmit} className="mb-4" hasContext>
          <H3 className="mb-6">Search for an individual’s full name and date of birth</H3>

          <div className="flex items-start">
            <InputText
              className="mr-6"
              isRequired
              labelText="First Name"
              name="firstName"
              onChange={handleFormChange}
              value={values.firstName}
            />

            <InputText
              className="mr-6"
              isRequired
              labelText="Last Name"
              name="lastName"
              onChange={handleFormChange}
              value={values.lastName}
            />

            <InputDate
              className="mr-6"
              descriptionText="Format: DD/MM/YYYY"
              isRequired
              labelText="Date of Birth"
              name="dob"
              onChange={handleFormChange}
              placeholderText={["DD", "MM", "YYYY"]}
              values={values}
            />

            <Button
              className="mt-8 w-32"
              isDisabled={!isSearchQueryValid || individualsQuery.isLoading}
              kind="primary"
              type="submit"
            >
              Search
            </Button>
          </div>
        </Form>
      )}

      {((searchQuery && individualsQuery.isSuccess) || isExistingIndividual) &&
        checkPermissions(ACTIONS.EDIT_INSURED) && (
          <p className="mb-8">
            {individualsCount === 0 && (
              <span>We couldn’t find anyone that matched those details. Please try again or </span>
            )}

            {individualsCount > 0 && (
              <span>
                We found <span className="font-medium text-blue-600">{individualsCount}</span>{" "}
                {individualsCount > 1 ? "matches" : "match"} with those details. Not a match?{" "}
              </span>
            )}

            <span
              className="font-medium text-blue-600 cursor-pointer"
              onClick={() => handleCreateNewIndividualClick(castValues)}
            >
              Create a new individual
            </span>
          </p>
        )}

      {isLoading && <Loader className="mt-32 mx-auto" />}

      {(hasResults || isExistingIndividual) && !isLoading && (
        <Table
          columns={columnsByRole}
          rows={mappedIndividualsData}
          renderData={renderData}
          isStriped
        />
      )}
    </>
  );
};

IndividualSearchForm.propTypes = {
  contractData: PropTypes.object,
  selectedInsured: PropTypes.string,
  setSelectedInsured: PropTypes.func,
  handleCreateNewIndividualClick: PropTypes.func,
};

IndividualSearchForm.defaultProps = {
  contractData: undefined,
  selectedInsured: undefined,
  setSelectedInsured: undefined,
  handleCreateNewIndividualClick: undefined,
};

export default IndividualSearchForm;
