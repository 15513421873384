import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";

const UnsavedChangesModal = ({ handleClose, handleConfirm }) => (
  <Modal handleClose={handleClose} headingText="Unsaved changes">
    <div className="p-6 w-160">
      <p className="mb-6">
        You have unsaved changes on this step, are you sure you want to go back?
      </p>

      <div className="flex">
        <Button onClick={handleClose} className="mr-4">
          Cancel
        </Button>

        <Button kind="primary" onClick={handleConfirm}>
          Go back
        </Button>
      </div>
    </div>
  </Modal>
);

UnsavedChangesModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default UnsavedChangesModal;
