import PropTypes from "prop-types";
import React from "react";
import * as yup from "yup";
import Button from "../components/Button";
import Form from "../components/Form";
import InputSelect from "../components/InputSelect";
import InputTextarea from "../components/InputTextarea";
import Modal from "../components/Modal";
import { QUOTE_STATUSES, STATUSES } from "../constants";
import { useContractQuery, useForm, useQuoteQuery } from "../hooks";

const RejectLastPriceModal = ({ handleClose, refData, quoteData, contractData }) => {
  const { handleSubmitContract, isSubmittingContract } = useContractQuery();
  const { handleSubmitQuote, isSubmittingQuote } = useQuoteQuery();

  const handleSubmit = async (formValues) => {
    await handleSubmitQuote({ status: QUOTE_STATUSES.NOT_TAKEN_UP }, quoteData);
    await handleSubmitContract(
      {
        status: STATUSES.NOT_TAKEN_UP,
        notTakenUp: {
          note: formValues.note === "" ? undefined : formValues.note,
          reason: formValues.reason,
        },
      },
      contractData
    );

    handleClose();
  };

  const { getFieldProps, canSubmit, onSubmit } = useForm({
    initialState: { reason: undefined, note: "" },
    onSubmit: handleSubmit,
    validationSchema: yup.object().shape({
      note: yup.string(),
      reason: yup.string().required(),
    }),
  });

  return (
    <Modal handleClose={handleClose} headingText="Reject price">
      <div className="p-6 w-160">
        <Form onSubmit={onSubmit}>
          <InputSelect
            className="mb-6"
            descriptionText="Select a reason for the status change"
            isRequired
            items={refData.notTakenUpReasons}
            labelText="Reason"
            {...getFieldProps("reason")}
          />

          <InputTextarea
            className="mb-6"
            labelText="Add a note (optional)"
            {...getFieldProps("note")}
          />

          <div className="flex">
            <Button
              className="mr-4"
              isDisabled={isSubmittingQuote || isSubmittingContract || !canSubmit}
              kind="primary"
              type="submit"
            >
              Confirm status change
            </Button>

            <Button kind="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

RejectLastPriceModal.propTypes = {
  contractData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  quoteData: PropTypes.object.isRequired,
  refData: PropTypes.object.isRequired,
};

export default RejectLastPriceModal;
