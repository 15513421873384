import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";

const DiscardChangesModal = ({ handleClose, handleConfirm }) => (
  <Modal handleClose={handleClose} headingText="Discard and exit">
    <div className="p-6 w-160">
      <p className="mb-6">
        Are you sure you want to exit? Any changes you have made on this step will be lost.
      </p>

      <div className="flex">
        <Button onClick={handleClose} className="mr-4">
          Cancel
        </Button>

        <Button kind="danger" onClick={handleConfirm}>
          Discard and exit
        </Button>
      </div>
    </div>
  </Modal>
);

DiscardChangesModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default DiscardChangesModal;
