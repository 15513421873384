import PropTypes from "prop-types";
import React from "react";

const Footer = ({ children }) => (
  <div className="h-24 bg-white fixed bottom-0 inset-x-0 border-t border-gray-500 flex items-center justify-between px-8 z-20">
    {children}
  </div>
);

Footer.propTypes = {
  children: PropTypes.node,
};

Footer.defaultProps = {
  children: undefined,
};

export default Footer;
