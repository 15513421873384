import { compile } from "path-to-regexp";

export const ENDPOINTS = {
  ATTACHMENT_LINK: compile("document/:contractId/presign/document/:attachmentId"),
  ATTACHMENT: compile("document/:contractId"),
  BORDEREAU: compile("bordereau/:month/:year"),
  COMPANIES: "companieshouse",
  CONTRACT_ADDTL_PREMIUMS: compile("contract/:contractId/fe-step-4-price"),
  CONTRACT_FLAG_CLAIM: compile("contract/:contractId/flag-claim"),
  CONTRACT_FLAG_PREMIUM_PAID: compile("contract/:contractId/flag-premium-paid"),
  CONTRACT_HISTORY: compile("contract/:contractId/history"),
  CONTRACT_PATCHES: compile("contract/:contractId/evolution"),
  CONTRACT_PREMIUMS: compile("contract/:contractId/fe-step-4-data"),
  CONTRACT: compile("contract/:contractId"),
  CONTRACTS: "contract",
  ENDORSEMENT_QUOTE: compile("/contract/:contractId/endorsement/quote"),
  ENDORSEMENT: compile("contract/:contractId/endorsement"),
  GROUPED_CONTRACT_HISTORY: compile("contract/:contractId/history/groupByStatus"),
  GROUPED_CONTRACT_PATCHES: compile("contract/:contractId/evolution/groupByStatus"),
  INDIVIDUALS: "insured-individuals",
  OUTCODE_EXPOSURES: "outcode-exposures",
  QUOTE_LINK: compile("document/:contractId/presign/price/:priceId"),
  QUOTE: compile("contract/:contractId/quote/:quoteId"),
  QUOTES: compile("contract/:contractId/quote"),
  REF_DATA: "ref-data",
  TENANTS: "tenants?flat=true",
  USERS: "users",
};

export default ENDPOINTS;
