import PropTypes from "prop-types";
import React from "react";

const Svg = ({ name }) => {
  switch (name) {
    case "upload":
      return (
        <svg viewBox="0 0 14 14">
          <path d="M0 1.63336L14 1.63336L14 2.88486e-05L1.42791e-07 2.76247e-05L0 1.63336Z" />
          <path d="M8.54175 14L4.79175 14L4.79175 8.44444L1.66675 8.44444L6.66675 4L11.6667 8.44444L8.54175 8.44444L8.54175 14Z" />
        </svg>
      );

    case "calendar":
      return (
        <svg viewBox="0 0 16 16">
          <path d="M16 3.886V2.9a.5.5 0 00-.5-.5h-2.014V0H10.97v2.514H5.03V0H2.514v2.514H.457A.457.457 0 000 2.971V15.5a.5.5 0 00.5.5h15a.5.5 0 00.5-.5V3.886zM1.6 5.943h12.686v8.343H1.6V5.943z" />
        </svg>
      );

    case "checkmark":
      return (
        <svg viewBox="0 0 12 10">
          <path d="M3.916 7.645L1.204 4.8l-.918.963 3.63 3.808 7.798-8.18-.918-.962-6.88 7.216z" />
        </svg>
      );

    case "warning":
      return (
        <svg viewBox="0 0 48 48">
          <path d="M24 4.8C13.396 4.8 4.8 13.396 4.8 24S13.396 43.2 24 43.2 43.2 34.604 43.2 24 34.604 4.8 24 4.8zM0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z" />
          <path d="M21.6 28.457V11.314h4.8v17.143h-4.8zM21.6 36.686v-4.8h4.8v4.8h-4.8z" />
        </svg>
      );

    case "paperclip":
      return (
        <svg viewBox="0 0 14 14">
          <path d="M9.045 3.182V10.5a2.545 2.545 0 11-5.09 0V2.545a1.591 1.591 0 013.181 0v6.682c0 .35-.286.637-.636.637a.638.638 0 01-.636-.637V3.182h-.955v6.045a1.591 1.591 0 003.182 0V2.545a2.545 2.545 0 10-5.091 0V10.5C3 12.434 4.565 14 6.5 14s3.5-1.566 3.5-3.5V3.182h-.955z" />
        </svg>
      );

    case "lock":
      return (
        <svg viewBox="0 0 12 16">
          <path
            fillRule="evenodd"
            d="M4.514 3.086C4.514 2.27 5.184 1.6 6 1.6c.815 0 1.486.67 1.486 1.486v2.628H4.514V3.086zm-1.6 2.628V3.086A3.092 3.092 0 016 0a3.092 3.092 0 013.086 3.086v2.628h2.628V16H.286V5.714h2.628zM6 12.571a1.714 1.714 0 100-3.428 1.714 1.714 0 000 3.428z"
            clipRule="evenodd"
          />
        </svg>
      );

    case "edit":
      return (
        <svg viewBox="0 0 16 16">
          <path d="M9.951 3.596l2.1-2.1a.5.5 0 01.707 0l1.746 1.746a.5.5 0 010 .707l-2.1 2.1-2.453-2.453zM8.948 4.599L11.4 7.052l-7.659 7.659a.5.5 0 01-.353.146H1.143v-2.246a.5.5 0 01.146-.353l7.659-7.659z" />
        </svg>
      );

    case "search":
      return (
        <svg viewBox="0 0 16 16">
          <path d="M5.486 1.6a3.886 3.886 0 100 7.771 3.886 3.886 0 000-7.771zM0 5.486a5.486 5.486 0 1110.971 0A5.486 5.486 0 010 5.486z" />
          <path d="M10.83 9.34a.5.5 0 01.244.134l4.572 4.572a.5.5 0 010 .708l-.892.892a.5.5 0 01-.707 0l-4.573-4.572a.5.5 0 01-.134-.245l-.426-1.915 1.915.426z" />
        </svg>
      );

    case "cross":
      return (
        <svg viewBox="0 0 14 14">
          <path d="M6.012 7L1 12.012l.988.988L7 7.988 12.012 13l.988-.988L7.988 7 13 1.988 12.012 1 7 6.012 1.988 1 1 1.988 6.012 7z" />
        </svg>
      );

    case "info":
      return (
        <svg viewBox="0 0 13 14">
          <path d="M6.5 12.2a5.2 5.2 0 100-10.4 5.2 5.2 0 000 10.4zM13 7A6.5 6.5 0 110 7a6.5 6.5 0 0113 0z" />
          <path d="M7.15 5.793v4.643h-1.3V5.793h1.3zM7.15 3.564v1.3h-1.3v-1.3h1.3z" />
        </svg>
      );

    case "chevron-up":
      return (
        <svg width="12" height="7">
          <path fillRule="evenodd" d="M0 6l1 1 5-5 5 5 1-1-6-6-1 1-5 5z" clipRule="evenodd" />
        </svg>
      );

    case "chevron-down":
      return (
        <svg width="12" height="7">
          <path fillRule="evenodd" d="M12 1l-1-1-5 5-5-5-1 1 6 6 1-1 5-5z" clipRule="evenodd" />
        </svg>
      );

    case "circle-edit":
      return (
        <svg width="32" height="32" fill="none">
          <circle cx="16" cy="16" r="16" fill="#339AF0" />
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M17.951 11.596l2.1-2.1a.5.5 0 01.707 0l1.746 1.746a.5.5 0 010 .707l-2.1 2.1-2.453-2.453zm-1.003 1.003l2.453 2.453-7.659 7.659a.5.5 0 01-.353.146H9.143v-2.246a.5.5 0 01.146-.353l7.659-7.659z"
            clipRule="evenodd"
          />
        </svg>
      );

    case "circle-check":
      return (
        <svg width="32" height="32" fill="none">
          <circle cx="16" cy="16" r="16" fill="#51CF66" />
          <path
            fill="#fff"
            d="M13.916 18.645L11.204 15.8l-.918.963 3.63 3.808 7.798-8.179-.918-.963-6.88 7.216z"
          />
        </svg>
      );

    case "circle-lock":
      return (
        <svg width="32" height="32" fill="none">
          <circle cx="16" cy="16" r="16" fill="#E9ECEF" />
          <path
            fill="#495057"
            fillRule="evenodd"
            d="M14.514 11.086c0-.816.67-1.486 1.486-1.486.815 0 1.486.67 1.486 1.486v2.628h-2.972v-2.628zm-1.6 2.628v-2.628A3.092 3.092 0 0116 8a3.092 3.092 0 013.086 3.086v2.628h2.628V24H10.286V13.714h2.628zM16 20.571a1.714 1.714 0 100-3.428 1.714 1.714 0 000 3.428z"
            clipRule="evenodd"
          />
        </svg>
      );

    case "logout":
      return (
        <svg width="15" height="14">
          <g fill="#495057" fillRule="evenodd">
            <path d="M10.42 0h-9a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h9.9a.5.5 0 00.5-.5v-3h-1.4v2.1h-8.1V1.4h8.1v2.1h1.4v-3a.5.5 0 00-.5-.5h-.9z" />
            <path d="M11.82 4.4l3.1 2.6-3.1 2.6V7.7h-7.8V6.3h7.8V4.4z" />
          </g>
        </svg>
      );

    default:
      return false;
  }
};

Svg.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Svg;
