import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import Icon from "./Icon";

const sizes = {
  normal: "h-12",
  small: "h-10",
};

const variants = {
  primary: "text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-1000 disabled:bg-blue-300",
  secondary:
    "active:bg-gray-200 bg-white border border-gray-500 disabled:text-gray-600 hover:bg-gray-100 text-gray-900",
  tertiary:
    "active:bg-gray-200 bg-white border border-gray-500 disabled:text-gray-600 hover:bg-gray-100 text-blue-800",
  "danger-secondary":
    "active:bg-gray-200 bg-white border border-gray-500 disabled:text-gray-600 hover:bg-gray-100 text-red-800",
  danger: "text-white bg-red-800 hover:bg-red-900 active:bg-red-1000 disabled:bg-red-500",
};

const Button = ({
  as: Component,
  children,
  className,
  iconName,
  isDisabled,
  kind,
  size,
  ...props
}) => (
  <Component
    className={clsx(
      "inline-flex items-center justify-center relative transition duration-75 ease-in font-medium text-sm rounded disabled:cursor-not-allowed focus:outline-none focus:shadow-outline",
      variants[kind],
      sizes[size],
      iconName ? "pl-10 pr-8" : "px-8",
      className
    )}
    disabled={isDisabled}
    type={Component === "button" ? Component : undefined}
    {...props}
  >
    {iconName && (
      <div className="flex w-6 z-10 ml-2 items-center justify-center absolute top-0 bottom-0 left-0">
        <Icon name={iconName} className="w-4 fill-current text-gray-800" />
      </div>
    )}

    {children}
  </Component>
);

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  className: PropTypes.string,
  iconName: PropTypes.string,
  isDisabled: PropTypes.bool,
  kind: PropTypes.oneOf(["danger", "danger-secondary", "primary", "secondary", "tertiary"]),
  size: PropTypes.oneOf(["normal", "small"]),
};

Button.defaultProps = {
  as: "button",
  children: undefined,
  className: undefined,
  iconName: undefined,
  isDisabled: undefined,
  kind: "secondary",
  size: "normal",
};

export default Button;
