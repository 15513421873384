/*
  The following regex will match against missing outcode error, e.g.

  "Error looking up data from ref table: Missing outcode outward: 'foo'"
*/

const missingOutcodeErrorRe = /missing outcode outward: .*$/i;

/*
  The following regex will match against server error postal code messages, e.g.

  "Error in $: $.product.locationsSchedule[0].address.postCode: Invalid postcode: foo
  $.product.locationsSchedule[1].address.postCode: Invalid postcode: bar"

  This requires global and multiline flags.
*/

const postCodeErrorRe = /address.postcode: invalid postcode: .*$/gim;

/*
  The following regex will return invalid postal code messages, e.g.

  "Invalid postcode: bar"
*/

const postCodeRe = /invalid postcode: (.*$)/i;

/*
  This function will take an error message and match it against different
  error message regexes. If no error is found, it will return the original message.
*/

const parseErrorMessages = (message) => {
  const invalidPostCodes = message.match(postCodeErrorRe);
  const missingOutcode = message.match(missingOutcodeErrorRe);

  if (invalidPostCodes) {
    const postCodesArr = invalidPostCodes.map((error) => error.match(postCodeRe)[1]);
    const postCodes = postCodesArr.join(", ");
    const isSingular = postCodesArr.length === 1;

    return `Invalid Postal Code${isSingular ? "" : "s"}: ${postCodes}`;
  }

  if (missingOutcode) {
    return missingOutcode[0];
  }

  return message;
};

export default parseErrorMessages;
