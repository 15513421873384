import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import Table from "./Table";

const TableDataPoint = ({
  bgClassName,
  children,
  className,
  descriptionText,
  isStriped,
  labelText,
}) => (
  <Table.Row
    className={clsx("border-b border-gray-500 last:border-0", className, bgClassName)}
    isStriped={isStriped}
  >
    <Table.Data className="w-2/5">
      <div className={clsx(descriptionText && "-my-2")}>
        <div>{labelText}</div>
        {descriptionText && <div className="text-gray-800 text-sm">{descriptionText}</div>}
      </div>
    </Table.Data>
    <Table.Data className="w-3/5">{children}</Table.Data>
  </Table.Row>
);

TableDataPoint.propTypes = {
  bgClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  isStriped: PropTypes.bool,
  labelText: PropTypes.string,
};

TableDataPoint.defaultProps = {
  bgClassName: undefined,
  children: undefined,
  className: undefined,
  descriptionText: undefined,
  isStriped: undefined,
  labelText: undefined,
};

export default TableDataPoint;
