import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import Accordion from "../../components/Accordion";
import CustomTag from "../../components/CustomTag";
import Table from "../../components/Table";
import TableDataPoint from "../../components/TableDataPoint";
import { addressFormatter, dateFormatter, fullNameFormatter } from "../../formatters";
import { checkUpdated, prepareData } from "../../helpers";

const dataPoints = {
  individualName: {
    labelText: "Individual name",
    formatter: fullNameFormatter,
    path: ["/insured/Individual/firstName", "/insured/Individual/lastName"],
  },
  dateOfBirth: {
    formatter: dateFormatter,
    labelText: "Date of birth",
    path: "/insured/Individual/dateOfBirth",
  },
  companyName: {
    labelText: "Company name",
    path: "/insured/Company/name",
  },
  companyNumber: {
    labelText: "Company number",
    path: "/insured/Company/number",
  },
  natureOfBusiness: {
    labelText: "Nature of business (SIC)",
    path: "/insured/Company/natureOfBusiness/0",
  },
  registeredAddress: {
    formatter: addressFormatter("\n"),
    labelText: "Registered address",
    className: "whitespace-pre",
    path: [
      "/insured/:insuredType/address/line1",
      "/insured/:insuredType/address/city",
      "/insured/:insuredType/address/county",
      "/insured/:insuredType/address/postCode",
      "/insured/:insuredType/address/country",
    ],
  },
};

const InsuredDetails = ({ contractData, patchData, showDiff, ...props }) => {
  const companyData = get(contractData, "insured.Company");
  const individualData = get(contractData, "insured.Individual");
  const insuredType = companyData ? "Company" : "Individual";
  const isUpdated = showDiff && checkUpdated(dataPoints, contractData, patchData, { insuredType });

  const data = prepareData({
    contractData,
    dataPoints,
    params: { insuredType },
    patchData,
    showDiff,
  });

  return (
    <Accordion.Panel
      {...props}
      headingText="Insured details"
      tag={isUpdated && <CustomTag kind="updated" />}
    >
      {companyData && (
        <Table>
          <Table.Body>
            <TableDataPoint {...data.companyName} />
            <TableDataPoint {...data.companyNumber} isStriped />
            <TableDataPoint {...data.natureOfBusiness} />
            <TableDataPoint {...data.registeredAddress} isStriped />
          </Table.Body>
        </Table>
      )}

      {individualData && (
        <Table>
          <Table.Body>
            <TableDataPoint {...data.individualName} />
            <TableDataPoint {...data.dateOfBirth} isStriped />
            <TableDataPoint {...data.registeredAddress} />
          </Table.Body>
        </Table>
      )}
    </Accordion.Panel>
  );
};

InsuredDetails.propTypes = {
  contractData: PropTypes.object.isRequired,
  patchData: PropTypes.array,
  showDiff: PropTypes.bool,
};

InsuredDetails.defaultProps = {
  patchData: [],
  showDiff: undefined,
};

export default InsuredDetails;
