import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";

const Svg = ({ stroke, strokeWidth, fill }) => (
  <svg width="24" height="24">
    <circle cx="12" cy="12" r="11" stroke={stroke} strokeWidth={strokeWidth} fill="none" />
    <circle cx="12" cy="12" r="6" stroke="none" strokeWidth="0" fill={fill} />
  </svg>
);

const InputRadio = ({
  className,
  labelText,
  isDisabled,
  isChecked,
  name,
  id,
  descriptionText,
  value,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const stroke = (isDisabled && "#CED4DA") || (isFocused && "#339AF0") || "#868E96";
  const strokeWidth = isFocused ? "2" : "1";
  const fill =
    (isChecked && !isDisabled && "#339AF0") || (isChecked && isDisabled && "#CED4DA") || "none";

  return (
    <label
      className={clsx(
        "relative flex items-start cursor-pointer",
        isDisabled && "text-gray-700",
        className
      )}
      htmlFor={id}
    >
      <Svg stroke={stroke} strokeWidth={strokeWidth} fill={fill} />
      <div className="ml-3">
        <div>{labelText}</div>
        {descriptionText && (
          <p className={clsx("text-sm mt-2", !isDisabled && "text-gray-800")}>{descriptionText}</p>
        )}
      </div>
      <input
        className="absolute opacity-0 -z-10"
        disabled={isDisabled}
        checked={isChecked}
        id={id}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        type="radio"
        value={value}
        {...props}
      />
    </label>
  );
};

Svg.propTypes = {
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
  fill: PropTypes.string,
};

Svg.defaultProps = {
  stroke: undefined,
  strokeWidth: undefined,
  fill: undefined,
};

InputRadio.propTypes = {
  className: PropTypes.string,
  labelText: PropTypes.string,
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  descriptionText: PropTypes.string,
  value: PropTypes.string,
};

InputRadio.defaultProps = {
  className: undefined,
  labelText: undefined,
  isDisabled: undefined,
  isChecked: undefined,
  name: undefined,
  id: undefined,
  descriptionText: undefined,
  value: undefined,
};

export default InputRadio;
