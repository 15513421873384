import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import InputField from "./InputField";

const InputTextarea = ({
  className,
  descriptionText,
  errors,
  isDisabled,
  isReadOnly,
  labelText,
  ...props
}) => (
  <InputField
    className={className}
    descriptionText={descriptionText}
    labelText={labelText}
    {...props}
  >
    <textarea
      className={clsx(
        "aui-input",
        "aui-input-textarea",
        isDisabled && "aui-input--disabled",
        isReadOnly && "aui-input--readonly",
        errors && "aui-input--errors",
        className
      )}
      {...props}
    />
  </InputField>
);

InputTextarea.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  labelText: PropTypes.string,
};

InputTextarea.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  errors: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  labelText: undefined,
};

export default InputTextarea;
