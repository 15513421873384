import { ACTIONS } from "../../constants";
import { commissionFormatter, currencyFormatter, signedCurrencyFormatter } from "../../formatters";

export const priceDataPointsMap = {
  deductible: {
    formatter: currencyFormatter,
    labelText: "Deductible amount",
    path: ["/quotes/:index/deductible/amount", "/quotes/:index/deductible/currency"],
    permissions: ACTIONS.VIEW_QUOTED_PREMIUM,
  },
  gross: {
    formatter: currencyFormatter,
    labelText: "Gross premium",
    descriptionText: "(incl. commission but excl. tax)",
    path: ["/quotes/:index/rates/gross/amount", "/quotes/:index/rates/gross/currency"],
    permissions: ACTIONS.VIEW_QUOTED_PREMIUM,
  },
  commission: {
    formatter: commissionFormatter,
    labelText: "Commission amount",
    path: [
      "/quotes/:index/rates/commission/amount",
      "/quotes/:index/rates/commission/currency",
      "/quotes/:index/commissionPercentage",
    ],
    permissions: ACTIONS.VIEW_QUOTED_PREMIUM,
  },
  ipt: {
    formatter: currencyFormatter,
    labelText: "Insurance premium tax",
    path: ["/quotes/:index/rates/ipt/amount", "/quotes/:index/rates/ipt/currency"],
    permissions: ACTIONS.VIEW_QUOTED_PREMIUM,
  },
  total: {
    className: "font-semibold",
    formatter: currencyFormatter,
    labelText: "Premium to pay",
    path: ["/quotes/:index/rates/total/amount", "/quotes/:index/rates/total/currency"],
    permissions: ACTIONS.VIEW_QUOTED_PREMIUM,
  },
};

export const quotesRatesDiffDataPointsMap = {
  gross: {
    formatter: signedCurrencyFormatter,
    labelText: "Gross premium",
    descriptionText: "(incl. commission but excl. tax)",
    path: [
      "/activeEndorsement/quotesRatesDiff/gross/amount",
      "/activeEndorsement/quotesRatesDiff/gross/currency",
    ],
  },
  commission: {
    formatter: signedCurrencyFormatter,
    labelText: "Commission amount",
    path: [
      "/activeEndorsement/quotesRatesDiff/commission/amount",
      "/activeEndorsement/quotesRatesDiff/commission/currency",
    ],
  },
  ipt: {
    formatter: signedCurrencyFormatter,
    labelText: "Insurance premium tax",
    path: [
      "/activeEndorsement/quotesRatesDiff/ipt/amount",
      "/activeEndorsement/quotesRatesDiff/ipt/currency",
    ],
  },
  total: {
    className: "font-semibold",
    formatter: signedCurrencyFormatter,
    labelText: "Premium to pay",
    path: [
      "/activeEndorsement/quotesRatesDiff/total/amount",
      "/activeEndorsement/quotesRatesDiff/total/currency",
    ],
  },
};
