import PropTypes from "prop-types";
import React from "react";
import Icon from "./Icon";
import Input from "./Input";

const InputSearch = ({
  className,
  descriptionText,
  icon,
  inputClassName,
  isReadOnly,
  labelText,
  link,
  ...props
}) => (
  <div className="relative">
    <Input className="aui-input-search" {...props} />

    <button
      type="submit"
      className="flex w-10 z-10 h-12 items-center justify-center absolute top-0 left-0 cursor-pointer"
    >
      <Icon name="search" className="w-3 fill-current text-gray-800" />
    </button>
  </div>
);

InputSearch.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  icon: PropTypes.node,
  inputClassName: PropTypes.string,
  isReadOnly: PropTypes.bool,
  labelText: PropTypes.string,
  link: PropTypes.node,
};

InputSearch.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  icon: undefined,
  inputClassName: undefined,
  isReadOnly: undefined,
  labelText: undefined,
  link: undefined,
};

export default InputSearch;
