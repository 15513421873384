import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import Button from "../components/Button";
import Form from "../components/Form";
import InputSelect from "../components/InputSelect";
import InputTextarea from "../components/InputTextarea";
import Modal from "../components/Modal";
import { ROUTES, STATUSES } from "../constants";
import { useContractQuery, useForm } from "../hooks";

const NotTakenUpModal = ({ handleClose, refData, contractData }) => {
  const { contractId, contractType } = useParams();
  const { handleSubmitContract, isSubmittingContract } = useContractQuery();
  const history = useHistory();

  const handleSubmit = async (formValues) => {
    const note = formValues.note === "" ? undefined : formValues.note;
    await handleSubmitContract(
      { status: STATUSES.NOT_TAKEN_UP, notTakenUp: { note, reason: formValues.reason } },
      contractData
    );

    handleClose();
    history.push(compile(ROUTES.CONTRACT)({ contractId, contractType }), { isForced: true });
  };

  const { getFieldProps, canSubmit, onSubmit } = useForm({
    initialState: { reason: undefined, note: "" },
    onSubmit: handleSubmit,
    validationSchema: yup.object().shape({
      note: yup.string(),
      reason: yup.string().required(),
    }),
  });

  return (
    <Modal handleClose={handleClose} headingText="Add a reason">
      <div className="p-6 w-160">
        <Form onSubmit={onSubmit}>
          <InputSelect
            className="mb-6"
            descriptionText="Add a reason for the status change"
            isRequired
            items={refData.notTakenUpReasons}
            labelText="Status description"
            {...getFieldProps("reason")}
          />

          <InputTextarea
            className="mb-6"
            labelText="Add a note (optional)"
            {...getFieldProps("note")}
          />

          <div className="flex">
            <Button
              className="mr-4"
              isDisabled={isSubmittingContract || !canSubmit}
              kind="primary"
              type="submit"
            >
              Confirm status change
            </Button>

            <Button onClick={handleClose}>Cancel</Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

NotTakenUpModal.propTypes = {
  contractData: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  refData: PropTypes.object,
};

NotTakenUpModal.defaultProps = {
  handleClose: undefined,
  refData: undefined,
};

export default NotTakenUpModal;
