import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const variants = {
  blue: "bg-blue-100 border-blue-600",
  green: "bg-green-200 border-green-700",
  orange: "bg-orange-100 border-orange-700",
  red: "bg-red-100 border-red-1000",
};

const Banner = ({ className, children, color, headingText }) => (
  <div className={clsx("py-4 px-4 rounded border-solid border-t-8", variants[color], className)}>
    {headingText && <p className="font-medium mb-2">{headingText}</p>}

    {children}
  </div>
);

Banner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(["blue", "green", "orange", "red"]),
  headingText: PropTypes.string,
};

Banner.defaultProps = {
  children: undefined,
  className: undefined,
  color: "green",
  headingText: undefined,
};

export default Banner;
