import React from "react";

const Logo = () => (
  <div
    className=" bg-cover bg-center"
    style={{ width: "140px", height: "50px", backgroundImage: "url(/Chaucer_White_Logo.png)" }}
  />
);

export default Logo;
