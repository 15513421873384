import PropTypes from "prop-types";
import React from "react";
import Tag from "./Tag";

const statusTags = {
  Bound: { text: "Bound", color: "green" },
  Cancelled: { text: "Cancelled", color: "red" },
  DeclineToQuote: { text: "Decline To Quote", color: "blue" },
  EndorsementApplied: { text: "Endorsement Applied", color: "indigo" },
  EndorsementInforce: { text: "Endorsement Inforce", color: "indigo" },
  EndorsementQuoted: { text: "Endorsement Quoted", color: "indigo" },
  EndorsementSubmitted: { text: "Endorsement Submitted", color: "indigo" },
  Expired: { text: "Expired", color: "violet" },
  Inforce: { text: "Inforce", color: "blue" },
  Lapsed: { text: "Lapsed", color: "blue" },
  NewQuote: { text: "New Quote", color: "pink" },
  NotTakenUp: { text: "Not Taken Up", color: "red" },
  QuoteArchived: { text: "Archived", color: "orange" },
  QuoteBound: { text: "Bound", color: "blue" },
  QuoteCancelled: { text: "Cancelled", color: "red" },
  Quoted: { text: "Quoted", color: "blue" },
  QuotedReferred: { text: "Quoted Referred", color: "orange" },
  QuoteExpired: { text: "Quote Expired", color: "blue" },
  QuoteNotTakenUp: { text: "Not Taken Up", color: "red" },
  QuoteSubmitted: { text: "Submitted", color: "green" },
  Referred: { text: "Referred", color: "orange" },
  Submitted: { text: "Submitted", color: "indigo" },
};

const StatusTag = ({ status, className }) => <Tag className={className} {...statusTags[status]} />;

StatusTag.propTypes = {
  className: PropTypes.string,
  status: PropTypes.oneOf([
    "Bound",
    "Cancelled",
    "DeclineToQuote",
    "EndorsementApplied",
    "EndorsementInforce",
    "EndorsementQuoted",
    "EndorsementSubmitted",
    "Expired",
    "Inforce",
    "Lapsed",
    "NewQuote",
    "NotTakenUp",
    "QuoteArchived",
    "QuoteBound",
    "QuoteCancelled",
    "Quoted",
    "QuotedReferred",
    "QuoteExpired",
    "QuoteNotTakenUp",
    "QuoteSubmitted",
    "Referred",
    "Submitted",
  ]).isRequired,
};

StatusTag.defaultProps = {
  className: undefined,
};

export default StatusTag;
