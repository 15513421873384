import clsx from "clsx";
import camelCase from "lodash.camelcase";
import PropTypes from "prop-types";
import React from "react";

const Datapoint = ({
  className,
  content,
  children,
  contentClassName,
  contentPrefix,
  bgClassName,
  labelText,
}) => {
  return (
    <div className={clsx("rounded p-2", bgClassName, className)}>
      <p className="text-sm mb-2 text-gray-800 flex-1" id={camelCase(`${labelText}Label`)}>
        {labelText}
      </p>
      {content && (
        <p className={contentClassName}>
          {contentPrefix}
          {content}
        </p>
      )}
      {children && (
        <p className={contentClassName} aria-labelledby={camelCase(`${labelText}Label`)}>
          {contentPrefix}
          {children}
        </p>
      )}
    </div>
  );
};

Datapoint.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node,
  children: PropTypes.node,
  contentClassName: PropTypes.string,
  contentPrefix: PropTypes.string,
  bgClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelText: PropTypes.string,
};

Datapoint.defaultProps = {
  className: undefined,
  content: undefined,
  children: undefined,
  contentClassName: undefined,
  contentPrefix: undefined,
  bgClassName: undefined,
  labelText: undefined,
};

export default Datapoint;
