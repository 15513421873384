import clsx from "clsx";
import Downshift from "downshift";
import PropTypes from "prop-types";
import React from "react";
import Icon from "./Icon";

const sizes = {
  normal: "h-12",
  small: "h-10",
};

const margins = {
  normal: "mt-12",
  small: "mt-10",
};

const Dropdown = ({ className, items, size, labelText, onSelect, name }) => (
  <Downshift itemToString={(item) => (item ? item.value || item : "")} onSelect={onSelect}>
    {({ getItemProps, getMenuProps, getToggleButtonProps, highlightedIndex, isOpen }) => (
      <div className={clsx("relative text-gray-800", className)}>
        <button
          {...getToggleButtonProps()}
          className={clsx(
            "input focus:outline-none w-full rounded px-3 focus:border-transparent text-left text-sm font-medium flex items-center justify-between truncate",
            sizes[size],
            isOpen &&
              "text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-1000 border border-transparent",
            !isOpen && "bg-white hover:bg-gray-200 border border-gray-500"
          )}
          name={name}
          type="button"
        >
          {labelText}
          {!isOpen && <Icon name="chevron-down" className="ml-2 fill-current text-gray-700" />}
          {isOpen && <Icon name="chevron-up" className="ml-2 fill-current text-white" />}
        </button>

        {isOpen && (
          <div className={clsx("absolute inset-x-0 top-0 z-10", margins[size])}>
            <ul
              {...getMenuProps()}
              className="input focus:outline-none mt-2 border border-gray-500 rounded max-h-56 overflow-y-auto min-w-56 bg-white"
            >
              {items.map((item, index) => (
                <li
                  name={item.value || item}
                  key={item.value || item}
                  disabled={item.disabled ? "true" : undefined}
                  className={clsx(
                    "text-sm font-medium flex items-center px-3 py-2 border-b border-gray-500 last:border-0",
                    item.disabled
                      ? "text-gray-700 cursor-not-allowed"
                      : "text-gray-1000 cursor-pointer",
                    index === highlightedIndex && "bg-gray-100",
                    sizes[size]
                  )}
                  {...getItemProps({ disabled: item.disabled, index, item })}
                >
                  {item.label || item}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    )}
  </Downshift>
);

Dropdown.propTypes = {
  className: PropTypes.string,
  initialSelectedItem: PropTypes.string,
  items: PropTypes.array,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onItemClick: PropTypes.func,
  onSelect: PropTypes.func,
  size: PropTypes.oneOf(["small", "normal"]),
};

Dropdown.defaultProps = {
  className: undefined,
  initialSelectedItem: undefined,
  items: undefined,
  labelText: undefined,
  name: undefined,
  onItemClick: undefined,
  onSelect: undefined,
  size: "normal",
};

export default Dropdown;
