/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const Heading = ({ className, children, tag, ...props }) => (
  <div className="flex items-center" {...props}>
    <div className={clsx("font-medium mr-4", className)}>{children}</div>

    {tag}
  </div>
);

const Description = ({ className, children }) => (
  <div className={clsx("text-sm leading-snug mt-2", className)}>{children}</div>
);

const Header = ({ className, headingText, descriptionText, children, tag, onClick }) => (
  <div
    className={clsx("p-4 bg-gray-100 border-b border-gray-400 rounded-t", className)}
    onClick={onClick}
  >
    {headingText && <Heading tag={tag}>{headingText}</Heading>}

    {descriptionText && <Description>{descriptionText}</Description>}

    {children}
  </div>
);

const Content = ({ className, children }) => (
  <div className={clsx("p-6", className)}>{children}</div>
);

const Card = ({ className, children, headingText, content, descriptionText, tag, ...props }) => (
  <div className={clsx("rounded border border-gray-500 bg-white", className)} {...props}>
    {(headingText || descriptionText) && (
      <Header headingText={headingText} descriptionText={descriptionText} tag={tag} />
    )}

    {content && <Content>{content}</Content>}

    {children}
  </div>
);

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tag: PropTypes.node,
};

Heading.defaultProps = {
  children: undefined,
  className: undefined,
  tag: undefined,
};

Description.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Description.defaultProps = {
  children: undefined,
  className: undefined,
};

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  headingText: PropTypes.string,
  onClick: PropTypes.func,
  tag: PropTypes.node,
};

Header.defaultProps = {
  children: undefined,
  className: undefined,
  descriptionText: undefined,
  headingText: undefined,
  onClick: undefined,
  tag: undefined,
};

Content.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

Content.defaultProps = {
  children: undefined,
  className: undefined,
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  content: PropTypes.string,
  descriptionText: PropTypes.string,
  headingText: PropTypes.string,
  tag: PropTypes.node,
};

Card.defaultProps = {
  children: undefined,
  className: undefined,
  content: undefined,
  descriptionText: undefined,
  headingText: undefined,
  tag: undefined,
};

Card.Content = Content;
Card.Description = Description;
Card.Header = Header;
Card.Heading = Heading;

export default Card;
