/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { useStickyState } from "../hooks";
import Card from "./Card";
import Icon from "./Icon";

const Panel = ({ children, isExpanded, tag, headingText, label, onClick }) => (
  <div className="border-gray-400 border-b last:border-b-0">
    <div onClick={onClick} className="p-4 bg-gray-100 cursor-pointer select-none">
      <div className="flex justify-between">
        <Card.Heading tag={tag}>{headingText}</Card.Heading>
        <div className="flex items-center">
          <div>{label}</div>
          <Icon
            name={isExpanded ? "chevron-up" : "chevron-down"}
            className="mx-4 fill-current text-blue-600"
          />
        </div>
      </div>
    </div>
    {isExpanded && <div className="border-t border-gray-400">{children}</div>}
  </div>
);

const CardAccordion = ({ className, children, initialActiveIds, id }) => {
  const [selectedIds, setSelectedIds] = useStickyState(initialActiveIds, id);

  const handleItemClick = (itemId, isExpanded) => (event) => {
    const nextIds = isExpanded
      ? selectedIds.filter((item) => item !== itemId)
      : [...selectedIds, itemId];

    event.stopPropagation();

    return setSelectedIds(nextIds);
  };

  return (
    <div className={clsx("border-gray-400 border rounded", className)}>
      {React.Children.map(children, (child) => {
        const itemId = child.props.id || child.props.headingText;
        const isExpanded = selectedIds.includes(itemId);

        return React.cloneElement(child, {
          isExpanded,
          onClick: handleItemClick(itemId, isExpanded),
        });
      })}
    </div>
  );
};

Panel.propTypes = {
  children: PropTypes.node,
  headingText: PropTypes.string,
  isExpanded: PropTypes.bool,
  label: PropTypes.node,
  onClick: PropTypes.func,
  tag: PropTypes.node,
};

Panel.defaultProps = {
  children: undefined,
  headingText: undefined,
  isExpanded: false,
  label: undefined,
  onClick: undefined,
  tag: undefined,
};

CardAccordion.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  initialActiveIds: PropTypes.arrayOf(PropTypes.string),
};

CardAccordion.defaultProps = {
  children: undefined,
  className: undefined,
  id: undefined,
  initialActiveIds: [],
};

CardAccordion.Panel = Panel;

export default CardAccordion;
