import clsx from "clsx";
import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import InputRadio from "../../components/InputRadio";
import Table from "../../components/Table";
import { ACTIONS } from "../../constants";
import { currencyFormatter } from "../../formatters";

const coversMap = {
  Threat: "Threat",
  Liability: "Liability",
  NCBR: "Nuclear, Chemical, Biological and Radiological (NCBR)",
  AlternativeAccommodation: "Alternative accommodation",
  ActiveAssailant: "Active assailant",
  NonDamageBI: "Non Damage Business Interruption",
  DenialOfAccess: "Denial of access",
  LossOfAttraction: "Loss of attraction",
  ExcessEmployersLiability: "Excess Employers’ Liability",
};

const TableRow = ({
  checkPermissions,
  handleSublimitChange,
  isSpecialScenario,
  mainSublimits,
  selectedCovers,
  sublimitRates,
  sublimitType,
  sublimitTypeIndex,
}) => {
  const getLabelText = (mainSublimitValue, coverAmount) => {
    const isCoverAmountZero = coverAmount === 0;
    const isCoverAmountNotAvailable = coverAmount === undefined;
    const coverCurrency = coverAmount && currencyFormatter(coverAmount);

    if (!checkPermissions(ACTIONS.VIEW_SUBLIMITS)) {
      return "--";
    }

    if (isCoverAmountNotAvailable) {
      return "n/a";
    }

    if (isCoverAmountZero) {
      return "Included";
    }

    return coverCurrency;
  };

  return (
    <Table.Row
      className={clsx("border border-gray-500", sublimitTypeIndex % 2 !== 0 && "bg-gray-100")}
      key={sublimitType}
    >
      <Table.Data className="border border-gray-500 w-84">
        {process.env.ENVIRONMENT === "uat" && "--"}
        {process.env.ENVIRONMENT !== "uat" && coversMap[sublimitType]}
      </Table.Data>

      {mainSublimits.map((mainSublimitAmount) => {
        const parsedMainSublimitAmount = parseInt(mainSublimitAmount, 10);
        const foundCover = selectedCovers.find((item) => item.type === sublimitType);

        const isChecked = get(foundCover, "limit.amount") === parsedMainSublimitAmount;

        const coverAmount = get(sublimitRates, [parsedMainSublimitAmount, sublimitType]);
        const hasCoverAmount = coverAmount !== undefined;
        const labelText = getLabelText(parsedMainSublimitAmount, coverAmount);

        return (
          <Table.Data
            className={clsx(
              "transition-colors duration-75 ease-in border border-gray-500 w-48",
              isChecked && !isSpecialScenario && "bg-blue-100"
            )}
            key={parsedMainSublimitAmount}
          >
            {checkPermissions(ACTIONS.EDIT_SUBLIMITS) && !isSpecialScenario && hasCoverAmount && (
              <InputRadio
                isChecked={isChecked}
                labelText={labelText}
                name={sublimitType}
                onChange={() => handleSublimitChange([parsedMainSublimitAmount, sublimitType])}
              />
            )}

            {(!checkPermissions(ACTIONS.EDIT_SUBLIMITS) || isSpecialScenario || !hasCoverAmount) &&
              labelText}
          </Table.Data>
        );
      })}
    </Table.Row>
  );
};

TableRow.propTypes = {
  checkPermissions: PropTypes.func.isRequired,
  handleSublimitChange: PropTypes.func.isRequired,
  isSpecialScenario: PropTypes.bool.isRequired,
  mainSublimits: PropTypes.array.isRequired,
  selectedCovers: PropTypes.array.isRequired,
  sublimitRates: PropTypes.object.isRequired,
  sublimitType: PropTypes.string.isRequired,
  sublimitTypeIndex: PropTypes.number.isRequired,
};

export default TableRow;
