import clsx from "clsx";
import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import Accordion from "../../components/Accordion";
import CustomTag from "../../components/CustomTag";
import Table from "../../components/Table";
import { ACTIONS } from "../../constants";
import { currencyFormatter } from "../../formatters";
import { checkUpdated, prepareData } from "../../helpers";
import { useAuth } from "../../hooks";

const dataPoints = {
  coverage: {
    formatter: currencyFormatter,
    path: [
      "/product/sublimits/:pdbi/:index/limit/amount",
      "/product/sublimits/:pdbi/:index/limit/currency",
    ],
  },
  premium: {
    formatter: currencyFormatter,
    path: [
      "/rates/sublimitRates/:pdbi/:index/rate/amount",
      "/rates/sublimitRates/:pdbi/:index/rate/currency",
    ],
    permissions: ACTIONS.VIEW_SUBLIMITS_PREMIUM_BREAKDOWN,
    obfuscate: ({ contractData }) => Boolean(get(contractData, "commercial")),
  },
};

const sublimitLabelsMap = {
  Threat: "Threat",
  Liability: "Liability",
  NCBR: "Nuclear, Chemical, Biological and Radiological (NCBR)",
  AlternativeAccommodation: "Alternative accommodation",
  ActiveAssailant: "Active assailant",
  NonDamageBI: "Non Damage Business Interruption",
  DenialOfAccess: "Denial of access",
  LossOfAttraction: "Loss of attraction",
  ExcessEmployersLiability: "Excess Employers’ Liability",
};

const Sublimits = ({ contractData, patchData, hasCommercialPrice, showDiff, ...props }) => {
  const { checkPermissions } = useAuth(contractData);
  const existingCovers = get(contractData, "product.sublimits", []);
  const someUpdated = Object.keys(existingCovers).reduce((prev, next) => {
    return existingCovers[next].some((item, index) =>
      checkUpdated(dataPoints, contractData, patchData, { index, pdbi: next })
    );
  }, false);

  return (
    <Accordion.Panel
      headingText="Sublimits"
      {...props}
      tag={showDiff && someUpdated && <CustomTag kind="updated" />}
    >
      <div className="my-6">
        <Table>
          <Table.Head className="border-b border-gray-500">
            <Table.Header className="w-1/2">Sublimits</Table.Header>
            <Table.Header className="w-1/4">Coverage</Table.Header>
            <Table.Header className="w-1/4">Premium</Table.Header>
          </Table.Head>
          <Table.Body>
            {Object.keys(existingCovers).map((key) => {
              return existingCovers[key].map((cover, index) => {
                const data = prepareData({
                  checkPermissions,
                  contractData,
                  dataPoints,
                  params: { index, pdbi: key },
                  patchData,
                  showDiff,
                });

                return (
                  <Table.Row
                    key={cover.type}
                    className={clsx("border-b border-gray-500", index % 2 !== 0 && "bg-gray-100")}
                  >
                    <Table.Data>{sublimitLabelsMap[cover.type]}</Table.Data>
                    <Table.Data {...data.coverage} />
                    <Table.Data {...data.premium} />
                  </Table.Row>
                );
              });
            })}
          </Table.Body>
        </Table>
      </div>
    </Accordion.Panel>
  );
};

Sublimits.propTypes = {
  contractData: PropTypes.object.isRequired,
  hasCommercialPrice: PropTypes.bool,
  patchData: PropTypes.array,
  showDiff: PropTypes.bool,
};

Sublimits.defaultProps = {
  hasCommercialPrice: undefined,
  patchData: [],
  showDiff: undefined,
};

export default Sublimits;
