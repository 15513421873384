import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";

const SanctionsCheckFail = ({ handleClose, handleConfirm }) => (
  <Modal handleClose={handleClose} headingText="Sanctions check - failed">
    <div className="p-6 w-160">
      <p className="mb-6">
        The insured has failed the sanctions check, you cannot continue with this submission.
      </p>

      <div className="flex">
        <Button onClick={handleConfirm} kind="danger-secondary">
          Cancel submission
        </Button>
      </div>
    </div>
  </Modal>
);

SanctionsCheckFail.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default SanctionsCheckFail;
