import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import * as yup from "yup";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Form from "../../components/Form";
import InputSelect from "../../components/InputSelect";
import { QUOTE_STATUSES } from "../../constants";
import { currencyFormatter } from "../../formatters";
import { useAuth, useQuoteQuery } from "../../hooks";

const formSchema = yup.object().shape({
  deductible: yup.number().required(),
});

const NewPrice = ({ deductiblesData, contractData, quotesData }) => {
  const { tenants } = useAuth(contractData);
  const [formValues, setFormValues] = useState({ deductible: undefined });
  const [isNewPriceFormVisible, setIsNewPriceFormVisible] = useState();
  const checkNonArchived = (quoteData) => quoteData.status !== QUOTE_STATUSES.ARCHIVED;
  const totalNonArchivedQuotesCount = quotesData.filter(checkNonArchived).length;
  const { handleSubmitQuote, isSubmittingQuote } = useQuoteQuery();

  const isValid = formSchema.isValidSync(formValues);

  const handleSubmit = async () => {
    const tenant = tenants.find((item) => item.id === contractData.tenantId);
    const product = get(tenant, "products[0]");

    await handleSubmitQuote({
      commissionPercentage: Number(product.commission),
      deductible: { amount: formValues.deductible, currency: "GBP" },
    });

    setIsNewPriceFormVisible(false);
  };

  return (
    <>
      {totalNonArchivedQuotesCount < 3 && (
        <Button className="mb-4" onClick={() => setIsNewPriceFormVisible(true)}>
          New price
        </Button>
      )}

      {isNewPriceFormVisible && (
        <Card
          className="mb-8"
          headingText="Price"
          descriptionText="Select an alternative deductible in order to generate a new price. "
        >
          <div className="p-4">
            <Form hasContext onSubmit={handleSubmit}>
              <InputSelect
                className="w-64 mr-3 mb-2"
                formatter={(value) => currencyFormatter({ amount: value, currency: "GBP" })}
                isRequired
                items={deductiblesData}
                name="deductible"
                onSelect={(value) => setFormValues({ ...formValues, deductible: value })}
              />

              <div className="mb-6">
                <p className="text-gray-800 text-sm">
                  Please note: you may only generate up to three prices.
                </p>

                {totalNonArchivedQuotesCount === 2 && (
                  <p className="text-red-800 text-sm">
                    This is the last price you will be allowed to generate.
                  </p>
                )}
              </div>

              <div className="flex">
                <Button
                  className="mr-3"
                  isDisabled={isSubmittingQuote || !isValid}
                  kind="primary"
                  type="submit"
                >
                  Generate price
                </Button>

                <Button kind="secondary" onClick={() => setIsNewPriceFormVisible(false)}>
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </Card>
      )}
    </>
  );
};

NewPrice.propTypes = {
  deductiblesData: PropTypes.array,
  contractData: PropTypes.object.isRequired,
  quotesData: PropTypes.array,
};

NewPrice.defaultProps = {
  deductiblesData: undefined,
  quotesData: undefined,
};

export default NewPrice;
