import clsx from "clsx";
import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import { currencyFormatter } from "../formatters";
import Input from "./Input";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const InputCurrency = ({
  className,
  currencies,
  defaultValue,
  descriptionText,
  errors,
  id,
  isReadOnly,
  labelText,
  name,
  onChange,
  value,
  ...props
}) => {
  const selectedCurrency = get(value, "currency") || get(defaultValue, "currency") || currencies[0];
  const hasValue = get(value, "amount") && get(value, "currency");

  const handleChange = ({ floatValue }) => {
    if (onChange) onChange({ amount: floatValue, currency: selectedCurrency }, name);
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      labelText={labelText}
      name={name}
      {...props}
    >
      {!isReadOnly && (
        <div className="relative">
          <NumberFormat
            allowNegative={false}
            aria-describedby={descriptionText ? `${name}Description` : undefined}
            className="aui-input aui-input-currency"
            customInput={Input}
            decimalScale={2}
            defaultValue={defaultValue && defaultValue.amount}
            errors={errors}
            fixedDecimalScale
            id={name}
            isNumericString
            maxLength="22"
            name={name}
            onValueChange={handleChange}
            thousandSeparator
            value={value && value.amount}
            {...props}
          />
          <div
            className={clsx(
              "absolute inset-y-0 right-0 bg-gray-100 border-l flex items-center justify-center px-3 my-px mr-px w-16 rounded-r",
              !errors && "border-gray-700",
              errors && "border-red-900"
            )}
          >
            {selectedCurrency}
          </div>
        </div>
      )}

      {isReadOnly && (
        <InputReadOnly {...props} defaultValue={hasValue && currencyFormatter(value)} />
      )}
    </InputField>
  );
};

InputCurrency.propTypes = {
  className: PropTypes.string,
  currencies: PropTypes.array,
  defaultValue: PropTypes.object,
  descriptionText: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

InputCurrency.defaultProps = {
  className: undefined,
  currencies: undefined,
  defaultValue: undefined,
  descriptionText: undefined,
  errors: undefined,
  id: undefined,
  isReadOnly: undefined,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
  value: undefined,
};

export default InputCurrency;
