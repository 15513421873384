import * as yup from "yup";

const today = new Date();

export const insuredSchema = yup.object().shape({
  type: yup.mixed().oneOf(["Company", "Individual"]).required(),
  id: yup.string().required(),
});

export const searchCompanySchema = yup.object().shape({
  companyName: yup.string().trim().min(3).required(),
});

export const searchIndividualSchema = yup.object().shape({
  firstName: yup.string().ensure().trim().required(),
  lastName: yup.string().ensure().trim().required(),
  day: yup.string().ensure().trim().required(),
  month: yup.string().ensure().trim().required(),
  year: yup.string().ensure().trim().length(4).required(),
});

export const createIndividualSchema = yup.object().shape({
  firstName: yup.string().ensure().trim().required(),
  lastName: yup.string().ensure().trim().required(),
  day: yup.string().ensure().trim().required(),
  month: yup.string().ensure().trim().required(),
  year: yup.string().ensure().trim().length(4).required(),
  dob: yup.date().max(today).required(),
  addressLine1: yup.string().ensure().trim().required(),
  addressLine2: yup.string().ensure().trim(),
  city: yup.string().ensure().trim().required(),
  postCode: yup.string().ensure().trim().required(),
  county: yup.string().ensure().trim().required(),
  country: yup.string().ensure().trim().default("United Kingdom").required(),
});
