import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../../api";
import Button from "../../components/Button";
import Card from "../../components/Card";
import CustomTag from "../../components/CustomTag";
import Dropdown from "../../components/Dropdown";
import Loader from "../../components/Loader";
import StatusTag from "../../components/StatusTag";
import Table from "../../components/Table";
import TableDataPoint from "../../components/TableDataPoint";
import { ACTIONS, QUOTE_STATUSES } from "../../constants";
import { checkUpdated, downloadDocument, prepareData } from "../../helpers";
import { useAuth } from "../../hooks";
import { priceDataPointsMap } from "./config";

const Price = ({
  contractData,
  handleAction,
  index,
  isContractCurrent,
  patchData,
  quoteData,
  showDiff,
}) => {
  const { contractId } = useParams();
  const { checkPermissions } = useAuth(contractData);
  const quoteId = quoteData.id;
  const isPriceSubmitted = quoteData.status === QUOTE_STATUSES.SUBMITTED;
  const isUpdated = checkUpdated(priceDataPointsMap, contractData, patchData, { index });

  const quoteLinkQuery = useQuery(
    ["quoteLink", { contractId, priceId: quoteId }],
    api.getQuoteLink,
    { retry: 12, enabled: checkPermissions(ACTIONS.VIEW_QUOTE_DOCUMENT) && isContractCurrent }
  );

  const hasData = get(quoteLinkQuery, "data.data.url");
  const isDisabled = !hasData || quoteLinkQuery.isLoading;

  const onSelect = async (item) => {
    if (item.value === ACTIONS.VIEW_QUOTE_DOCUMENT) {
      const { url, fileName } = get(quoteLinkQuery, "data.data", {});
      return downloadDocument(url, fileName);
    }

    return handleAction({ type: item.value, quoteId, quoteData });
  };

  const data = prepareData({
    checkPermissions,
    contractData,
    dataPoints: priceDataPointsMap,
    params: { index },
    patchData,
    showDiff,
  });

  return (
    <Card
      key={quoteData.ref}
      className="mb-8"
      headingText={`Price - ${quoteData.ref}`}
      tag={
        <div className="flex">
          <StatusTag status={quoteData.status} className="mr-4" />
          {showDiff && isUpdated && <CustomTag kind="updated" />}
        </div>
      }
    >
      <Table>
        <Table.Body>
          <TableDataPoint {...data.deductible} />
          <TableDataPoint {...data.gross} isStriped />
          <TableDataPoint {...data.commission} />
          <TableDataPoint {...data.ipt} isStriped />
          <TableDataPoint {...data.total} />
        </Table.Body>
      </Table>

      {isContractCurrent && isPriceSubmitted && checkPermissions(ACTIONS.BIND_PRICE) && (
        <div className="flex p-4">
          <Button
            name="Bind"
            kind="primary"
            onClick={() => handleAction({ type: ACTIONS.BIND_PRICE, quoteId })}
            className="mr-4"
          >
            Bind
          </Button>

          <Dropdown
            name="Actions"
            labelText="Actions"
            items={[
              {
                label: "Reject price",
                value: ACTIONS.REJECT_PRICE,
              },
              {
                label: "Change commission",
                value: ACTIONS.CHANGE_COMMISSION,
              },
              {
                disabled: isDisabled,
                label: (
                  <div className="flex items-center">
                    {isDisabled && <Loader size="small" className="mr-2" />}
                    <span>View quote document</span>
                  </div>
                ),
                value: ACTIONS.VIEW_QUOTE_DOCUMENT,
              },
            ]}
            onSelect={onSelect}
          />
        </div>
      )}

      {isContractCurrent && isPriceSubmitted && checkPermissions(ACTIONS.ACCEPT_TECHNICAL_PRICE) && (
        <div className="p-4">
          <Button
            kind="primary"
            onClick={() => handleAction({ type: ACTIONS.ACCEPT_TECHNICAL_PRICE, quoteId })}
          >
            Accept technical price
          </Button>
        </div>
      )}
    </Card>
  );
};

Price.propTypes = {
  contractData: PropTypes.object.isRequired,
  quoteData: PropTypes.object.isRequired,
  handleAction: PropTypes.func.isRequired,
  isContractCurrent: PropTypes.bool,
  patchData: PropTypes.array,
  predicate: PropTypes.func,
  showDiff: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

Price.defaultProps = {
  isContractCurrent: undefined,
  patchData: [],
  predicate: undefined,
  showDiff: undefined,
};

export default Price;
