import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import DescriptionText from "./DescriptionText";
import ErrorText from "./ErrorText";
import Icon from "./Icon";
import Label from "./Label";

const sizes = {
  normal: "h-12",
  small: "h-10",
};

const InputField = ({
  children,
  className,
  descriptionText,
  errors,
  icon,
  iconName,
  iconPosition,
  id,
  isRequired,
  labelText,
  link,
  name,
  size,
}) => {
  const errorsArray = Array.isArray(errors) ? errors : [errors];

  return (
    <div className={clsx("relative", className)}>
      {labelText && (
        <div className="mb-2 flex justify-between">
          <Label htmlFor={id || name} isRequired={isRequired}>
            {labelText}
          </Label>
          {link}
        </div>
      )}

      <div className="relative">
        {icon}

        {iconName && (
          <div
            className={clsx(
              "flex w-10 z-10 p-3 items-center justify-center absolute pointer-events-none",
              iconPosition === "right" && "right-0",
              iconPosition === "left" && "left-0",
              sizes[size]
            )}
          >
            <Icon name={iconName} className="w-3 fill-current text-gray-800" />
          </div>
        )}

        {children}
      </div>

      {descriptionText && !errors && (
        <DescriptionText id={`${name}Description`}>{descriptionText}</DescriptionText>
      )}

      {errors && errorsArray.map((errorText) => <ErrorText key={errorText}>{errorText}</ErrorText>)}
    </div>
  );
};

InputField.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  descriptionText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.node]),
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  icon: PropTypes.func,
  iconName: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  id: PropTypes.string,
  isRequired: PropTypes.bool,
  labelText: PropTypes.string,
  link: PropTypes.node,
  name: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal"]),
};

InputField.defaultProps = {
  children: undefined,
  className: undefined,
  descriptionText: undefined,
  errors: undefined,
  icon: undefined,
  iconName: undefined,
  iconPosition: "right",
  id: undefined,
  isRequired: undefined,
  labelText: undefined,
  link: undefined,
  name: undefined,
  size: "normal",
};

export default InputField;
