import { ROLES, ROUTES, STATUS_TEXT_MAP } from "../../constants";
import { dateFormatter, fullNameFormatter, insuredFormatter } from "../../formatters";

export const statusColorMap = {
  Bound: "text-lime-900 bg-lime-100",
  Cancelled: "text-red-900 bg-red-100",
  DeclineToQuote: "text-red-900 bg-red-100",
  Expired: "text-yellow-900 bg-yellow-100",
  Inforce: "text-green-900 bg-green-100",
  Lapsed: "text-pink-900 bg-pink-100",
  NotTakenUp: "text-pink-900 bg-pink-100",
  Quoted: "text-green-900 bg-green-100",
  QuotedReferred: "text-green-900 bg-green-100",
  QuoteExpired: "text-yellow-900 bg-yellow-100",
  Referred: "text-orange-900 bg-orange-100",
  Submitted: "text-blue-900 bg-blue-100",
};

export const headingsMap = {
  [ROUTES.POLICIES_INDEX]: "Policies",
  [ROUTES.QUOTES_INDEX]: "Quotes",
  [ROUTES.REFERRALS_INDEX]: "Referrals",
};

export const filtersMap = {
  [ROUTES.POLICIES_INDEX]: "Bound,Lapsed,Inforce,Cancelled",
  [ROUTES.QUOTES_INDEX]:
    "DeclineToQuote,NotTakenUp,Quoted,QuotedReferred,Referred,Submitted,Expired",
  [ROUTES.REFERRALS_INDEX]: "Referred",
};

export const emptyMap = {
  [ROUTES.QUOTES_INDEX]: {
    [ROLES.BROKER]: {
      descriptionText: "Create a new quote to get started.",
      headingText: "No quotes to show",
    },
    [ROLES.UNDERWRITER]: {
      descriptionText: "Once a quote has been created, it will show here.",
      headingText: "No quotes to show",
    },
    [ROLES.READ_ONLY]: {
      descriptionText: "Once a quote has been created, it will show here.",
      headingText: "No quotes to show",
    },
  },
  [ROUTES.POLICIES_INDEX]: {
    [ROLES.BROKER]: {
      descriptionText: "There are currently no policies within the system.",
      headingText: "No policies to show",
    },
    [ROLES.UNDERWRITER]: {
      descriptionText: "Once a quote has been bound, it will show here.",
      headingText: "No policies to show",
    },
    [ROLES.READ_ONLY]: {
      descriptionText: "Once a quote has been bound, it will show here.",
      headingText: "No policies to show",
    },
  },
  [ROUTES.REFERRALS_INDEX]: {
    [ROLES.BROKER]: {
      descriptionText: "There are currently no policies within the system.",
      headingText: "No referrals to show",
    },
    [ROLES.UNDERWRITER]: {
      descriptionText: "There have been no referred submissions yet.",
      headingText: "No referrals to show",
    },
    [ROLES.READ_ONLY]: {
      descriptionText: "There have been no referred submissions yet.",
      headingText: "No referrals to show",
    },
  },
};

export const columns = [
  {
    className: "w-48",
    displayName: "Reference",
    path: "ref",
  },
  {
    className: "truncate max-w-3xs",
    displayName: "Insured",
    formatter: insuredFormatter,
    path: "insured",
  },
  {
    defaultValue: "Brokerage not found.",
    displayName: "Broker Name",
    path: "tenant.name",
  },
  {
    defaultValue: "User not found.",
    displayName: "Broker Contact",
    formatter: fullNameFormatter,
    path: "contact",
  },
  {
    className: "w-32",
    displayName: "Submitted",
    formatter: dateFormatter,
    path: "createdAt",
  },
  {
    className: "w-48",
    displayName: "Status",
    formatter: (value) => STATUS_TEXT_MAP[value] || value,
    getDataClassName: (value) => statusColorMap[value],
    path: "status",
  },
];
