import PropTypes from "prop-types";
import React from "react";
import Tag from "./Tag";

const customTags = {
  updated: { text: "Updated", color: "yellow", icon: "info" },
};

const CustomTag = ({ kind, className }) => <Tag className={className} {...customTags[kind]} />;

CustomTag.propTypes = {
  className: PropTypes.string,
  kind: PropTypes.oneOf(["updated"]).isRequired,
};

CustomTag.defaultProps = {
  className: undefined,
};

export default CustomTag;
