import PropTypes from "prop-types";
import React from "react";
import * as yup from "yup";
import Button from "../components/Button";
import Datapoint from "../components/Datapoint";
import Form from "../components/Form";
import InputPercentage from "../components/InputPercentage";
import Modal from "../components/Modal";
import Table from "../components/Table";
import TableDataPoint from "../components/TableDataPoint";
import { currencyFormatter } from "../formatters";
import { prepareData } from "../helpers";
import { useAuth, useForm, useQuoteQuery } from "../hooks";
import { priceDataPointsMap } from "../modules/ContractDetails/config";

const ChangeCommissionModal = ({ handleClose, contractData, quoteData, quoteIndex }) => {
  const { checkPermissions, tenant } = useAuth(contractData);
  const { handleSubmitQuote, isSubmittingQuote } = useQuoteQuery();

  const handleSubmit = async (formValues) => {
    await handleSubmitQuote({ commissionPercentage: formValues.commissionPercentage }, quoteData);

    handleClose();
  };

  const { getFieldProps, canSubmit, onSubmit, formValues } = useForm({
    initialState: { commissionPercentage: quoteData.commissionPercentage },
    onSubmit: handleSubmit,
    validationOptions: { context: { commission: tenant.commission } },
    validationSchema: yup.object().shape({
      commissionPercentage: yup.number().min(0).max(yup.ref("$commission")).required(),
    }),
  });

  const commissionAmount = quoteData.rates.commission.amount;
  const discountedPremium = commissionAmount * (100 / quoteData.commissionPercentage);
  const newCommissionAmount = discountedPremium * (formValues.commissionPercentage / 100);

  const data = prepareData({
    checkPermissions,
    contractData,
    dataPoints: priceDataPointsMap,
    params: { index: quoteIndex },
  });

  return (
    <Modal handleClose={handleClose} headingText="Change commission">
      <div className="p-6 w-160">
        <Form onSubmit={onSubmit}>
          <div className="flex mb-4">
            <InputPercentage
              className="w-3/5 mr-10"
              descriptionText={`Enter a commission amount between 0-${tenant.commission}%`}
              isAllowed={({ floatValue, value }) => value === "" || floatValue <= tenant.commission}
              isRequired
              labelText="Comission percentage"
              {...getFieldProps("commissionPercentage")}
            />

            <Datapoint
              className="pt-8 w-2/5 self-start"
              contentClassName="text-lg"
              labelText="New commission amount"
            >
              {currencyFormatter({ amount: newCommissionAmount || 0, currency: "GBP" })}
            </Datapoint>
          </div>

          <Table className="mb-4">
            <Table.Body>
              <TableDataPoint {...data.deductible} />
              <TableDataPoint {...data.gross} isStriped />
              <TableDataPoint {...data.commission} />
              <TableDataPoint {...data.ipt} isStriped />
              <TableDataPoint {...data.total} />
            </Table.Body>
          </Table>

          <div className="flex">
            <Button
              className="mr-4"
              isDisabled={!canSubmit || isSubmittingQuote}
              kind="primary"
              type="submit"
            >
              Update premium
            </Button>

            <Button kind="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

ChangeCommissionModal.propTypes = {
  contractData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  quoteData: PropTypes.object.isRequired,
  quoteIndex: PropTypes.number.isRequired,
};

export default ChangeCommissionModal;
