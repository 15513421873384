import PropTypes from "prop-types";
import React from "react";
import { ReactQueryConfigProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { FormContextProvider } from "../context/Form";
import PermissionsContext from "../context/Permissions";

const Providers = ({ children }) => (
  <ReactQueryConfigProvider config={{ queries: { refetchOnWindowFocus: false } }}>
    <BrowserRouter>
      <FormContextProvider>
        <PermissionsContext.Provider value={process.env.permissions}>
          {children}
        </PermissionsContext.Provider>
      </FormContextProvider>
    </BrowserRouter>
  </ReactQueryConfigProvider>
);

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Providers;
