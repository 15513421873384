import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Icon from "./Icon";

const StepSmall = ({ children, isUnlocked, index, isActive }) => {
  const isLocked = !isActive && !isUnlocked;

  return (
    <div className="flex items-center mx-6">
      {isActive && (
        <div className="rounded-full h-8 w-8 mr-3 bg-blue-600 text-white flex items-center justify-center">
          {index + 1}
        </div>
      )}

      {isUnlocked && !isActive && <Icon name="circle-check" className="mr-3" />}

      {isLocked && (
        <div className="rounded-full h-8 w-8 mr-3 bg-gray-200 text-gray-800 flex items-center justify-center">
          {index + 1}
        </div>
      )}

      {children}
    </div>
  );
};

const Step = ({ to, children, className, isUnlocked, index, isActive }) => {
  const isLocked = !isActive && !isUnlocked;
  const Component = isUnlocked ? Link : "div";

  return (
    <Component
      tabIndex={isUnlocked ? 0 : undefined}
      to={isUnlocked ? to : undefined}
      className={clsx(
        "pl-3 pb-6 pt-6 flex-1 w-full max-w-64 border-b-4 border-transparent",
        isActive && "border-blue-600",
        className
      )}
    >
      <div className="flex items-center">
        {isActive && <Icon name="circle-edit" className="mr-3" />}

        {isUnlocked && !isActive && <Icon name="circle-check" className="mr-3" />}

        {isLocked && <Icon name="circle-lock" className="mr-3" />}

        <div className={clsx(isLocked ? "text-gray-700" : "text-gray-1000")}>
          <div className="text-sm">{`Step ${index + 1}`}</div>

          <div className="font-semibold">{children}</div>
        </div>
      </div>
    </Component>
  );
};

const Stepper = ({
  className,
  children,
  activeIndex,
  disableCompleted,
  tabMode,
  showSeparators,
}) => (
  <div className={clsx("flex items-center", className)}>
    {React.Children.map(children, (child, index) => {
      const isLast = React.Children.toArray(children).length - 1 === index;

      return (
        <>
          {React.cloneElement(child, {
            index,
            isActive: index === activeIndex,
            isUnlocked: tabMode || (!disableCompleted && index < activeIndex),
          })}

          {showSeparators && !isLast && <div className="h-px bg-gray-500 flex-1" />}
        </>
      );
    })}
  </div>
);

StepSmall.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  isUnlocked: PropTypes.bool,
};

StepSmall.defaultProps = {
  children: undefined,
  index: undefined,
  isActive: undefined,
  isUnlocked: undefined,
};

Step.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  index: PropTypes.number,
  isActive: PropTypes.bool,
  isUnlocked: PropTypes.bool,
  to: PropTypes.string,
};

Step.defaultProps = {
  children: undefined,
  className: undefined,
  index: undefined,
  isActive: undefined,
  isUnlocked: undefined,
  to: undefined,
};

Stepper.propTypes = {
  activeIndex: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  disableCompleted: PropTypes.bool,
  showSeparators: PropTypes.bool,
  tabMode: PropTypes.bool,
};

Stepper.defaultProps = {
  activeIndex: undefined,
  children: undefined,
  className: undefined,
  disableCompleted: undefined,
  showSeparators: undefined,
  tabMode: undefined,
};

Stepper.Step = Step;
Stepper.StepSmall = StepSmall;

export default Stepper;
