import get from "lodash.get";
import sortBy from "lodash.sortby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../../api";
import Accordion from "../../components/Accordion";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { STATUSES } from "../../constants";
import { downloadDocument } from "../../helpers";

const columns = [
  { path: "fileName", displayName: "Document name", className: "truncate max-w-3xs" },
  { path: "documentSize", displayName: "Size" },
  { path: "download", displayName: "Action", className: "w-40" },
];

const PolicyDocuments = ({ contractData, ...props }) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState();
  const { contractId } = useParams();

  const { status } = contractData;
  const isCancelled = status === STATUSES.CANCELLED;
  const numDocs = isCancelled ? 4 : 3;

  const policyDocumentsQuery = useQuery(
    ["policyDocuments", { contractId, numDocs }],
    api.getPolicyDocuments,
    { retry: 16 }
  );
  const policyDocumentsData = get(policyDocumentsQuery, "data.data.documents", []);
  const hasData = policyDocumentsData.length > 0;

  const [getContractAttachmentLink, getContractAttachmentLinkQuery] = useMutation(
    api.getContractAttachmentLink
  );

  const handleDownloadClick = async (row) => {
    setSelectedDocumentId(row.documentId);

    const res = await getContractAttachmentLink({ contractId, attachmentId: row.documentId });

    downloadDocument(res.data.url, res.data.fileName);
  };

  const renderData = ({ row, column, value }) => {
    if (column.displayName === "Action") {
      return (
        <Button
          className="-my-2"
          isDisabled={
            row.documentId === selectedDocumentId && getContractAttachmentLinkQuery.isLoading
          }
          kind="secondary"
          onClick={() => handleDownloadClick(row)}
          size="small"
        >
          Download
        </Button>
      );
    }

    return value;
  };

  return (
    <Accordion.Panel headingText="Policy documents" {...props}>
      <div className="my-6">
        {policyDocumentsQuery.isLoading && <Loader className="mx-auto" />}

        {!policyDocumentsQuery.isLoading && hasData && (
          <Table
            rows={sortBy(policyDocumentsData, ["fileName"])}
            columns={columns}
            renderData={renderData}
            isStriped
          />
        )}

        {!policyDocumentsQuery.isLoading && !hasData && (
          <p className="text-center my-2">No policy documents were found…</p>
        )}
      </div>
    </Accordion.Panel>
  );
};

PolicyDocuments.propTypes = {
  contractData: PropTypes.object.isRequired,
};

export default PolicyDocuments;
