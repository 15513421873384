import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import Icon from "./Icon";

const styles = {
  blue: "bg-blue-100 text-blue-1000",
  grape: "bg-grape-100 text-grape-1000",
  green: "bg-green-100 text-green-1000",
  indigo: "bg-indigo-100 text-indigo-1000",
  orange: "bg-orange-100 text-orange-1000",
  pink: "bg-pink-100 text-pink-1000",
  red: "bg-red-200 text-red-900",
  teal: "bg-teal-100 text-teal-1000",
  violet: "bg-violet-100 text-violet-1000",
  yellow: "bg-yellow-100 text-yellow-1000",
};

const Tag = ({ className, children, color, text, icon }) => (
  <div
    className={clsx(
      "text-xs h-6 px-2 rounded flex justify-center items-center",
      styles[color],
      className
    )}
  >
    {icon && <Icon name={icon} className="w-3 mr-2" />}
    {children}
    {text}
  </div>
);

Tag.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    "blue",
    "grape",
    "green",
    "indigo",
    "orange",
    "pink",
    "red",
    "teal",
    "violet",
    "yellow",
  ]),
  icon: PropTypes.string,
  text: PropTypes.string,
};

Tag.defaultProps = {
  children: undefined,
  className: undefined,
  color: "blue",
  icon: undefined,
  text: undefined,
};

export default Tag;
