import { ACTIONS } from "../../constants";
import { currencyFormatter, postCodeFormatter } from "../../formatters";
import { commaSeparate } from "../../helpers";

export const columns = [
  {
    className: "w-32",
    displayName: "Postal code",
    formatter: postCodeFormatter,
    id: "postCode",
    isRequired: true,
    path: "address.postCode",
  },
  {
    className: "truncate max-w-64",
    displayName: "Address",
    formatter: commaSeparate,
    id: "address",
    isRequired: true,
    paths: ["address.line1", "address.line2", "address.city"],
  },
  {
    className: "w-56",
    displayName: "Property Damage Value",
    formatter: currencyFormatter,
    isRequired: true,
    path: "propertyDamage",
  },
  {
    className: "w-64",
    formatter: currencyFormatter,
    path: "businessInterruption",
  },
  {
    className: "w-56",
    displayName: "Technical price",
    formatter: currencyFormatter,
    path: "rates",
    permissions: ACTIONS.VIEW_TECHNICAL_PRICE_COLUMN,
  },
  {
    className: "max-w-3xs truncate",
    displayName: "Location type",
    isRequired: true,
    path: "locationType",
  },
  {
    className: "w-64",
    displayName: "Action",
    id: "action",
  },
];

export default columns;
