import clsx from "clsx";
import Downshift from "downshift";
import PropTypes from "prop-types";
import React from "react";
import Icon from "./Icon";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const sizes = {
  normal: "h-12",
  small: "h-10",
};

const margins = {
  normal: "mt-12",
  small: "mt-10",
};

const InputSelect = ({
  className,
  descriptionText,
  formatter,
  id,
  initialSelectedItem,
  isReadOnly,
  items,
  labelText,
  name,
  onChange,
  onSelect,
  size,
  ...props
}) => {
  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      labelText={labelText}
      name={name}
      {...props}
    >
      {isReadOnly && (
        <InputReadOnly
          {...props}
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={
            formatter && initialSelectedItem ? formatter(initialSelectedItem) : initialSelectedItem
          }
        />
      )}

      {!isReadOnly && (
        <Downshift
          initialSelectedItem={initialSelectedItem}
          itemToString={(item) => (item ? item.value || item : "")}
          onSelect={(item) => {
            if (onSelect) onSelect(item, name);
            if (onChange) onChange(item, name);
          }}
        >
          {({
            getItemProps,
            getMenuProps,
            getToggleButtonProps,
            highlightedIndex,
            isOpen,
            selectedItem,
          }) => (
            <div className="relative">
              <button
                {...getToggleButtonProps()}
                aria-label={labelText}
                aria-labelledby={props["aria-labelledby"]}
                type="button"
                className={clsx("aui-input aui-input-select", sizes[size])}
              >
                {formatter && selectedItem
                  ? formatter(selectedItem)
                  : selectedItem || <span className="text-gray-700">Select</span>}
                {!isOpen && (
                  <Icon name="chevron-down" className="ml-2 fill-current text-gray-800" />
                )}
                {isOpen && <Icon name="chevron-up" className="ml-2 fill-current text-blue-700" />}
              </button>

              {isOpen && (
                <div className={clsx("absolute inset-x-0 top-0 z-10", margins[size])}>
                  <ul {...getMenuProps()} className="aui-input aui-menu">
                    {items.map((option, index) => (
                      <li
                        key={option}
                        className={clsx(
                          "flex items-center px-3 py-2 cursor-pointer",
                          index === highlightedIndex && "bg-gray-300"
                        )}
                        {...getItemProps({ item: option, index })}
                      >
                        {formatter ? formatter(option) : option}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
        </Downshift>
      )}
    </InputField>
  );
};

InputSelect.propTypes = {
  "aria-labelledby": PropTypes.string,
  className: PropTypes.string,
  descriptionText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.node]),
  formatter: PropTypes.func,
  id: PropTypes.string,
  initialSelectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isReadOnly: PropTypes.bool,
  items: PropTypes.array,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  size: PropTypes.oneOf(["small", "normal"]),
};

InputSelect.defaultProps = {
  "aria-labelledby": undefined,
  className: undefined,
  descriptionText: undefined,
  formatter: undefined,
  id: undefined,
  initialSelectedItem: undefined,
  isReadOnly: undefined,
  items: undefined,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
  onSelect: undefined,
  size: "normal",
};

export default InputSelect;
