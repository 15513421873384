import React from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { ROUTES } from "../../constants";
import NavBar from "../NavBar";

const NotFound = () => (
  <>
    <NavBar />
    <div className="text-center mt-64">
      <div className="mb-6 flex justify-center">
        <Icon name="warning" className="w-12 fill-current text-gray-700" />
      </div>

      <p className="text-2xl font-medium mb-4">404: Page not found</p>

      <p className="mb-6">Sorry, that record is not available. </p>

      <Button as={Link} to={ROUTES.HOME} kind="primary">
        Go back
      </Button>
    </div>
  </>
);

export default NotFound;
