import PropTypes from "prop-types";
import React from "react";
import useFormContext from "../context/Form";

const Form = ({ onSubmit, onChange, id, hasContext, ...props }) => {
  const formContext = useFormContext();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (onSubmit) {
      onSubmit(event);
    }
  };

  const handleChange = (event) => {
    if (hasContext) {
      formContext.setValue({ isDirty: true });
    }

    if (onChange) {
      onChange(event);
    }
  };

  return <form id={id} onSubmit={handleSubmit} onChange={handleChange} {...props} />;
};

Form.propTypes = {
  hasContext: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

Form.defaultProps = {
  hasContext: undefined,
  id: undefined,
  onChange: undefined,
  onSubmit: undefined,
};

export default Form;
