import clsx from "clsx";
import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import Accordion from "../../components/Accordion";
import CustomTag from "../../components/CustomTag";
import Table from "../../components/Table";
import { addressFormatter, currencyFormatter, postCodeFormatter } from "../../formatters";
import { checkRemoved, checkUpdated, prepareData } from "../../helpers";

const dataPoints = {
  address: {
    formatter: addressFormatter(", "),
    path: [
      "/product/locationsSchedule/:index/address/line1",
      "/product/locationsSchedule/:index/address/city",
      "/product/locationsSchedule/:index/address/country",
    ],
  },
  postCode: {
    formatter: postCodeFormatter,
    path: "/product/locationsSchedule/:index/address/postCode",
  },
  propertyDamage: {
    formatter: currencyFormatter,
    path: [
      "/product/locationsSchedule/:index/propertyDamage/amount",
      "/product/locationsSchedule/:index/propertyDamage/currency",
    ],
  },
  businessInterruption: {
    formatter: currencyFormatter,
    path: [
      "/product/locationsSchedule/:index/businessInterruption/amount",
      "/product/locationsSchedule/:index/businessInterruption/currency",
    ],
  },
  locationType: {
    path: "/product/locationsSchedule/:index/locationType",
  },
};

const LocationSchedule = ({ contractData, patchData, showDiff, ...props }) => {
  const isCompany = get(contractData, "insured.Company");
  const locationsData = get(contractData, "product.locationsSchedule", []);
  const isCommercial = get(contractData, "product.coverDetails.businessType") === "Commercial";
  const someRemoved = checkRemoved({ path: "/product/locationsSchedule/:index", patchData });
  const someUpdated = locationsData.some((item, index) =>
    checkUpdated(dataPoints, contractData, patchData, { index })
  );

  const isUpdated = showDiff && (someUpdated || someRemoved);

  return (
    <Accordion.Panel
      headingText="Location schedule"
      {...props}
      tag={isUpdated && <CustomTag kind="updated" />}
    >
      <div className="my-6">
        <div className="my-6">
          <Table>
            <Table.Head className="border-b border-gray-500">
              <Table.Header className="truncate max-w-3xs">Address</Table.Header>
              <Table.Header>Postal code</Table.Header>
              <Table.Header>
                <div className="flex items-center">Property Damage Value</div>
              </Table.Header>
              {isCompany && (
                <Table.Header>
                  <div className="flex items-center">
                    {isCommercial ? "Business Interruption Value" : "Rental Income Value"}
                  </div>
                </Table.Header>
              )}
              <Table.Header>Type</Table.Header>
            </Table.Head>
            <Table.Body>
              {locationsData.map((item, index) => {
                const data = prepareData({
                  dataPoints,
                  contractData,
                  patchData,
                  params: { index },
                });

                return (
                  <Table.Row
                    key={index.toString()}
                    className={clsx("border-b border-gray-500", index % 2 !== 0 && "bg-gray-100")}
                  >
                    <Table.Data className="truncate max-w-3xs" {...data.address} />
                    <Table.Data {...data.postCode} />
                    <Table.Data {...data.propertyDamage} />
                    {isCompany && <Table.Data {...data.businessInterruption} />}
                    <Table.Data {...data.locationType} />
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </div>
    </Accordion.Panel>
  );
};

LocationSchedule.propTypes = {
  contractData: PropTypes.object.isRequired,
  patchData: PropTypes.array,
  showDiff: PropTypes.bool,
};

LocationSchedule.defaultProps = {
  patchData: [],
  showDiff: undefined,
};

export default LocationSchedule;
