import clsx from "clsx";
import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import { Route, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import H2 from "../../components/H2";
import { ACTIONS, ROUTES, STATUS_TEXT_COLOR_MAP, STATUS_TEXT_MAP } from "../../constants";
import { useAuth, useModal } from "../../hooks";
import AttachmentsModal from "../../modals/AttachmentsModal";
import NotTakenUpModal from "../../modals/NotTakenUpModal";
import NavBar from "../NavBar";

const Header = ({ headingText, attachmentsData, refData, contractData, children }) => {
  const { modal, showModal, closeModal } = useModal();
  const { contractId } = useParams();
  const contractStatus = get(contractData, "status");
  const contractStatusText = STATUS_TEXT_MAP[contractStatus] || contractStatus;
  const contractStatusTextColor = STATUS_TEXT_COLOR_MAP[contractStatus] || "text-blue-900";
  const { tenant, user, checkPermissions, contractTenant, contractUser } = useAuth(contractData);
  const tenantName = contractId ? contractTenant.name : tenant.name;
  const userName = contractId ? contractUser.fullName : user.fullName;

  const onSelect = (item) => {
    if (item.value === ACTIONS.UPDATE_TO_NTU) showModal("NotTakenUpModal");
  };

  return (
    <>
      {modal === "NotTakenUpModal" && (
        <NotTakenUpModal handleClose={closeModal} contractData={contractData} refData={refData} />
      )}

      {modal === "AttachmentsModal" && (
        <AttachmentsModal
          handleClose={closeModal}
          attachmentsData={attachmentsData}
          canUpload={checkPermissions(ACTIONS.UPLOAD_ATTACHMENTS)}
        />
      )}

      <NavBar />

      <div className="flex justify-between items-center mt-8 px-8 mb-4">
        {headingText && <H2>{headingText}</H2>}

        <Route path={[ROUTES.CONTRACT_STEPPER, ROUTES.CONTRACT]}>
          <div className="flex">
            <div className="text-right">
              <p className="text-sm text-gray-900 font-semibold mb-2">{tenantName}</p>
              {userName && <p className="text-sm text-gray-800">{userName}</p>}
            </div>

            {contractId && checkPermissions(ACTIONS.UPDATE_TO_NTU) && (
              <Dropdown
                className={clsx("ml-4", contractStatusTextColor)}
                items={[{ value: ACTIONS.UPDATE_TO_NTU, label: "Not taken up" }]}
                labelText={contractStatusText}
                onSelect={onSelect}
              />
            )}

            {contractId && !checkPermissions(ACTIONS.UPDATE_TO_NTU) && (
              <Button className="ml-4" kind="secondary">
                <span className={contractStatusTextColor}>{contractStatusText}</span>
              </Button>
            )}

            {contractId && checkPermissions(ACTIONS.VIEW_ATTACHMENTS) && (
              <Button
                className="ml-4"
                iconName="paperclip"
                kind="secondary"
                onClick={() => showModal("AttachmentsModal")}
              >
                Attachments ({attachmentsData.length})
              </Button>
            )}
          </div>
        </Route>

        {children}
      </div>
    </>
  );
};

Header.propTypes = {
  attachmentsData: PropTypes.array,
  children: PropTypes.node,
  contractData: PropTypes.object,
  headingText: PropTypes.string,
  refData: PropTypes.object,
};

Header.defaultProps = {
  attachmentsData: undefined,
  children: undefined,
  contractData: undefined,
  headingText: undefined,
  refData: undefined,
};

export default Header;
