import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import Input from "./Input";
import InputField from "./InputField";

const InputDate = ({
  className,
  defaultValues,
  errors,
  onChange,
  placeholderText,
  values,
  ...props
}) => (
  <InputField className={className} errors={errors} {...props}>
    <fieldset name="dateInputs">
      <div className="flex w-full">
        <NumberFormat
          allowLeadingZeros
          allowNegative={false}
          className="w-1/4 mr-3"
          customInput={Input}
          decimalSeparator={false}
          defaultValue={defaultValues && defaultValues.day}
          errors={errors}
          isAllowed={({ floatValue, value }) => value === "" || floatValue <= 31}
          maxLength="2"
          name="day"
          onChange={onChange}
          placeholderText={placeholderText[0]}
          value={values && values.day}
        />
        <NumberFormat
          allowLeadingZeros
          allowNegative={false}
          className="w-1/4 mr-3"
          customInput={Input}
          decimalSeparator={false}
          defaultValue={defaultValues && defaultValues.month}
          errors={errors}
          isAllowed={({ floatValue, value }) => value === "" || floatValue <= 12}
          maxLength="2"
          name="month"
          onChange={onChange}
          placeholderText={placeholderText[1]}
          value={values && values.month}
        />
        <NumberFormat
          allowLeadingZeros
          allowNegative={false}
          className="w-2/4"
          customInput={Input}
          decimalSeparator={false}
          defaultValue={defaultValues && defaultValues.year}
          errors={errors}
          format="####"
          name="year"
          onChange={onChange}
          placeholderText={placeholderText[2]}
          value={values && values.year}
        />
      </div>
    </fieldset>
  </InputField>
);

InputDate.propTypes = {
  className: PropTypes.string,
  defaultValues: PropTypes.object,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  placeholderText: PropTypes.array,
  values: PropTypes.object,
};

InputDate.defaultProps = {
  className: undefined,
  defaultValues: undefined,
  errors: undefined,
  onChange: undefined,
  placeholderText: [],
  values: undefined,
};

export default InputDate;
