import get from "lodash.get";
import { queryCache, useMutation } from "react-query";
import { useParams, useRouteMatch } from "react-router-dom";
import * as api from "../api";
import { ROUTES, STATUSES } from "../constants";
import { prepareContract, prepareEndorsement } from "../helpers";

const useContractQuery = () => {
  const { contractId, contractType } = useParams();
  const isContract = contractType === "quotes";
  const isEndorsementsTab = useRouteMatch(ROUTES.CONTRACT_ENDORSEMENTS);
  const isEndorsementsStepper = contractType === "endorsements";

  const [createContract, createContractQuery] = useMutation(api.createContract, {
    throwOnError: true,
    onSuccess: () => {
      queryCache.invalidateQueries("contracts");
    },
  });

  const [updateContract, updateContractQuery] = useMutation(api.updateContract, {
    throwOnError: true,
    onSuccess: async (data) => {
      const companyCheck = get(data, "data.insured.Company.sanctionsCheck");
      const individualCheck = get(data, "data.insured.Individual.sanctionsCheck");
      const sanctionsCheckStatus = companyCheck || individualCheck;
      const hasSanctionsCheckFailed = sanctionsCheckStatus === "Failed";
      const isCancelled = get(data, "data.status") === STATUSES.CANCELLED;

      /* Sanctions check will transition contract to NTU, which means losing access to stepper.
         So in order to show sanctions check modals after updating contract, we must not update
         the cache when sanctions check fails. Otherwise the user will get redirected to "/" before
         he can see the modal.
         Also, when policy is cancelled via modal, we display a message after the request is done,
         so we prevent updating cache in that scenario as well.
      */
      if (!hasSanctionsCheckFailed && !isCancelled) {
        queryCache.setQueryData(["contract", { contractId }], data);
      }

      queryCache.invalidateQueries("contracts");
      queryCache.invalidateQueries("history");
      queryCache.invalidateQueries("patches");
    },
  });

  const [updateClaimFlag, updateClaimFlagQuery] = useMutation(api.updateContractClaimFlag, {
    onSuccess: async (data) => {
      queryCache.setQueryData(["contract", { contractId }], data);
    },
  });

  const [updatePremiumPaidFlag, updatePremiumPaidFlagQuery] = useMutation(
    api.updateContractPremiumPaidFlag,
    {
      onSuccess: async (data) => {
        queryCache.setQueryData(["contract", { contractId }], data);
      },
    }
  );

  const [createEndorsement, createEndorsementQuery] = useMutation(api.createEndorsement, {
    onSuccess: async (data) => {
      queryCache.setQueryData(["endorsement", { contractId }], data);
      queryCache.invalidateQueries("contract", { contractId });
    },
  });

  const [updateEndorsement, updateEndorsementQuery] = useMutation(api.updateEndorsement, {
    onSuccess: async (data) => {
      queryCache.setQueryData(["endorsement", { contractId }], data);
      queryCache.invalidateQueries("contract", { contractId });
    },
  });

  const handleSubmitContract = (formValues, contractData) => {
    const preparedContract = prepareContract(contractData, formValues);
    const preparedEndorsement = prepareEndorsement(contractData, formValues);

    if ("claimFlag" in formValues) {
      return updateClaimFlag({ data: formValues, contractId });
    }

    if ("premiumPaidFlag" in formValues) {
      return updatePremiumPaidFlag({ data: formValues, contractId });
    }

    if (!contractId) {
      return createContract({ data: formValues });
    }

    if (isContract) {
      return updateContract({ data: preparedContract, contractId });
    }

    if ("cancellationDate" in formValues) {
      return updateContract({ data: preparedContract, contractId });
    }

    if (isEndorsementsTab) {
      return createEndorsement({ data: formValues, contractId });
    }

    if (isEndorsementsStepper) {
      return updateEndorsement({ data: preparedEndorsement, contractId });
    }

    return false;
  };

  const isSubmittingContract =
    updatePremiumPaidFlagQuery.isLoading ||
    updateClaimFlagQuery.isLoading ||
    createContractQuery.isLoading ||
    updateContractQuery.isLoading ||
    createEndorsementQuery.isLoading ||
    updateEndorsementQuery.isLoading;

  return {
    createContractQuery,
    createEndorsementQuery,
    handleSubmitContract,
    isSubmittingContract,
    updateContractQuery,
    updateEndorsementQuery,
  };
};

export default useContractQuery;
