import get from "lodash.get";
import moment from "moment";
import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import Banner from "../components/Banner";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCalendar from "../components/InputCalendar";
import InputCheckbox from "../components/InputCheckbox";
import Modal from "../components/Modal";
import Table from "../components/Table";
import TableDataPoint from "../components/TableDataPoint";
import { QUOTE_STATUSES, ROUTES } from "../constants";
import { isBeforeToday, prepareData } from "../helpers";
import { useAuth, useContractQuery, useForm, useQuoteQuery } from "../hooks";
import { priceDataPointsMap } from "../modules/ContractDetails/config";

const today = new Date();
const minDate = new Date(today);
const maxDate = new Date(today);

minDate.setDate(minDate.getDate() - 30);
minDate.setHours(0, 0, 0, 0);
maxDate.setDate(maxDate.getDate() + 30);

const errorText =
  "For a backdated inception date, you cannot bind the policy without agreeing to the warranty of no known claims. Please confirm the warranty or change the inception date.";

const BindPriceModal = ({ handleClose, contractData, quoteData, quoteIndex }) => {
  const { checkPermissions } = useAuth(contractData);
  const { contractId } = useParams();

  const { handleSubmitContract, isSubmittingContract } = useContractQuery();
  const { handleSubmitQuote, isSubmittingQuote } = useQuoteQuery();

  const history = useHistory();
  const existingInceptionIsoDate = get(contractData, "period.start");
  const existingInceptionDate = new Date(existingInceptionIsoDate);

  const data = prepareData({
    checkPermissions,
    contractData,
    dataPoints: priceDataPointsMap,
    params: { index: quoteIndex },
  });

  const handleFormSubmit = async (formValues) => {
    await handleSubmitContract(formValues, contractData);
    await handleSubmitQuote({ status: QUOTE_STATUSES.BOUND }, quoteData);

    handleClose();

    history.push(compile(ROUTES.CONTRACT_DETAILS)({ contractId, contractType: "policies" }), {
      isForced: true,
    });
  };

  const { getFieldProps, canSubmit, onSubmit, formValues, setFormErrors, formErrors } = useForm({
    initialState: {
      warrantyOfNoKnownClaims: false,
      inceptionDate: existingInceptionIsoDate ? existingInceptionDate : undefined,
    },
    onSubmit: () => {
      const isInceptionBeforeToday = isBeforeToday(formValues.inceptionDate);

      if (isInceptionBeforeToday && !formValues.warrantyOfNoKnownClaims) {
        return setFormErrors({ warrantyOfNoKnownClaims: errorText });
      }

      return handleFormSubmit(formValues);
    },
    validationSchema: yup.object().shape({
      inceptionDate: yup.date().min(minDate).max(maxDate).required(),
    }),
  });

  const isInceptionBeforeToday = isBeforeToday(formValues.inceptionDate);

  return (
    <Modal handleClose={handleClose} headingText={`Bind price - ${quoteData.ref}`}>
      <div className="p-6 w-160">
        <Form onSubmit={onSubmit}>
          <InputCalendar
            className="mb-4"
            dayPickerProps={{ disabledDays: [{ after: maxDate, before: minDate }] }}
            isRequired
            labelText="Choose a date"
            placeholder="DD/MM/YYYY"
            {...getFieldProps("inceptionDate")}
          />

          {isInceptionBeforeToday && (
            <Banner
              key={formValues.inceptionDate}
              className="mb-6"
              color="orange"
              headingText="Prior notice - backdated inception date"
            >
              <p>The insured warrants no known or reported losses as of:</p>

              <p className="font-medium mb-4">{moment.utc().format("DD/MM/YYYY")}</p>

              <InputCheckbox labelText="I accept" {...getFieldProps("warrantyOfNoKnownClaims")} />

              {formErrors.warrantyOfNoKnownClaims && (
                <p className="mt-4 text-red-800 text-sm">{formErrors.warrantyOfNoKnownClaims}</p>
              )}
            </Banner>
          )}

          <Table className="mb-4">
            <Table.Body>
              <TableDataPoint {...data.deductible} />
              <TableDataPoint {...data.gross} isStriped />
              <TableDataPoint {...data.commission} />
              <TableDataPoint {...data.ipt} isStriped />
              <TableDataPoint {...data.total} />
            </Table.Body>
          </Table>

          <div className="flex">
            <Button
              className="mr-4"
              isDisabled={isSubmittingQuote || isSubmittingContract || !canSubmit}
              kind="primary"
              name="Bind quote"
              type="submit"
            >
              Bind quote
            </Button>

            <Button kind="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

BindPriceModal.propTypes = {
  contractData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  quoteData: PropTypes.object.isRequired,
  quoteIndex: PropTypes.number.isRequired,
};

export default BindPriceModal;
