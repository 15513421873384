import moment from "moment";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { useParams } from "react-router-dom";
import CardAccordion from "../../components/CardAccordion";
import StatusTag from "../../components/StatusTag";
import ContractDetails from "../ContractDetails";

const dateFormatter = (value) => moment.utc(value).local().format("DD-MM-YYYY, HH:mm:ss");

const Timeline = ({ contractHistData, contractPatchData, refData, isEndorsement }) => {
  const { contractId } = useParams();
  const keys = Object.keys(contractHistData).reverse();

  return (
    <CardAccordion id={contractId}>
      {keys.map((key, index) => {
        const contractData = contractHistData[key];
        const { status, endorsementStatus, ref } = contractData;
        const patchData = contractPatchData[key];
        const isFirst = index === keys.length - 1;
        const isFirstCreated = key === "0";
        const isCurrent = index === 0;
        const createdLabelText = `Created - ${dateFormatter(contractData.createdAt)}`;
        const editedLabelText = `Edited - ${dateFormatter(contractData.updatedAt)}`;
        const labelText = isFirstCreated ? createdLabelText : editedLabelText;

        return (
          <CardAccordion.Panel
            key={key}
            id={ref + key}
            headingText={ref}
            label={<p className="text-sm">{labelText}</p>}
            tag={
              <div className="flex">
                <StatusTag className="mr-4" status={endorsementStatus || status} />
              </div>
            }
          >
            <div className="p-6">
              <ContractDetails
                contractData={contractData}
                id={contractId + key}
                isCurrent={isCurrent}
                isEndorsement={isEndorsement}
                patchData={patchData}
                refData={refData}
                showDiff={!isFirst}
              />
            </div>
          </CardAccordion.Panel>
        );
      })}
    </CardAccordion>
  );
};

Timeline.propTypes = {
  contractHistData: PropTypes.object.isRequired,
  contractPatchData: PropTypes.object.isRequired,
  refData: PropTypes.object.isRequired,
  isEndorsement: PropTypes.bool,
};

Timeline.defaultProps = {
  isEndorsement: false,
};

const areEqual = (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps);

export default memo(Timeline, areEqual);
