import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { stringifyParams } from "../helpers";

const PaginationItem = ({ children, isActive, className }) => (
  <div
    style={{ fontVariantNumeric: "tabular-nums" }}
    className={clsx(
      "flex items-center text-sm box-content",
      !isActive && "text-gray-1000 border-r border-gray-700 hover:bg-gray-200 last:border-0",
      isActive && "bg-blue-600 text-white -mx-px",
      className
    )}
  >
    {children}
  </div>
);

const LinkItem = ({ children, isActive, to }) => (
  <PaginationItem isActive={isActive}>
    <Link to={to} className="px-3 h-full flex items-center">
      {children}
    </Link>
  </PaginationItem>
);

const Pagination = ({ count, perPage, page, searchTerm, isTrimmed }) => {
  const location = useLocation();
  const totalPagesNum = Math.ceil(count / perPage);
  const pageNumbers = Array.from({ length: totalPagesNum }, (item, index) => index + 1);
  const perPageItems = [10, 50, 100];
  const trimCurrentCount = 2;
  const trimEdgesCount = 2;
  const ellipsis = "…";

  const trimmedPageNumbers = pageNumbers
    .map((item) => {
      const shouldTrimBeforeCurrent = item < page - trimCurrentCount && item > trimEdgesCount;
      const shouldTrimAfterCurrent =
        item > page + trimCurrentCount && item <= pageNumbers.length - trimEdgesCount;

      if (shouldTrimBeforeCurrent || shouldTrimAfterCurrent) {
        return ellipsis;
      }

      return item;
    })
    .reduce((acc, next) => {
      const prev = acc[acc.length - 1];

      if (next === ellipsis && prev === ellipsis) {
        return acc;
      }

      return [...acc, next];
    }, []);

  const pages = isTrimmed ? trimmedPageNumbers : pageNumbers;

  const getNextPath = (newFilters) => ({
    pathname: location.pathname,
    search: stringifyParams({ page, perPage, searchTerm, ...newFilters }),
  });

  return (
    <div className="flex justify-between">
      <div className="inline-flex rounded-sm border border-gray-700 h-8 mr-8">
        {page > 1 && <LinkItem to={getNextPath({ page: page - 1 })}>Prev</LinkItem>}

        {pages.map((item, index) => {
          if (item === ellipsis) {
            return (
              <PaginationItem key={index.toString()} className="px-3">
                {item}
              </PaginationItem>
            );
          }

          return (
            <LinkItem
              key={index.toString()}
              isActive={page === item}
              to={getNextPath({ page: item })}
            >
              {item}
            </LinkItem>
          );
        })}

        {page < totalPagesNum && <LinkItem to={getNextPath({ page: page + 1 })}>Next</LinkItem>}
      </div>
      <div className="flex items-center">
        <p className="mr-3 text-sm">Results per page</p>
        <div className="inline-flex rounded-sm border border-gray-700 h-8">
          {perPageItems.map((item) => (
            <LinkItem
              key={item}
              isActive={perPage === item}
              to={getNextPath({ page: 1, perPage: item })}
            >
              {item}
            </LinkItem>
          ))}
        </div>
      </div>
    </div>
  );
};

PaginationItem.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  className: PropTypes.string,
};

PaginationItem.defaultProps = {
  children: undefined,
  isActive: undefined,
  className: undefined,
};

LinkItem.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  to: PropTypes.object,
};

LinkItem.defaultProps = {
  children: undefined,
  isActive: undefined,
  to: undefined,
};

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  isTrimmed: PropTypes.bool,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
};

Pagination.defaultProps = {
  isTrimmed: true,
  searchTerm: undefined,
};

export default Pagination;
