import PropTypes from "prop-types";
import React, { useState } from "react";
import { queryCache, useMutation } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../api";
import Button from "../components/Button";
import Form from "../components/Form";
import InputFile from "../components/InputFile";
import Modal from "../components/Modal";
import Table from "../components/Table";
import { downloadDocument, toBase64 } from "../helpers";

const columns = [
  { path: "fileName", displayName: "Document name", className: "truncate max-w-3xs" },
  { path: "documentSize", displayName: "Size" },
  { path: "download", displayName: "Action", className: "w-40" },
];

const AttachmentsModal = ({ attachmentsData, handleClose, canUpload }) => {
  const [selectedDocumentId, setSelectedDocumentId] = useState();
  const [formErrors, setFormErrors] = useState({ uploadAttachment: undefined });
  const { contractId } = useParams();
  const [uploadAttachment, uploadAttachmentQuery] = useMutation(api.uploadContractAttachment, {
    onSuccess: () => queryCache.invalidateQueries("attachments", { contractId }),
  });

  const [getContractAttachmentLink, getContractAttachmentLinkQuery] = useMutation(
    api.getContractAttachmentLink
  );

  const hasData = attachmentsData.length > 0;
  const hasError = uploadAttachmentQuery.error || formErrors.uploadAttachment;

  const handleSubmit = async (event) => {
    const file = event.target.uploadAttachment.files[0];
    const fileSizeInBase64 = (file.size + 814) * 1.37;
    const sizeThreshold = (6000000 - 814) / 1.37;
    const body = await toBase64(file);

    if (fileSizeInBase64 > sizeThreshold) {
      return setFormErrors({ uploadAttachment: "File size is too big" });
    }

    return uploadAttachment({ contractId, data: { body, fileName: file.name } });
  };

  const handleChange = async () => {
    return setFormErrors({ uploadAttachment: undefined });
  };

  const handleDownloadClick = async (row) => {
    setSelectedDocumentId(row.documentId);

    const res = await getContractAttachmentLink({ contractId, attachmentId: row.documentId });

    downloadDocument(res.data.url, res.data.fileName);
  };

  const renderData = ({ row, column, value }) => {
    if (column.displayName === "Action") {
      return (
        <Button
          isDisabled={
            row.documentId === selectedDocumentId && getContractAttachmentLinkQuery.isLoading
          }
          kind="secondary"
          size="small"
          className="-my-2"
          onClick={() => handleDownloadClick(row)}
        >
          Download
        </Button>
      );
    }

    return value;
  };

  return (
    <Modal handleClose={handleClose} headingText="Attachments">
      <div className="p-6 w-160">
        {canUpload && (
          <Form onSubmit={handleSubmit} onChange={handleChange} className="mb-4">
            <div className="flex">
              <InputFile
                errors={uploadAttachmentQuery.error || formErrors.uploadAttachment}
                isDisabled={uploadAttachmentQuery.isLoading}
                isRequired
                labelText="Attach a file"
                name="uploadAttachment"
                className="w-full max-w-sm"
              />

              <Button
                className="ml-4 mt-8 whitespace-no-wrap"
                kind="primary"
                type="submit"
                isDisabled={uploadAttachmentQuery.isLoading}
              >
                Upload Attachment
              </Button>
            </div>

            {!hasError && (
              <p className="text-sm text-gray-1000 mt-2">
                Please note: the maximum file upload size is 4.3mb
              </p>
            )}
          </Form>
        )}

        {!hasData && <p className="text-center mt-6 mb-2">No documents were attached…</p>}

        {hasData && (
          <Table columns={columns} rows={attachmentsData} renderData={renderData} isStriped />
        )}
      </div>
    </Modal>
  );
};

AttachmentsModal.propTypes = {
  attachmentsData: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  canUpload: PropTypes.bool.isRequired,
};

AttachmentsModal.defaultProps = {
  attachmentsData: [],
};

export default AttachmentsModal;
