import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useQuery } from "react-query";
import * as api from "../../api";
import Button from "../../components/Button";
import Form from "../../components/Form";
import H3 from "../../components/H3";
import InputText from "../../components/InputText";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { ACTIONS } from "../../constants";
import { useAuth } from "../../hooks";
import { companyColumns, dormantNatureOfBusinesses } from "./config";
import { searchCompanySchema } from "./schema";

const CompanySearchForm = ({ contractData, selectedInsured, setSelectedInsured }) => {
  const { checkPermissions } = useAuth(contractData);
  const existingCompanyData = get(contractData, "insured.Company");

  const initialCompanyData = {
    company_name: get(existingCompanyData, "name"),
    company_number: get(existingCompanyData, "number"),
    reg_address_address_line_1: get(existingCompanyData, "address.line1"),
    reg_address_address_line_2: get(existingCompanyData, "address.line2"),
    reg_address_post_code: get(existingCompanyData, "address.postCode"),
    reg_address_post_town: get(existingCompanyData, "address.city"),
    sic_code_sic_text_1: get(existingCompanyData, "natureOfBusiness[0]"),
    company_status: "Active",
  };

  const [values, setValues] = useState({ companyName: initialCompanyData.company_name || "" });
  const [searchQuery, setSearchQuery] = useState();

  const companiesQuery = useQuery(["companies", searchQuery], api.getCompanies, {
    enabled: searchQuery,
  });
  const initialCompaniesData = existingCompanyData ? [initialCompanyData] : [];
  const companiesData = get(companiesQuery, "data.data.data", initialCompaniesData);
  const companiesCount = get(companiesQuery, "data.data.total", initialCompaniesData.length);
  const hasResults = searchQuery && companiesCount > 0 && companiesQuery.isSuccess;
  const isLoading = searchQuery && companiesQuery.isLoading;
  const isSearchCompanySchemaValid = searchCompanySchema.isValidSync(values);

  const columnsByRole = companyColumns.filter(
    (column) => !(column.permissions && !checkPermissions(column.permissions))
  );

  const handleCompanySearchFormChange = (event) =>
    setValues({ ...values, [event.target.name]: event.target.value });

  const handleCompanySearchFormSubmit = () => {
    setSearchQuery(searchCompanySchema.cast(values));
    setSelectedInsured(undefined);
  };

  const renderData = ({ row, column, value }) => {
    if (column.displayName === "Action") {
      const isStatusActive = get(row, "company_status", "") === "Active";
      const isDormant = dormantNatureOfBusinesses.includes(get(row, "sic_code_sic_text_1"));
      const isCompanyActive = isStatusActive && !isDormant;

      if (!isCompanyActive) {
        return "Dormant - cannot be selected";
      }

      if (row.company_number === selectedInsured) {
        return (
          <Button kind="secondary" size="small" className="-my-2 text-blue-600">
            Selected
          </Button>
        );
      }

      return (
        <Button
          kind="secondary"
          size="small"
          className="-my-2 "
          onClick={() => setSelectedInsured(row.company_number)}
        >
          Select
        </Button>
      );
    }

    return value;
  };

  return (
    <>
      {checkPermissions(ACTIONS.EDIT_INSURED) && (
        <Form
          className="mb-6"
          hasContext
          id="companySearch"
          name="company search form"
          onSubmit={handleCompanySearchFormSubmit}
        >
          <H3 className="mb-6" id="searchCompanyLabel">
            Search for a company name
          </H3>

          <div className="flex w-160">
            <InputText
              aria-labelledby="searchCompanyLabel"
              className="mr-4 flex-1"
              name="companyName"
              onChange={handleCompanySearchFormChange}
              placeholder="Search"
              value={values.companyName}
            />

            <Button
              isDisabled={!isSearchCompanySchemaValid || companiesQuery.isLoading}
              kind="primary"
              name="Search"
              type="submit"
            >
              Search
            </Button>
          </div>
        </Form>
      )}

      {isLoading && <Loader className="mt-32 mx-auto" />}

      {(searchQuery || existingCompanyData) &&
        companiesQuery.status !== "loading" &&
        checkPermissions(ACTIONS.EDIT_INSURED) && (
          <p className="mb-8">
            We found <span className="font-medium text-blue-600">{companiesCount}</span>{" "}
            {companiesCount === 1 ? "match" : "matches"} with those details.{" "}
            {companiesCount >= 50 && <span>Try searching for a more specific company name.</span>}
          </p>
        )}

      {(hasResults || existingCompanyData) && !isLoading && (
        <Table columns={columnsByRole} rows={companiesData} renderData={renderData} isStriped />
      )}
    </>
  );
};

CompanySearchForm.propTypes = {
  contractData: PropTypes.object,
  selectedInsured: PropTypes.string,
  setSelectedInsured: PropTypes.func,
};

CompanySearchForm.defaultProps = {
  contractData: undefined,
  selectedInsured: undefined,
  setSelectedInsured: undefined,
};

export default CompanySearchForm;
