import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "../components/Button";
import Form from "../components/Form";
import InputAddress from "../components/InputAddress";
import InputCurrency from "../components/InputCurrency";
import InputSelect from "../components/InputSelect";
import InputText from "../components/InputText";
import Modal from "../components/Modal";
import Stepper from "../components/Stepper";
import { useForm } from "../hooks";
import { addressSchema, locationSchema } from "../modules/ContractStepperLocations/schema";

const labelMap = {
  Commercial: {
    labelText: "Business Interruption (BI) Value",
    descriptionText:
      "The maximum Gross Earnings, less charges and expenses, that you calculate could be lost from Direct Physical Loss of or Damage to the Insured property. This figure should be the total value across the indemnity period. Example: If you want £5m of cover per year for two years, enter £10m here",
  },
  Residential: {
    labelText: "Rental Income Value",
    descriptionText:
      "The maximum loss in Rental Income, less charges and expenses, that you calculate could be lost from Direct Physical Loss of or Damage to the Insured property. This figure should be the total value across the indemnity period. Example: If you want £5m of cover per year for two years, enter £10m here",
  },
};

const AddressModal = ({
  businessType,
  handleClose,
  handleConfirm,
  initialValues,
  isCommercial,
  isCompany,
  isEditingLocation,
  locationTypes,
}) => {
  const hasInitialValues = Object.keys(initialValues).length > 0;
  const [isAddressFormVisible, setIsAddressFormVisible] = useState(hasInitialValues);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const { getFieldProps, canSubmit, onSubmit, formValues, setFormValues } = useForm({
    initialState: initialValues,
    onSubmit: handleConfirm,
    validationSchema: locationSchema,
    validationOptions: { context: { isCommercial, isCompany } },
  });

  const isAddressFormValid = addressSchema.isValidSync(formValues.address);

  const handlePlaceChange = (newPlace) => {
    const { line1, line2, postCode, city } = newPlace;

    setFormValues({ ...formValues, address: { line1, line2, postCode, city } });
    setIsAddressFormVisible(true);
  };

  return (
    <Modal
      handleClose={handleClose}
      headingText={isEditingLocation ? "Edit location" : "Add location"}
    >
      <div className="border-b border-gray-400 h-20 flex items-center">
        <Stepper activeIndex={currentStepIndex} className="flex-1 justify-between" showSeparators>
          <Stepper.StepSmall>
            {isEditingLocation ? "Edit" : "Add"} location address
          </Stepper.StepSmall>
          <Stepper.StepSmall>
            {isEditingLocation ? "Edit" : "Add"} location cover values
          </Stepper.StepSmall>
        </Stepper>
      </div>

      <Form onSubmit={onSubmit}>
        {currentStepIndex === 0 && (
          <div className="p-6 w-160">
            <InputAddress
              autoFocus
              className="mb-6"
              labelText="Search for address"
              name="addressSearch"
              onPlaceChange={handlePlaceChange}
              placeholderText="Start typing here"
            />

            {!isAddressFormVisible && (
              <Button kind="primary" onClick={() => setIsAddressFormVisible(true)}>
                Input manually
              </Button>
            )}

            {isAddressFormVisible && (
              <>
                <InputText
                  className="mb-6"
                  isRequired
                  labelText="Address line 1"
                  {...getFieldProps("address.line1")}
                />

                <InputText
                  className="mb-6"
                  labelText="Address line 2 (optional)"
                  {...getFieldProps("address.line2")}
                />

                <InputText
                  className="mb-6"
                  isRequired
                  labelText="Town / City"
                  {...getFieldProps("address.city")}
                />

                <InputText
                  className="mb-6"
                  isRequired
                  labelText="Postal code"
                  {...getFieldProps("address.postCode")}
                />

                <div className="flex justify-between">
                  <Button onClick={handleClose}>Cancel</Button>

                  <Button
                    isDisabled={!isAddressFormValid}
                    kind="primary"
                    onClick={() => setCurrentStepIndex(1)}
                  >
                    Confirm address
                  </Button>
                </div>
              </>
            )}
          </div>
        )}

        {currentStepIndex === 1 && (
          <div className="p-6 w-160">
            <InputCurrency
              className="mb-6"
              currencies={["GBP"]}
              isRequired
              labelText="Property Damage Value"
              descriptionText="This should be the total of the full replacement cost (include any fixtures and fittings) of the insured property"
              {...getFieldProps("propertyDamage")}
            />

            {isCompany && (
              <InputCurrency
                className="mb-6"
                currencies={["GBP"]}
                {...(labelMap[businessType] || {})}
                {...getFieldProps("businessInterruption")}
              />
            )}

            <InputSelect
              className="mb-6"
              initialSelectedItem={
                locationTypes.find((item) => item === formValues.locationType)
                  ? formValues.locationType
                  : undefined
              }
              isRequired
              items={locationTypes}
              key={formValues.businessType}
              labelText="Location type"
              {...getFieldProps("locationType")}
            />

            <div className="flex justify-between">
              <Button onClick={() => setCurrentStepIndex(0)}>Back</Button>

              <Button isDisabled={!canSubmit} kind="primary" type="submit">
                Confirm and save location
              </Button>
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};

AddressModal.propTypes = {
  businessType: PropTypes.string,
  initialValues: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  locationTypes: PropTypes.array.isRequired,
  isCommercial: PropTypes.bool.isRequired,
  isCompany: PropTypes.bool.isRequired,
  isEditingLocation: PropTypes.bool.isRequired,
};

AddressModal.defaultProps = {
  businessType: undefined,
  initialValues: {},
};

export default AddressModal;
