import PropTypes from "prop-types";
import React from "react";
import LogoAlt from "../../components/LogoAlt";

const AuthLayout = ({ children }) => (
  <div className="h-screen flex">
    <div className="h-full bg-white w-full max-w-96 xl:max-w-128 flex flex-col overflow-y-auto">
      <div className="pt-32 xl:pt-64 flex justify-center mb-20">
        {process.env.ENVIRONMENT !== "uat" && <LogoAlt />}
      </div>

      <div className="px-12 flex-auto">{children}</div>

      <p className="text-gray-500 text-center py-10">Powered by ArtificialOS</p>
    </div>

    <div
      className="h-full w-full bg-center bg-cover"
      style={{ backgroundImage: "url(/Chaucer\\ Image\\ Treated13.jpg)" }}
    />
  </div>
);

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthLayout;
