import PropTypes from "prop-types";
import React from "react";
import useFormContext from "../context/Form";

const Fieldset = ({ onChange, hasContext, ...props }) => {
  const formContext = useFormContext();

  const handleChange = (event) => {
    if (hasContext) {
      formContext.setValue({ isDirty: true });
    }

    if (onChange) {
      onChange(event);
    }
  };

  return <fieldset onChange={handleChange} {...props} />;
};

Fieldset.propTypes = {
  hasContext: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

Fieldset.defaultProps = {
  hasContext: undefined,
  onChange: undefined,
  onSubmit: undefined,
};

export default Fieldset;
