import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { ROUTES } from "../constants";
import { useContractQuery } from "../hooks";

const AmendPolicyModal = ({ handleClose, contractData }) => {
  const { contractId } = useParams();
  const { handleSubmitContract, isSubmittingContract } = useContractQuery();
  const { push } = useHistory();

  const handleConfirm = async () => {
    await handleSubmitContract({}, contractData);

    return push(compile(ROUTES.CONTRACT_STEPPER)({ contractId, contractType: "endorsements" }));
  };

  return (
    <Modal handleClose={handleClose} headingText="Endorse policy">
      <div className="p-6 w-160">
        <p className="mb-6">
          You’re about to create a new policy endorsement. Are you sure you want to do this?
        </p>

        <div className="flex">
          <Button
            className="mr-4"
            isDisabled={isSubmittingContract}
            kind="primary"
            onClick={handleConfirm}
          >
            Endorse policy
          </Button>

          <Button kind="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

AmendPolicyModal.propTypes = {
  contractData: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AmendPolicyModal;
