import get from "lodash.get";
import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import Fieldset from "../../components/Fieldset";
import Footer from "../../components/Footer";
import Form from "../../components/Form";
import H3 from "../../components/H3";
import InputRadio from "../../components/InputRadio";
import { ACTIONS, ROUTES } from "../../constants";
import useFormContext from "../../context/Form";
import { useAuth, useContractQuery, useModal } from "../../hooks";
import SanctionsCheckFail from "../../modals/SanctionsCheckFail";
import SanctionsCheckNoResults from "../../modals/SanctionsCheckNoResults";
import CompanySearchForm from "./CompanySearchForm";
import IndividualCreateForm from "./IndividualCreateForm";
import IndividualSearchForm from "./IndividualSearchForm";
import { insuredSchema } from "./schema";

const ContractStepperInsured = ({ contractData }) => {
  const { checkPermissions } = useAuth(contractData);
  const { contractId, contractType = "quotes" } = useParams();
  const history = useHistory();
  const formContext = useFormContext();
  const { handleSubmitContract, isSubmittingContract } = useContractQuery();
  const { modal, showModal, closeModal } = useModal();
  const [errorMessage, setErrorMessage] = useState();

  const existingIndividualData = get(contractData, "insured.Individual");
  const existingCompanyData = get(contractData, "insured.Company");
  const existingInsuredType =
    (existingIndividualData && "Individual") ||
    (existingCompanyData && "Company") ||
    (!checkPermissions(ACTIONS.INSURE_INDIVIDUALS) && "Company");
  const existingCompanyNumber = get(contractData, "insured.Company.number");
  const existingIndividualId = get(contractData, "insured.Individual.id");
  const existingInsuredId = existingCompanyNumber || existingIndividualId;

  const [isCreatingIndividual, setIsCreatingIndividual] = useState();
  const [nextContractId, setNextContractId] = useState(contractId);

  const initialValues = insuredSchema.cast({ id: existingInsuredId, type: existingInsuredType });
  const [insuredFormValues, setInsuredFormValues] = useState(initialValues);
  const [createIndividualFormValues, setCreateIndividualFormValues] = useState();

  const isCompany = insuredFormValues.type === "Company";
  const isIndividual = insuredFormValues.type === "Individual";

  const getNextStepPath = compile(ROUTES.CONTRACT_STEPPER);

  const handleInsuredTypeChange = (event) => {
    setIsCreatingIndividual(false);
    setInsuredFormValues({ type: event.target.value, id: undefined });
  };

  const submitInsuredForm = async (insuredRef) => {
    try {
      const res = await handleSubmitContract({ insuredRef }, contractData);
      const companyCheck = get(res, "data.insured.Company.sanctionsCheck");
      const individualCheck = get(res, "data.insured.Individual.sanctionsCheck");
      const sanctionsCheckStatus = companyCheck || individualCheck;
      const newContractId = get(res, "data.id");

      setNextContractId(newContractId);

      if (sanctionsCheckStatus === "Failed") {
        return showModal("SanctionsCheckFail");
      }

      if (sanctionsCheckStatus === "Unknown") {
        return showModal("SanctionsCheckNoResults");
      }

      const params = { contractId: newContractId, contractType, step: "locations" };

      return history.push(getNextStepPath(params), { isForced: true });
    } catch (err) {
      return setErrorMessage("A server error occured.");
    }
  };

  const handleInsuredFormSubmit = () => {
    const insuredRef = {
      IndividualRef: isIndividual ? insuredFormValues.id : undefined,
      CompanyRef: isCompany ? insuredFormValues.id : undefined,
    };

    submitInsuredForm(insuredRef);
  };

  const setSelectedInsured = (id) => {
    formContext.setValue({ isDirty: true });

    setInsuredFormValues({ ...insuredFormValues, id });
  };

  const handleCreateNewIndividualClick = (values) => {
    setIsCreatingIndividual(true);
    setSelectedInsured(undefined);
    setCreateIndividualFormValues(values);
  };

  return (
    <>
      {errorMessage && (
        <Alert headingText="Error" color="red" cb={() => setErrorMessage(false)}>
          {errorMessage}
        </Alert>
      )}

      {modal === "SanctionsCheckFail" && (
        <SanctionsCheckFail
          handleClose={closeModal}
          handleConfirm={() => history.push(ROUTES.HOME, { isForced: true })}
        />
      )}

      {modal === "SanctionsCheckNoResults" && (
        <SanctionsCheckNoResults
          handleClose={closeModal}
          contractId={nextContractId}
          contractType={contractType}
        />
      )}

      {checkPermissions(ACTIONS.INSURE_INDIVIDUALS) && (
        <>
          <H3 className="mb-6">Insured Details</H3>

          {checkPermissions(ACTIONS.VIEW_INSURED_TYPE) && (
            <Fieldset form="insuredForm" hasContext>
              <p className="mb-6">Is the Insured a company or an individual?</p>

              <InputRadio
                className="mb-6"
                descriptionText="An active company registered with Companies House"
                id="company"
                isChecked={insuredFormValues.type === "Company"}
                labelText="Company"
                name="type"
                onChange={handleInsuredTypeChange}
                isDisabled={!checkPermissions(ACTIONS.EDIT_INSURED)}
                value="Company"
              />

              <InputRadio
                className="mb-8"
                descriptionText="An individual who would like to cover their private properties"
                id="individual"
                isChecked={insuredFormValues.type === "Individual"}
                labelText="Individual"
                name="type"
                onChange={handleInsuredTypeChange}
                isDisabled={!checkPermissions(ACTIONS.EDIT_INSURED)}
                value="Individual"
              />
            </Fieldset>
          )}
        </>
      )}

      {!isCreatingIndividual && (
        <>
          {isCompany && (
            <CompanySearchForm
              contractData={contractData}
              selectedInsured={insuredFormValues.id}
              setSelectedInsured={setSelectedInsured}
            />
          )}

          {isIndividual && (
            <IndividualSearchForm
              contractData={contractData}
              selectedInsured={insuredFormValues.id}
              setSelectedInsured={setSelectedInsured}
              handleCreateNewIndividualClick={handleCreateNewIndividualClick}
            />
          )}

          <Form id="insuredForm" onSubmit={handleInsuredFormSubmit} hasContext>
            <Footer>
              <Button as={Link} to={ROUTES.HOME}>
                Exit
              </Button>

              {((!formContext.isDirty && !insuredFormValues.id) || formContext.isDirty) && (
                <Button
                  isDisabled={!insuredFormValues.id || isSubmittingContract}
                  kind="primary"
                  type="submit"
                >
                  Save
                </Button>
              )}

              {contractId && !formContext.isDirty && insuredFormValues.id && (
                <Button
                  as={Link}
                  to={getNextStepPath({ contractId, contractType, step: "locations" })}
                  kind="primary"
                >
                  Continue
                </Button>
              )}
            </Footer>
          </Form>
        </>
      )}

      {isIndividual && isCreatingIndividual && (
        <IndividualCreateForm
          initialValues={createIndividualFormValues}
          submitInsuredForm={submitInsuredForm}
          isSubmittingContract={isSubmittingContract}
        />
      )}
    </>
  );
};

ContractStepperInsured.propTypes = {
  contractData: PropTypes.object,
};

ContractStepperInsured.defaultProps = {
  contractData: undefined,
};

export default ContractStepperInsured;
