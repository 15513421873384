import { queryCache, useMutation } from "react-query";
import { useParams, useRouteMatch } from "react-router-dom";
import * as api from "../api";
import { ROUTES } from "../constants";
import { prepareQuote } from "../helpers";

const useQuoteQuery = () => {
  const { contractId, contractType } = useParams();
  const isContract = contractType === "quotes";
  const isEndorsementRoute = useRouteMatch(ROUTES.CONTRACT_ENDORSEMENTS);
  const isEndorsement = isEndorsementRoute || contractType === "endorsements";

  const [updateQuote, updateQuoteQuery] = useMutation(api.updateQuote, {
    onSuccess: () => {
      queryCache.invalidateQueries("contract", { contractId });
      queryCache.invalidateQueries("contracts");
      queryCache.invalidateQueries("history");
      queryCache.invalidateQueries("patches");
    },
  });

  const [createQuote, createQuoteQuery] = useMutation(api.createQuote, {
    onSuccess: () => {
      queryCache.invalidateQueries("contract", { contractId });
      queryCache.invalidateQueries("contracts");
      queryCache.invalidateQueries("history");
      queryCache.invalidateQueries("patches");
    },
  });

  const [createEndoQuote, createEndoQuoteQuery] = useMutation(api.createEndoQuote, {
    onSuccess: () => {
      queryCache.invalidateQueries("contract", { contractId });
      queryCache.invalidateQueries("contracts");
      queryCache.invalidateQueries("history");
      queryCache.invalidateQueries("patches");
    },
  });

  const [updateEndoQuote, updateEndoQuoteQuery] = useMutation(api.updateEndoQuote, {
    onSuccess: () => {
      queryCache.invalidateQueries("contract", { contractId });
      queryCache.invalidateQueries("contracts");
      queryCache.invalidateQueries("history");
      queryCache.invalidateQueries("patches");
    },
  });

  const handleSubmitQuote = (formValues, quoteData) => {
    const preparedQuote = prepareQuote(quoteData, formValues);

    if (isContract && !quoteData) {
      return createQuote({ data: preparedQuote, contractId });
    }

    if (isContract && quoteData) {
      return updateQuote({ data: preparedQuote, contractId, quoteId: quoteData.id });
    }

    if (isEndorsement && !quoteData) {
      return createEndoQuote({ data: preparedQuote, contractId });
    }

    if (isEndorsement && quoteData) {
      return updateEndoQuote({ data: preparedQuote, contractId });
    }

    return false;
  };

  const isSubmittingQuote =
    updateQuoteQuery.isLoading ||
    createQuoteQuery.isLoading ||
    createEndoQuoteQuery.isLoading ||
    updateEndoQuoteQuery.isLoading;

  return {
    createEndoQuoteQuery,
    createQuoteQuery,
    handleSubmitQuote,
    isSubmittingQuote,
    updateQuoteQuery,
  };
};

export default useQuoteQuery;
