import * as Sentry from "@sentry/browser";
import { Auth } from "aws-amplify";
import "cross-fetch/polyfill";
import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { queryCache, useQuery } from "react-query";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import jwt from "jsonwebtoken";
import * as uuid from "uuid";

import * as api from "../../api";
import PageLoader from "../../components/PageLoader";
import Providers from "../../components/Providers";
import { ROUTES } from "../../constants";
import ForgotPassword from "../Auth/ForgotPassword";
import Login from "../Auth/Login";
import Logout from "../Auth/Logout";
import PasswordForm from "../Auth/PasswordForm";
import Contract from "../Contract";
import ContractIndex from "../ContractIndex";
import ContractStepper from "../ContractStepper";
import NotFound from "../NotFound";
import OutcodeExposures from "../OutcodeExposures";

Auth.configure({
  identityPoolId: process.env.COGNITO_IDENTITY_POOL_ID,
  region: "eu-west-2",
  identityPoolRegion: "eu-west-2",
  userPoolId: process.env.COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.COGNITO_WEB_CLIENT_ID,
});

const PrivateRoute = ({
  component: Component,
  path,
  isAuthenticated,
  setAuthenticated,
  ...rest
}) => {
  const history = useHistory();
  const usersQuery = useQuery(["users"], api.getUsers, { enabled: isAuthenticated });
  const tenantsQuery = useQuery(["tenants"], api.getTenants, { enabled: isAuthenticated });

  useEffect(() => {
    let timeout;

    const handleLogout = () => {
      history.push(ROUTES.LOGOUT, { isForced: true });
    };

    /* Logout after 30 minutes. */
    const resetTimer = () => {
      clearTimeout(timeout);

      timeout = setTimeout(handleLogout, process.env.SESSION_TIMEOUT || 60 * 30 * 1000);
    };

    document.addEventListener("click", resetTimer);
    document.addEventListener("keypress", resetTimer);
    document.addEventListener("mousemove", resetTimer);
    document.addEventListener("touchstart", resetTimer);
    resetTimer();

    return () => {
      clearTimeout(timeout);
      document.removeEventListener("click", resetTimer);
      document.removeEventListener("keypress", resetTimer);
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("touchstart", resetTimer);
    };
  });

  const init = async () => {
    try {
      await Auth.currentSession();
      const amplifyUser = await Auth.currentAuthenticatedUser();
      const firstName = get(amplifyUser, "attributes.given_name");
      const lastName = get(amplifyUser, "attributes.family_name");
      const email = get(amplifyUser, "attributes.email");
      const fullName = `${firstName} ${lastName}`;
      const role = get(amplifyUser, "attributes.custom:role");
      const id = amplifyUser.username;

      if (window.FS) {
        window.FS.identify(id, { displayName: fullName, email, role });

        Sentry.configureScope((scope) => {
          scope.setUser({ email, id, username: fullName });
        });
      }

      if (process.env.ZENDESK_SECRET_KEY) {
        // Zendesk setup
        const payload = {
          name: fullName,
          email,
          iat: Date.now() / 1000,
          jti: uuid.v4(),
        };

        const token = jwt.sign(payload, process.env.ZENDESK_SECRET_KEY);

        window.zESettings = {
          webWidget: {
            authenticate: {
              jwtFn: (cb) => {
                cb(`${token}`);
              },
            },
            helpCenter: {
              filter: {
                category: "360001369577",
              },
            },
            offset: {
              horizontal: "120px",
              vertical: "8px",
            },
          },
        };
      }

      queryCache.setQueryData("user", amplifyUser);
      setAuthenticated(true);
    } catch (err) {
      setAuthenticated(err);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (isAuthenticated === "not authenticated" || isAuthenticated === "No current user") {
    return <Redirect to={ROUTES.LOGIN} />;
  }

  return (
    <>
      <Route
        {...rest}
        path={path}
        render={() => {
          if (isAuthenticated && (usersQuery.isLoading || tenantsQuery.isLoading)) {
            return <PageLoader />;
          }

          if (isAuthenticated && usersQuery.data && tenantsQuery.data) {
            return <Component />;
          }

          return false;
        }}
      />
    </>
  );
};

const App = () => {
  const [isAuthenticated, setAuthenticated] = useState();
  useEffect(() => {
    if (isAuthenticated === true) {
      window.showZe();
    } else {
      window.hideZe();
    }
  }, [isAuthenticated]);

  return (
    <Providers>
      <Switch>
        <PrivateRoute
          path={ROUTES.HOME}
          component={ContractIndex}
          exact
          isAuthenticated={isAuthenticated}
          setAuthenticated={setAuthenticated}
        />
        <PrivateRoute
          path={ROUTES.NEW_QUOTE}
          component={ContractStepper}
          isAuthenticated={isAuthenticated}
          setAuthenticated={setAuthenticated}
        />
        <PrivateRoute
          path={ROUTES.OUTCODE_EXPOSURES}
          component={OutcodeExposures}
          isAuthenticated={isAuthenticated}
          setAuthenticated={setAuthenticated}
        />
        <PrivateRoute
          path={ROUTES.CONTRACT_STEPPER}
          component={ContractStepper}
          isAuthenticated={isAuthenticated}
          setAuthenticated={setAuthenticated}
        />
        <PrivateRoute
          path={ROUTES.CONTRACT}
          component={Contract}
          isAuthenticated={isAuthenticated}
          setAuthenticated={setAuthenticated}
        />
        <PrivateRoute
          path={ROUTES.POLICIES_INDEX}
          component={ContractIndex}
          isAuthenticated={isAuthenticated}
          setAuthenticated={setAuthenticated}
        />
        <PrivateRoute
          path={ROUTES.QUOTES_INDEX}
          component={ContractIndex}
          isAuthenticated={isAuthenticated}
          setAuthenticated={setAuthenticated}
        />
        <PrivateRoute
          path={ROUTES.REFERRALS_INDEX}
          component={ContractIndex}
          isAuthenticated={isAuthenticated}
          setAuthenticated={setAuthenticated}
        />
        <PrivateRoute
          path={ROUTES.NOT_FOUND}
          component={NotFound}
          isAuthenticated={isAuthenticated}
          setAuthenticated={setAuthenticated}
        />
        <Route path={ROUTES.LOGOUT} component={Logout} />
        <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={[ROUTES.RESET_PASSWORD, ROUTES.SETUP_PASSWORD]} component={PasswordForm} />
        <Route path={ROUTES.LOGIN} component={Login} />
      </Switch>
    </Providers>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  setAuthenticated: PropTypes.func.isRequired,
};

export default App;
