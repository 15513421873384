import get from "lodash.get";
import React from "react";
import { useQuery } from "react-query";
import { Redirect } from "react-router-dom";
import * as api from "../../api";
import Loader from "../../components/Loader";
import Table from "../../components/Table";
import { ACTIONS, ROUTES } from "../../constants";
import { useAuth } from "../../hooks";
import Header from "../Header";
import { columns } from "./config";

const OutcodeExposures = () => {
  const { checkPermissions } = useAuth();

  const outcodesQuery = useQuery(["outcodes"], api.getOutcodeExposures);
  const outcodesData = get(outcodesQuery, "data.data", []);
  const outcodesCount = outcodesData.length;
  const hasData = outcodesCount !== 0;

  const { isLoading } = outcodesData;

  if (!checkPermissions(ACTIONS.VIEW_OUTCODES_TAB)) {
    return <Redirect to={ROUTES.NOT_FOUND} />;
  }

  return (
    <>
      <Header headingText="Outcode Exposures" />

      <div className="p-8">
        {isLoading && <Loader className="mt-64 mx-auto" />}

        {!isLoading && (
          <Table
            columns={columns}
            rows={outcodesData.map((item) => ({
              ...item,
              isSaturated: item.isSaturated ? "Yes" : "No",
            }))}
            isStriped
          />
        )}

        {!isLoading && !hasData && (
          <div className="text-center mt-64">
            <p className="text-2xl font-medium mb-4">No data</p>
          </div>
        )}
      </div>
    </>
  );
};

export default OutcodeExposures;
