import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";
import Accordion from "../../components/Accordion";
import Table from "../../components/Table";
import { QUOTE_STATUSES } from "../../constants";
import { currencyFormatter } from "../../formatters";

const columns = [
  { path: "ref", displayName: "Reference" },
  { path: "rates.total", displayName: "Premium to Pay", formatter: currencyFormatter },
  { path: "deductible", displayName: "Deductible Amount", formatter: currencyFormatter },
  { path: "rates.commission", displayName: "Commission", formatter: currencyFormatter },
  { path: "rates.ipt", displayName: "IPT Applied", formatter: currencyFormatter },
];

const RejectedPrices = ({ contractData, ...props }) => {
  const quotesData = get(contractData, "quotes", []);
  const checkNtu = (quoteData) => quoteData.status === QUOTE_STATUSES.NOT_TAKEN_UP;
  const rejectedQuotesData = quotesData.filter(checkNtu);

  return (
    <Accordion.Panel headingText="Rejected prices" {...props}>
      <div className="my-6">
        <Table rows={rejectedQuotesData} columns={columns} isStriped />
      </div>
    </Accordion.Panel>
  );
};

RejectedPrices.propTypes = {
  contractData: PropTypes.object.isRequired,
};

export default RejectedPrices;
