export const ROUTES = {
  QUOTES: "/quotes/:options?",
  POLICIES: "/policies/:options?",
  REFERRALS: "/referrals/:options?",

  OUTCODE_EXPOSURES: "/outcode-exposures",
  QUOTES_INDEX: "/quotes",
  POLICIES_INDEX: "/policies",
  REFERRALS_INDEX: "/referrals",

  NEW_QUOTE: "/new-quote",

  CONTRACT: "/:contractType/:contractId/view/:tab?",
  CONTRACT_DETAILS: "/:contractType/:contractId/view/details",
  CONTRACT_HISTORY: "/:contractType/:contractId/view/history",
  CONTRACT_ENDORSEMENTS: "/:contractType/:contractId/view/endorsements",

  CONTRACT_STEPPER: "/:contractType/:contractId/edit/:step?",
  CONTRACT_STEPPER_INSURED: "/:contractType/:contractId/edit/insured",
  CONTRACT_STEPPER_LOCATIONS: "/:contractType/:contractId/edit/locations",
  CONTRACT_STEPPER_REQUIREMENTS: "/:contractType/:contractId/edit/requirements",
  CONTRACT_STEPPER_SUBLIMITS: "/:contractType/:contractId/edit/sublimits",

  HOME: "/",
  FORGOT_PASSWORD: "/forgot-password",
  LOGIN: "/login",
  LOGOUT: "/logout",
  NOT_FOUND: "/404",
  RESET_PASSWORD: "/reset-password",
  SETUP_PASSWORD: "/setup-password",
};

export default ROUTES;
