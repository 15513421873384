import clsx from "clsx";
import get from "lodash.get";
import PropTypes from "prop-types";
import React from "react";

const Tbody = ({ children }) => <tbody>{children}</tbody>;

const Td = ({ className, children, bgClassName, colSpan, ...props }) => (
  <td colSpan={colSpan} className={clsx("px-3 py-4", className, bgClassName)} {...props}>
    {children}
  </td>
);

const Thead = ({ columns, children, className }) => {
  if (children) {
    return (
      <thead>
        <tr className={className}>{children}</tr>
      </thead>
    );
  }

  return (
    <thead className="border-b border-gray-500">
      <tr>
        {columns.map((column, columnIndex) => (
          <Th key={columnIndex.toString()} className={clsx(column.className)}>
            {column.displayName || column}
            {column.isRequired && <span className="text-red-900">&nbsp;*</span>}
          </Th>
        ))}
      </tr>
    </thead>
  );
};

const Th = ({ className, children, colSpan }) => (
  <th
    colSpan={colSpan}
    className={clsx("font-normal text-sm text-left pb-2 px-3 align-text-top", className)}
  >
    {children}
  </th>
);

const Tr = ({ className, children, onClick, isStriped }) => (
  <tr className={clsx(className, isStriped && "bg-gray-100")} onClick={onClick}>
    {children}
  </tr>
);

const Table = ({
  children,
  className,
  columns,
  isStriped,
  onRowClick,
  renderData,
  rows,
  ...props
}) => {
  const handleRowClick = (row) => onRowClick && onRowClick(row);

  if (children) {
    return <table className={clsx("w-full", className)}>{children}</table>;
  }

  return (
    <table className={clsx("w-full", className)} {...props}>
      <Thead columns={columns} />
      <Tbody>
        {rows.map((row, rowIndex) => (
          <Tr
            key={rowIndex.toString()}
            onClick={() => handleRowClick(row)}
            className={clsx("border-b border-gray-500", {
              "cursor-pointer": onRowClick,
              "bg-gray-100": isStriped && rowIndex % 2 !== 0,
            })}
          >
            {columns.map((column, columnIndex) => {
              const { path, paths, formatter } = column;
              const data = get(row, path) || (paths && paths.map((item) => get(row, item)));
              const value = formatter && data ? formatter(data) : data || column.defaultValue;
              const result = renderData
                ? renderData({ row, rowIndex, column, columnIndex, value, data })
                : value;

              return (
                <Td
                  key={columnIndex.toString()}
                  className={clsx(
                    column.className,
                    column.dataClassName,
                    column.getDataClassName && column.getDataClassName(data)
                  )}
                >
                  {result}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </table>
  );
};

Table.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  columns: PropTypes.array,
  isStriped: PropTypes.bool,
  onRowClick: PropTypes.func,
  renderData: PropTypes.func,
  rows: PropTypes.array,
};

Table.defaultProps = {
  children: undefined,
  className: undefined,
  columns: [],
  isStriped: false,
  onRowClick: undefined,
  renderData: undefined,
  rows: [],
};

Tbody.propTypes = {
  children: PropTypes.node,
};

Tbody.defaultProps = {
  children: undefined,
};

Thead.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  columns: PropTypes.array,
};

Thead.defaultProps = {
  children: undefined,
  className: undefined,
  columns: [],
};

Tr.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isStriped: PropTypes.bool,
  onClick: PropTypes.func,
};

Tr.defaultProps = {
  children: undefined,
  className: undefined,
  isStriped: undefined,
  onClick: undefined,
};

Td.propTypes = {
  bgClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  className: PropTypes.string,
  colSpan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Td.defaultProps = {
  bgClassName: undefined,
  children: undefined,
  className: undefined,
  colSpan: undefined,
};

Th.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  colSpan: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Th.defaultProps = {
  children: undefined,
  className: undefined,
  colSpan: undefined,
};

Table.Body = Tbody;
Table.Data = Td;
Table.Head = Thead;
Table.Header = Th;
Table.Row = Tr;

export default Table;
