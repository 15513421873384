import { ACTIONS } from "../../constants";
import { fullNameFormatter } from "../../formatters";
import { commaSeparate } from "../../helpers";

export const dormantNatureOfBusinesses = [
  "7499 - Non-trading company",
  "9999 - Dormant company",
  "74990 - Non-trading company",
  "99999 - Dormant Company",
];

export const companyColumns = [
  {
    path: "company_name",
    displayName: "Company name",
    className: "truncate max-w-3xs",
  },
  {
    path: "company_number",
    displayName: "Company number",
    className: "w-40",
  },
  {
    paths: [
      "reg_address_address_line_1",
      "reg_address_address_line_2",
      "reg_address_post_code",
      "reg_address_post_town",
      "country_of_origin",
    ],
    displayName: "Address",
    className: "truncate max-w-3xs",
    formatter: commaSeparate,
  },
  {
    path: "sic_code_sic_text_1",
    displayName: "Nature of business",
    className: "truncate max-w-3xs",
  },
  {
    path: "company_status",
    displayName: "Company status",
    permissions: ACTIONS.VIEW_COMPANY_STATUS_COLUMN,
  },
  {
    id: "action",
    displayName: "Action",
    className: "w-40",
    permissions: ACTIONS.EDIT_INSURED,
  },
];

export const individualColumns = [
  {
    paths: ["firstName", "lastName"],
    displayName: "Full name",
    formatter: fullNameFormatter,
    className: "truncate max-w-3xs",
  },
  {
    path: "dob",
    displayName: "Date of birth",
    className: "w-40",
  },
  {
    paths: ["line1", "city", "county", "postCode", "country"],
    displayName: "Address",
    className: "truncate max-w-3xs",
    formatter: commaSeparate,
  },
  {
    id: "action",
    displayName: "Action",
    className: "w-40",
    permissions: ACTIONS.EDIT_INSURED,
  },
];
