import get from "lodash.get";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "../../components/Button";
import DescriptionText from "../../components/DescriptionText";
import H3 from "../../components/H3";
import Table from "../../components/Table";
import { ACTIONS } from "../../constants";
import useFormContext from "../../context/Form";
import { useAuth, useModal } from "../../hooks";
import AddressModal from "../../modals/AddressModal";
import LocationRemovalWarningModal from "../../modals/LocationRemovalWarningModal";
import { columns } from "./config";
import { locationsSchema } from "./schema";

const LocationsScheduleTable = ({
  businessType,
  contractData,
  formattedTiv,
  isCommercial,
  locations,
  refData,
  setLocations,
}) => {
  const { isUnderwriter, checkPermissions } = useAuth(contractData);
  const { modal, showModal, closeModal } = useModal();
  const [selectedLocation, setSelectedLocation] = useState({});
  const isEditingLocation = Boolean(selectedLocation.id);
  const isCompany = Boolean(get(contractData, "insured.Company"));
  const formContext = useFormContext();

  const castLocations = locationsSchema.cast(locations, { context: { isCompany } });

  const hasSomeLocations = locations.length >= 1;
  const hasMoreThanOneLocation = locations.length > 1;

  const commercialLocationTypes = refData.locationType.filter((item) => item !== "Residential");
  const residentialLocationTypes = refData.locationType.filter((item) => item === "Residential");
  const locationTypes = isCommercial ? commercialLocationTypes : residentialLocationTypes;

  const columnsByRole = columns.filter(
    (column) => !(column.permissions && !checkPermissions(column.permissions))
  );
  const mappedColumns = columnsByRole.map((item) => {
    if (item.path === "businessInterruption") {
      if (isCommercial) {
        return { ...item, displayName: "Business Interruption (BI) Value" };
      }

      if (!isCommercial) {
        return { ...item, displayName: "Rental Income Value" };
      }
    }

    return item;
  });
  const individualColumns = mappedColumns.filter((item) => item.path !== "businessInterruption");
  const finalColumns = isCompany ? mappedColumns : individualColumns;

  const addLocation = (location) => {
    const id = uuidv4();
    const newLocation = { id, ...location };

    formContext.setValue({ isDirty: true });
    setLocations([...locations, newLocation]);
    setSelectedLocation({});
    closeModal();
  };

  const updateLocation = (updatedLocation) => {
    const updatedLocations = locations.map((location) =>
      location.id === selectedLocation.id ? updatedLocation : location
    );

    formContext.setValue({ isDirty: true });
    setLocations(updatedLocations);
    setSelectedLocation({});
    closeModal();
  };

  const deleteLocation = (id) => {
    const filteredLocations = locations.filter((item) => item.id !== id);

    formContext.setValue({ isDirty: true });
    setLocations(filteredLocations);
    setSelectedLocation({});
    closeModal();
  };

  const handleAddLocationClick = () => {
    setSelectedLocation({});
    showModal("AddressModal");
  };

  const handleEditLocationClick = (row) => {
    setSelectedLocation(row);
    showModal("AddressModal");
  };

  const handleDeleteLocationClick = (row) => {
    setSelectedLocation(row);

    if (isUnderwriter) {
      return showModal("LocationRemovalWarningModal");
    }

    return deleteLocation(row.id);
  };

  const renderData = ({ row, column, value }) => {
    if (column.id === "action") {
      return (
        <div className="flex">
          {checkPermissions(ACTIONS.EDIT_LOCATIONS) && (
            <Button
              className="-my-2 mr-3 pl-4 pr-4 xl:px-8"
              kind="secondary"
              onClick={() => handleEditLocationClick(row)}
              size="small"
            >
              Edit
            </Button>
          )}

          {(checkPermissions(ACTIONS.DELETE_ALL_LOCATIONS) ||
            (checkPermissions(ACTIONS.DELETE_LOCATIONS) && hasMoreThanOneLocation)) && (
            <Button
              className="-my-2 pl-4 pr-4 xl:px-8"
              kind="danger-secondary"
              onClick={() => handleDeleteLocationClick(row)}
              size="small"
            >
              Delete
            </Button>
          )}
        </div>
      );
    }

    return value;
  };

  return (
    <>
      {modal === "AddressModal" && (
        <AddressModal
          businessType={businessType}
          handleClose={closeModal}
          handleConfirm={isEditingLocation ? updateLocation : addLocation}
          initialValues={selectedLocation}
          isCommercial={isCommercial}
          isCompany={isCompany}
          isEditingLocation={isEditingLocation}
          locationTypes={locationTypes}
        />
      )}

      {modal === "LocationRemovalWarningModal" && (
        <LocationRemovalWarningModal
          handleClose={closeModal}
          handleConfirm={() => deleteLocation(selectedLocation.id)}
        />
      )}

      <>
        <H3 className="mb-6" id="locationsTableTitle">
          Locations schedule
        </H3>

        <p className="mb-6" id="locationsTableDescription">
          Please list all properties you would like to insure
        </p>

        {checkPermissions(ACTIONS.EDIT_LOCATIONS) && (
          <Button className="mb-8" kind="primary" onClick={handleAddLocationClick}>
            Add location
          </Button>
        )}

        {checkPermissions(ACTIONS.DELETE_LOCATIONS) &&
          !checkPermissions(ACTIONS.DELETE_ALL_LOCATIONS) && (
            <p className="mb-6">Please note, you cannot remove all locations.</p>
          )}

        <Table
          aria-labelledby="locationsTableTitle"
          aria-describedby="locationsTableDescription"
          columns={finalColumns}
          rows={castLocations}
          renderData={renderData}
          isStriped
        />

        {hasSomeLocations && (
          <div className="mb-8">
            <Table className="max-w-sm font-medium">
              <Table.Body>
                <Table.Row>
                  <Table.Data className="w-48">Total insurable value:</Table.Data>
                  <Table.Data className="w-48" aria-label="Total insurable value">
                    {formattedTiv}
                  </Table.Data>
                </Table.Row>
              </Table.Body>
            </Table>

            <DescriptionText className="px-3 -mt-3">
              This is the sum of the Property Damage and{" "}
              {isCommercial ? "Business Interruption" : "Rental Income"} values from the Location
              Schedule
            </DescriptionText>
          </div>
        )}

        {!hasSomeLocations && (
          <div className="text-center mt-20">
            <p className="text-2xl font-medium mb-4">No locations to show</p>
            <p className="mb-6">Add a new location to get started.</p>
          </div>
        )}
      </>
    </>
  );
};

LocationsScheduleTable.propTypes = {
  businessType: PropTypes.string,
  contractData: PropTypes.object,
  formattedTiv: PropTypes.string,
  isCommercial: PropTypes.bool,
  locations: PropTypes.array,
  refData: PropTypes.object,
  setLocations: PropTypes.func,
};

LocationsScheduleTable.defaultProps = {
  businessType: undefined,
  contractData: undefined,
  formattedTiv: undefined,
  isCommercial: undefined,
  locations: undefined,
  refData: undefined,
  setLocations: undefined,
};

export default LocationsScheduleTable;
