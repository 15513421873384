import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import Input from "./Input";
import InputField from "./InputField";
import InputReadOnly from "./InputReadOnly";

const InputPercentage = ({
  className,
  descriptionText,
  errors,
  id,
  isReadOnly,
  labelText,
  name,
  onChange,
  value,
  ...props
}) => {
  const handleChange = ({ floatValue }) => {
    if (onChange) onChange(floatValue, name);
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      labelText={labelText}
      {...props}
    >
      {!isReadOnly && (
        <div className="relative">
          <NumberFormat
            allowNegative={false}
            className="pr-20 text-right"
            customInput={Input}
            decimalScale={0}
            errors={errors}
            id={id || name}
            isNumericString
            name={name}
            onValueChange={handleChange}
            value={value}
            {...props}
          />
          <div
            className={clsx(
              "absolute inset-y-0 right-0 bg-gray-100 border-l flex items-center justify-center px-3 my-px mr-px w-12 rounded-r",
              !errors && "border-gray-700",
              errors && "border-red-900"
            )}
          >
            %
          </div>
        </div>
      )}
      {isReadOnly && <InputReadOnly {...props} defaultValue={value} />}
    </InputField>
  );
};

InputPercentage.propTypes = {
  className: PropTypes.string,
  descriptionText: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  isReadOnly: PropTypes.bool,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

InputPercentage.defaultProps = {
  className: undefined,
  descriptionText: undefined,
  errors: undefined,
  id: undefined,
  isReadOnly: undefined,
  labelText: undefined,
  name: undefined,
  onChange: undefined,
  value: undefined,
};

export default InputPercentage;
