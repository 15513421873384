import { Auth } from "aws-amplify";
import axios from "axios";
import get from "lodash.get";
import { queryCache } from "react-query";
import { ENDPOINTS } from "../constants";

const fetch = axios.create({ baseURL: process.env.API_URL });
const fetchIdentity = axios.create({ baseURL: process.env.IDENTITY_API_URL });
const fetchIndividuals = axios.create({ baseURL: process.env.INSURED_INDIVIDUALS_API });
const fetchCompanies = axios.create({ baseURL: process.env.COMPANIES_HOUSE_API_URL });

const requestInterceptor = (config) => {
  const userData = queryCache.getQueryData("user");
  const token = get(userData, "signInUserSession.idToken.jwtToken");

  return { ...config, headers: { Authorization: token } };
};

const responseInterceptor = async (error) => {
  if (get(error, "response.status") === 401) {
    await Auth.currentSession();
    const amplifyUser = await Auth.currentAuthenticatedUser();
    const token = get(amplifyUser, "signInUserSession.idToken.jwtToken");
    const prevConfig = get(error, "response.config");

    queryCache.setQueryData("user", amplifyUser);

    return axios({ ...prevConfig, headers: { ...prevConfig.headers, Authorization: token } });
  }

  return Promise.reject(error);
};

fetch.interceptors.request.use(requestInterceptor);
fetch.interceptors.response.use(null, responseInterceptor);

fetchIdentity.interceptors.request.use(requestInterceptor);
fetchIdentity.interceptors.response.use(null, responseInterceptor);

fetchIndividuals.interceptors.request.use(requestInterceptor);
fetchIndividuals.interceptors.response.use(null, responseInterceptor);

fetchCompanies.interceptors.request.use(requestInterceptor);
fetchCompanies.interceptors.response.use(null, responseInterceptor);

/* Identity */

export const getUsers = () => fetchIdentity({ method: "get", url: ENDPOINTS.USERS });

export const getTenants = () => fetchIdentity({ method: "get", url: ENDPOINTS.TENANTS });

/* Individuals */

export const createIndividual = ({ data }) =>
  fetchIndividuals({ method: "post", url: ENDPOINTS.INDIVIDUALS, data });

export const getIndividuals = (key, params) =>
  fetchIndividuals({ method: "get", url: ENDPOINTS.INDIVIDUALS, params });

/* Companies */

export const getCompanies = (key, { companyName }) =>
  fetchCompanies({
    method: "get",
    params: { q: companyName, page: 1, perPage: 10, sort: "company_name", order: "desc" },
    url: ENDPOINTS.COMPANIES,
  });

/* Ref Data */

export const getRefData = () => fetch({ method: "get", url: ENDPOINTS.REF_DATA });

/* Attachments */

export const uploadContractAttachment = ({ contractId, data }) =>
  fetch({ method: "post", url: ENDPOINTS.ATTACHMENT({ contractId }), data });

export const getContractAttachments = (key, { contractId }) =>
  fetch({
    method: "get",
    params: { documentType: "attachment" },
    url: ENDPOINTS.ATTACHMENT({ contractId }),
  });

export const getContractAttachmentLink = ({ contractId, attachmentId }) =>
  fetch({ method: "get", url: ENDPOINTS.ATTACHMENT_LINK({ contractId, attachmentId }) });

export const getQuoteLink = (key, { contractId, priceId }) =>
  fetch({ method: "get", url: ENDPOINTS.QUOTE_LINK({ contractId, priceId }) });

export const getQuoteDocuments = (key, { contractId }) =>
  fetch({
    method: "get",
    params: { documentType: "quote" },
    url: ENDPOINTS.ATTACHMENT({ contractId }),
  });

export const getPolicyDocuments = async (key, { contractId, numDocs }) => {
  try {
    const res = await fetch({
      method: "get",
      params: { documentType: "policy" },
      url: ENDPOINTS.ATTACHMENT({ contractId }),
    });

    const policyDocumentsData = get(res, "data.documents", []);
    const hasPolicyDocs = policyDocumentsData.length >= numDocs;

    if (!hasPolicyDocs) {
      throw new Error();
    }

    return res;
  } catch (error) {
    throw new Error(error);
  }
};

/* Contracts */

export const getContract = (key, { contractId }) =>
  fetch({ method: "get", url: ENDPOINTS.CONTRACT({ contractId }) });

export const getContractHistory = (key, { contractId }) =>
  fetch({ method: "get", url: ENDPOINTS.GROUPED_CONTRACT_HISTORY({ contractId }) });

export const getContractPatches = (key, { contractId }) =>
  fetch({ method: "get", url: ENDPOINTS.GROUPED_CONTRACT_PATCHES({ contractId }) });

export const getContracts = (key, params) =>
  fetch({ method: "get", url: ENDPOINTS.CONTRACTS, params });

export const createContract = ({ data }) =>
  fetch({ method: "post", url: ENDPOINTS.CONTRACTS, data });

export const updateContract = ({ data, contractId }) =>
  fetch({ method: "put", url: ENDPOINTS.CONTRACT({ contractId }), data });

export const getContractPremiums = (key, { contractId }) =>
  fetch({ method: "get", url: ENDPOINTS.CONTRACT_PREMIUMS({ contractId }) });

export const getContractAddtlPremiums = (key, { data, contractId }) =>
  fetch({ method: "post", url: ENDPOINTS.CONTRACT_ADDTL_PREMIUMS({ contractId }), data });

export const updateContractClaimFlag = ({ data, contractId }) =>
  fetch({ method: "post", url: ENDPOINTS.CONTRACT_FLAG_CLAIM({ contractId }), data });

export const updateContractPremiumPaidFlag = ({ data, contractId }) =>
  fetch({ method: "post", url: ENDPOINTS.CONTRACT_FLAG_PREMIUM_PAID({ contractId }), data });

/* Quotes */

export const updateQuote = ({ data, contractId, quoteId }) =>
  fetch({ method: "put", url: ENDPOINTS.QUOTE({ contractId, quoteId }), data });

export const createQuote = ({ data, contractId }) =>
  fetch({ method: "post", url: ENDPOINTS.QUOTES({ contractId }), data });

/* Endorsements */

export const getEndorsement = (key, { contractId }) =>
  fetch({ method: "get", url: ENDPOINTS.ENDORSEMENT({ contractId }) });

export const updateEndorsement = ({ data, contractId }) =>
  fetch({ method: "put", url: ENDPOINTS.ENDORSEMENT({ contractId }), data });

export const createEndorsement = ({ data, contractId }) =>
  fetch({ method: "post", url: ENDPOINTS.ENDORSEMENT({ contractId }), data });

export const createEndoQuote = ({ data, contractId }) =>
  fetch({ method: "post", url: ENDPOINTS.ENDORSEMENT_QUOTE({ contractId }), data });

export const updateEndoQuote = ({ data, contractId }) =>
  fetch({ method: "put", url: ENDPOINTS.ENDORSEMENT_QUOTE({ contractId }), data });

/* Outcode Exposures */

export const getOutcodeExposures = () => fetch({ method: "get", url: ENDPOINTS.OUTCODE_EXPOSURES });

/* Bordereau */

export const getBordereau = ({ month, year }) =>
  fetch({ method: "get", url: ENDPOINTS.BORDEREAU({ month, year }) });
