import { Auth } from "aws-amplify";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";
import Banner from "../../components/Banner";
import Button from "../../components/Button";
import Form from "../../components/Form";
import InputText from "../../components/InputText";
import { ROUTES } from "../../constants";
import { useForm } from "../../hooks";
import AuthLayout from "./AuthLayout";

const mapFormError = (message) => {
  if (message === "Temporary password has expired and must be reset by an administrator.") {
    return {
      headingText: "Password Expired",
      text:
        "Your temporary password has expired. Please contact an administrator to request a new password.",
    };
  }

  return { headingText: message };
};

const Login = () => {
  const { push } = useHistory();
  const { getFieldProps, canSubmit, onSubmit, formError } = useForm({
    initialState: { email: "", password: "" },
    onSubmit: async (formValues) => {
      const user = await Auth.signIn(formValues.email.trim(), formValues.password);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        return push(ROUTES.SETUP_PASSWORD, formValues);
      }
      window.showZe();

      return push(ROUTES.HOME);
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(),
      password: yup.string().required(),
    }),
  });

  return (
    <AuthLayout>
      {formError && <Banner className="mb-8" color="red" {...mapFormError(formError)} />}

      <Form onSubmit={onSubmit}>
        <InputText
          className="mb-6"
          labelText="Email"
          placeholder="email@example.com"
          {...getFieldProps("email")}
        />

        <InputText
          className="mb-6"
          labelText="Password"
          link={
            <Link to={ROUTES.FORGOT_PASSWORD} className="text-blue-600 font-medium text-sm">
              Forgot password?
            </Link>
          }
          type="password"
          {...getFieldProps("password")}
        />

        <Button kind="primary" type="submit" isDisabled={!canSubmit}>
          Log In
        </Button>
      </Form>
    </AuthLayout>
  );
};

export default Login;
