import clsx from "clsx";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

const sizes = {
  small: "h-10",
  normal: "h-12",
};

const Input = forwardRef(
  (
    {
      className,
      errors,
      iconName,
      id,
      isDisabled,
      isReadOnly,
      isRequired,
      name,
      placeholderText,
      size,
      ...props
    },
    ref
  ) => (
    <input
      className={clsx(
        "aui-input",
        isDisabled && "aui-input--disabled",
        isReadOnly && "aui-input--readonly",
        errors && "aui-input--errors",
        sizes[size],
        className
      )}
      disabled={isDisabled}
      id={id || name}
      name={name}
      placeholder={placeholderText}
      readOnly={isReadOnly}
      ref={ref}
      required={isRequired}
      {...props}
    />
  )
);

Input.displayName = "Input";

Input.propTypes = {
  className: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.bool]),
  iconName: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  placeholderText: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal"]),
};

Input.defaultProps = {
  className: undefined,
  errors: undefined,
  iconName: undefined,
  id: undefined,
  isDisabled: undefined,
  isReadOnly: undefined,
  isRequired: undefined,
  name: undefined,
  placeholderText: undefined,
  size: "normal",
};

export default Input;
