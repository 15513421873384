import get from "lodash.get";
import moment from "moment";
import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import Form from "../../components/Form";
import H3 from "../../components/H3";
import InputCalendar from "../../components/InputCalendar";
import InputSelect from "../../components/InputSelect";
import DescriptionText from "../../components/DescriptionText";
import { ACTIONS, ROUTES } from "../../constants";
import useFormContext from "../../context/Form";
import { useAuth, useContractQuery } from "../../hooks";
import { coverRequirementsSchema } from "./schema";

const ContractStepperRequirements = ({ refData, contractData }) => {
  const history = useHistory();
  const { contractId, contractType } = useParams();
  const { checkPermissions } = useAuth(contractData);
  const formContext = useFormContext();

  const today = new Date();
  const minDate = new Date(today);
  const maxDate = new Date(today);

  minDate.setDate(minDate.getDate() - 30);
  minDate.setHours(0, 0, 0, 0);
  maxDate.setDate(maxDate.getDate() + 30);

  const isIndividual = Boolean(get(contractData, "insured.Individual"));
  const isCompany = Boolean(get(contractData, "insured.Company"));
  const isCommercial = get(contractData, "product.coverDetails.businessType") === "Commercial";
  const locationsSchedule = get(contractData, "product.locationsSchedule", []);
  const hasSomeBi = locationsSchedule.some((item) => get(item, "businessInterruption.amount"));
  const existingInceptionIsoDate = get(contractData, "period.start");
  const existingInceptionDate = new Date(existingInceptionIsoDate);

  const { handleSubmitContract, isSubmittingContract } = useContractQuery();

  const [values, setValues] = useState({
    claimBasis: get(contractData, "product.coverDetails.claimBasis"),
    inceptionDate: existingInceptionIsoDate ? existingInceptionDate : undefined,
    indemnityPeriod: get(contractData, "product.indemnityPeriod"),
    policyTerm: get(contractData, "product.policyTerm", refData.policyTerm[0]),
  });

  const prevStepPath = compile(ROUTES.CONTRACT_STEPPER_LOCATIONS)({ contractId, contractType });
  const nextStepPath = compile(ROUTES.CONTRACT_STEPPER_SUBLIMITS)({ contractId, contractType });

  const isCoverRequirementsFormValid = coverRequirementsSchema.isValidSync(values, {
    context: { hasSomeBi },
  });

  const handleFormChange = (value, name) => {
    formContext.setValue({ isDirty: true });
    setValues({ ...values, [name]: value });
  };

  const handleInceptionDateChange = (date) => {
    formContext.setValue({ isDirty: true });
    setValues({ ...values, inceptionDate: date });
  };

  const handleFormSubmit = async () => {
    const castFormValues = coverRequirementsSchema.cast(values);
    const inceptionDate = moment.utc(castFormValues.inceptionDate, "DD/MM/YYYY");

    await handleSubmitContract({ ...castFormValues, inceptionDate }, contractData);

    return history.push(nextStepPath, { isForced: true });
  };

  const getBIDescriptionText = () => {
    if (isCompany && !hasSomeBi) {
      return (
        <span>
          {isCommercial ? "Business interruption" : "Rental income"} values were not added in the
          locations schedule.{" "}
          <Link to={prevStepPath} className="text-blue-600">
            Go back
          </Link>
        </span>
      );
    }

    return (
      <span>
        The maximum length of time for which the policy will indemnify the business following an
        insured event causing an interruption to the business.
      </span>
    );
  };

  return (
    <div className="w-160">
      <Form id="coverRequirementsForm" onSubmit={handleFormSubmit} hasContext>
        <H3 className="mb-6">Policy date and term</H3>

        <InputCalendar
          className="mb-6"
          descriptionText="Policy must start +/- 30 days from today's date"
          isReadOnly={!checkPermissions(ACTIONS.EDIT_INCEPTION_DATE)}
          labelText="Inception date"
          dayPickerProps={{ disabledDays: [{ after: maxDate, before: minDate }] }}
          name="inceptionDate"
          onChange={handleInceptionDateChange}
          placeholder="DD/MM/YYYY"
          value={values.inceptionDate}
        />

        <InputSelect
          className="mb-6"
          initialSelectedItem={values.policyTerm}
          isReadOnly
          items={refData.policyTerm}
          labelText="Policy term"
          name="policyTerm"
          onSelect={handleFormChange}
        />

        <InputSelect
          className="mb-6"
          descriptionText={getBIDescriptionText()}
          initialSelectedItem={values.indemnityPeriod}
          isReadOnly={
            isIndividual ||
            (isCompany && !hasSomeBi) ||
            !checkPermissions(ACTIONS.EDIT_INDEMNITY_PERIOD)
          }
          items={refData.indemnityPeriod}
          labelText={
            isCommercial
              ? "Business interruption indemnity period"
              : "Rental income indemnity period"
          }
          name="indemnityPeriod"
          onSelect={handleFormChange}
        />

        <InputSelect
          className="mb-6"
          initialSelectedItem={values.claimBasis}
          isReadOnly={!checkPermissions(ACTIONS.EDIT_CLAIM_BASIS)}
          items={refData.claimBasis}
          labelText="Claim basis"
          name="claimBasis"
          onSelect={handleFormChange}
        />

        <div className="max-w-xl mb-6 -mt-2">
          <DescriptionText className="mb-3">
            Occurrence (OCC): The maximum limit of indemnity any one occurrence that the insurer
            will pay out each and every claim. All expenses are included in and not in addition to
            that limit.
          </DescriptionText>
          <DescriptionText className="mb-3">
            Aggregate (AGG): The aggregate limit of indemnity is the total amount that the insurer
            will pay out over the policy term in respect of all claims. All expenses are included in
            and not in addition to that limit and, once the limit of indemnity has been reached,
            then you cannot make further claims.{" "}
          </DescriptionText>
        </div>

        <Footer>
          <div className="flex">
            <Button as={Link} to={ROUTES.HOME} className="mr-4">
              Exit
            </Button>

            <Button as={Link} to={prevStepPath}>
              Go back
            </Button>
          </div>

          {((!formContext.isDirty && !isCoverRequirementsFormValid) || formContext.isDirty) && (
            <Button
              isDisabled={!isCoverRequirementsFormValid || isSubmittingContract}
              kind="primary"
              type="submit"
            >
              Save
            </Button>
          )}

          {!formContext.isDirty && isCoverRequirementsFormValid && (
            <Button as={Link} to={nextStepPath} kind="primary">
              Continue
            </Button>
          )}
        </Footer>
      </Form>
    </div>
  );
};

ContractStepperRequirements.propTypes = {
  contractData: PropTypes.object,
  refData: PropTypes.object,
};

ContractStepperRequirements.defaultProps = {
  contractData: undefined,
  refData: undefined,
};

export default ContractStepperRequirements;
