/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import Icon from "../../components/Icon";
import Logo from "../../components/Logo";
import { ACTIONS, ROUTES } from "../../constants";
import { useAuth } from "../../hooks";

const NavItem = ({ children, isActive, to }) => (
  <Link
    to={to}
    className={clsx(
      "ml-6 px-5",
      "font-medium text-sm",
      "flex items-center justify-center",
      "border-b-4 border-transparent",
      isActive && "bg-gray-1000 text-white border-blue-600"
    )}
  >
    {children}
  </Link>
);

const NavBar = () => {
  const { user, tenant, checkPermissions } = useAuth();
  const [isDropdownVisible, setIsDropdownVisible] = useState();
  const dropdownButtonRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const isNewQuote = Boolean(useRouteMatch(ROUTES.NEW_QUOTE));
  const isQuotes = Boolean(useRouteMatch(ROUTES.QUOTES));
  const isPolicies = Boolean(useRouteMatch(ROUTES.POLICIES));
  const isReferrals = Boolean(useRouteMatch(ROUTES.REFERRALS));
  const isOutcodes = Boolean(useRouteMatch(ROUTES.OUTCODE_EXPOSURES));

  const handleOutsideClick = (event) => {
    const { target } = event;
    const buttonNode = dropdownButtonRef.current;
    const menuNode = dropdownMenuRef.current;

    if (buttonNode && !buttonNode.contains(target) && menuNode && !menuNode.contains(target)) {
      setIsDropdownVisible(false);
    }
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  });

  return (
    <div className="h-20 bg-black px-8 flex justify-between items-center">
      <div className="flex items-center">
        <Link to={ROUTES.HOME}>
          {process.env.ENVIRONMENT !== "uat" && <Logo />}
          {process.env.ENVIRONMENT === "uat" && <p className="text-white font-medium ml-6">Demo</p>}
        </Link>
        <div className="flex items-center">
          {process.env.ENVIRONMENT !== "uat" && (
            <p className="text-white font-medium ml-6">UK Terrorism</p>
          )}
          <p className="text-sm text-gray-500 font-medium ml-6">
            {process.env.ENVIRONMENT !== "production" && process.env.npm_package_version}
          </p>
        </div>
      </div>
      <div className="self-stretch flex">
        <div className="flex text-gray-500">
          {checkPermissions(ACTIONS.VIEW_REFERRALS_TAB) && (
            <NavItem to={ROUTES.REFERRALS_INDEX} isActive={isReferrals}>
              Referrals
            </NavItem>
          )}

          {checkPermissions(ACTIONS.VIEW_QUOTES_TAB) && (
            <NavItem to={ROUTES.QUOTES_INDEX} isActive={isQuotes || isNewQuote}>
              Quotes
            </NavItem>
          )}

          {checkPermissions(ACTIONS.VIEW_POLICIES_TAB) && (
            <NavItem to={ROUTES.POLICIES_INDEX} isActive={isPolicies}>
              Policies
            </NavItem>
          )}

          {checkPermissions(ACTIONS.VIEW_OUTCODES_TAB) && (
            <NavItem to={ROUTES.OUTCODE_EXPOSURES} isActive={isOutcodes}>
              Outcodes
            </NavItem>
          )}
        </div>
        <div className="ml-6 relative self-stretch flex">
          <div
            className={clsx(
              "flex items-center cursor-pointer p-4",
              isDropdownVisible && "bg-gray-1000"
            )}
            onClick={handleDropdownClick}
            ref={dropdownButtonRef}
          >
            <div>
              <p className="text-gray-600 text-sm">{tenant.name}</p>
              <p className="text-gray-300 text-sm font-medium">{user.fullName}</p>
            </div>
            <Icon name="chevron-down" className="fill-current text-white ml-8" />
          </div>

          {isDropdownVisible && (
            <div className="mt-20 absolute bg-white left-0 right-0 z-10" ref={dropdownMenuRef}>
              <Link to={ROUTES.LOGOUT}>
                <div className="h-12 border border-gray-500 rounded-b-sm flex items-center p-4 text-sm font-medium shadow-md">
                  <Icon name="logout" className="fill-current text-gray-800 mr-4" />
                  Log Out
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  isActive: PropTypes.bool,
  to: PropTypes.string,
};

NavItem.defaultProps = {
  children: undefined,
  isActive: undefined,
  to: undefined,
};

export default NavBar;
