import get from "lodash.get";
import set from "lodash.set";
import PropTypes from "prop-types";
import React from "react";
import Accordion from "../../components/Accordion";
import CustomTag from "../../components/CustomTag";
import Table from "../../components/Table";
import TableDataPoint from "../../components/TableDataPoint";
import { coverTypeFormatter, currencyFormatter, dateFormatter } from "../../formatters";
import { checkUpdated, prepareData } from "../../helpers";
import { useAuth } from "../../hooks";

const getDataPoints = (contractData) => {
  const isCommercial = get(contractData, "product.coverDetails.businessType") === "Commercial";

  return {
    coverType: {
      formatter: coverTypeFormatter({ isCommercial }),
      labelText: "Cover type",
      path: "/product/coverType",
    },
    creationDate: {
      formatter: dateFormatter,
      labelText: "Creation date",
      path: "/createdAt",
    },
    businessType: {
      labelText: "Property type",
      path: "/product/coverDetails/businessType",
    },
    tiv: {
      formatter: currencyFormatter,
      labelText: "TIV",
      path: [
        "/product/aggregateValues/totalIV/amount",
        "/product/aggregateValues/totalIV/currency",
      ],
    },
    inceptionDate: {
      formatter: dateFormatter,
      labelText: "Inception date",
      path: "/period/start",
    },
    claimBasis: {
      labelText: "Claim basis",
      path: "/product/coverDetails/claimBasis",
    },
    biIndemnityPeriod: {
      defaultContent: "N/A",
      labelText: `${isCommercial ? "Business interruption" : "Rental income"} indemnity period`,
      path: "/product/indemnityPeriod",
    },
    policyTerm: {
      labelText: "Policy term",
      path: "/product/policyTerm",
    },
    policyLimit: {
      formatter: currencyFormatter,
      labelText: "Policy limit",
      path: ["/policyLimit/amount", "/policyLimit/currency"],
    },
  };
};

const CoverageSummary = ({ contractData, patchData, showDiff, ...props }) => {
  const { checkPermissions } = useAuth(contractData);
  const dataPoints = getDataPoints(contractData);
  const isUpdated = showDiff && checkUpdated(dataPoints, contractData, patchData);
  const data = prepareData({ dataPoints, contractData, patchData, checkPermissions, showDiff });
  const locationsSchedule = get(contractData, "product.locationsSchedule", []);
  const hasSomeBi = locationsSchedule.some((item) => get(item, "businessInterruption.amount"));
  const hasSomePd = locationsSchedule.some((item) => get(item, "propertyDamage.amount"));

  if (!hasSomeBi && hasSomePd) {
    set(data, "biIndemnityPeriod.children", "n/a");
  }

  return (
    <Accordion.Panel
      {...props}
      headingText="Coverage summary"
      tag={isUpdated && <CustomTag kind="updated" />}
    >
      <Table>
        <Table.Body>
          <TableDataPoint {...data.coverType} />
          <TableDataPoint {...data.creationDate} isStriped />
          <TableDataPoint {...data.inceptionDate} />
          <TableDataPoint {...data.policyTerm} isStriped />
          <TableDataPoint {...data.businessType} />
          <TableDataPoint {...data.claimBasis} isStriped />
          <TableDataPoint {...data.policyLimit} />
          <TableDataPoint {...data.tiv} isStriped />
          <TableDataPoint {...data.biIndemnityPeriod} />
        </Table.Body>
      </Table>
    </Accordion.Panel>
  );
};

CoverageSummary.propTypes = {
  contractData: PropTypes.object.isRequired,
  patchData: PropTypes.array,
  showDiff: PropTypes.bool,
};

CoverageSummary.defaultProps = {
  patchData: [],
  showDiff: undefined,
};

export default CoverageSummary;
