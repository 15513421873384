export const STATUSES = {
  ACTIVE_CLAIM: "ActiveClaim",
  BOUND: "Bound",
  CANCELLED: "Cancelled",
  DECLINE_TO_QUOTE: "DeclineToQuote",
  ENDORSEMENT_APPLIED: "EndorsementApplied",
  ENDORSEMENT_DECLINE_TO_QUOTE: "EndorsementDeclineToQuote",
  ENDORSEMENT_NOT_TAKEN_UP: "EndorsementNotTakenUp",
  ENDORSEMENT_QUOTED: "EndorsementQuoted",
  ENDORSEMENT_REFERRED: "EndorsementReferred",
  ENDORSEMENT_REFERRED_QUOTED: "EndorsementQuotedReferred",
  ENDORSEMENT_SUBMITTED: "EndorsementSubmitted",
  EXPIRED: "Expired",
  INFORCE: "Inforce",
  LAPSED: "Lapsed",
  NEW: "New",
  NOT_TAKEN_UP: "NotTakenUp",
  QUOTED: "Quoted",
  REFERRED: "Referred",
  REFERRED_QUOTED: "QuotedReferred",
  SUBMITTED: "Submitted",
  WILL_BE_REFERRED: "WillBeReferred",
};

export default STATUSES;
