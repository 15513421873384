import PropTypes from "prop-types";
import React, { createContext, useContext, useState } from "react";

const FormContext = createContext();

export const FormContextProvider = ({ children }) => {
  const [value, setValue] = useState({ isDirty: false });

  return <FormContext.Provider value={{ ...value, setValue }}>{children}</FormContext.Provider>;
};

const useFormContext = () => useContext(FormContext);

FormContextProvider.propTypes = {
  children: PropTypes.node,
};

FormContextProvider.defaultProps = {
  children: undefined,
};

export default useFormContext;
