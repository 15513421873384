import get from "lodash.get";
import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React from "react";
import { Link, useParams } from "react-router-dom";
import Button from "../../components/Button";
import H3 from "../../components/H3";
import { ACTIONS, ROUTES, STATUSES } from "../../constants";
import { useAuth, useModal } from "../../hooks";
import AmendPolicyModal from "../../modals/AmendPolicyModal";
import CancelPolicyModal from "../../modals/CancelPolicyModal";
import Timeline from "../Timeline";

const ContractEndorsements = ({ contractHistData, contractPatchData, refData, contractData }) => {
  const { modal, showModal, closeModal } = useModal();
  const { contractId } = useParams();
  const { checkPermissions } = useAuth(contractData);
  const { status, cancellationDate } = contractData;
  const hasHistory = Object.keys(contractHistData).length > 1;
  const isBound = status === STATUSES.BOUND;
  const isInforce = status === STATUSES.INFORCE;
  const willBeCancelled = Boolean((isBound || isInforce) && cancellationDate);
  const hasActiveClaim = get(contractData, "claimed");

  return (
    <>
      {modal === "CancelPolicyModal" && (
        <CancelPolicyModal handleClose={closeModal} contractData={contractData} refData={refData} />
      )}

      {modal === "AmendPolicyModal" && (
        <AmendPolicyModal handleClose={closeModal} contractData={contractData} />
      )}

      {checkPermissions(ACTIONS.CANCEL_POLICY) && !willBeCancelled && !hasActiveClaim && (
        <div className="mb-8">
          <H3 className="mb-8">Create endorsement</H3>

          <div className="flex">
            {checkPermissions(ACTIONS.CREATE_ENDORSEMENT) && (
              <Button onClick={() => showModal("AmendPolicyModal")} className="mr-4">
                Create endorsement
              </Button>
            )}

            {checkPermissions(ACTIONS.EDIT_ENDORSEMENT) && (
              <Button
                as={Link}
                to={compile(ROUTES.CONTRACT_STEPPER)({ contractId, contractType: "endorsements" })}
              >
                Edit endorsement
              </Button>
            )}

            {!hasActiveClaim && checkPermissions(ACTIONS.CANCEL_POLICY) && (
              <Button kind="danger-secondary" onClick={() => showModal("CancelPolicyModal")}>
                Cancel policy
              </Button>
            )}
          </div>
        </div>
      )}

      {hasHistory && (
        <Timeline
          contractHistData={contractHistData}
          contractPatchData={contractPatchData}
          isEndorsement
          refData={refData}
        />
      )}
    </>
  );
};

ContractEndorsements.propTypes = {
  contractData: PropTypes.object.isRequired,
  contractPatchData: PropTypes.object.isRequired,
  contractHistData: PropTypes.object.isRequired,
  refData: PropTypes.object.isRequired,
};

export default ContractEndorsements;
