import * as Sentry from "@sentry/browser";
import React from "react";
import { render } from "react-dom";
import App from "./modules/App";
import "./styles.css";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.ENVIRONMENT,
  release: `chaucer-spa@${process.env.npm_package_version}`,
});

render(<App />, document.getElementById("root"));
