import PropTypes from "prop-types";
import React from "react";
import Button from "../components/Button";
import Modal from "../components/Modal";

const LocationRemovalWarningModal = ({ handleClose, handleConfirm }) => (
  <Modal handleClose={handleClose} headingText="Warning: Location removal">
    <div className="p-6 w-160">
      <p className="mb-6">
        If you remove a location, the deductible and sublimits specified in Step 4 will be lost.
      </p>

      <p className="mb-6">
        You will need to contact the broker for the customer’s sublimit and deductible selection.
      </p>

      <p className="mb-6">Are you sure you want to continue?</p>

      <div className="flex">
        <Button onClick={handleClose} className="mr-4">
          No, cancel
        </Button>

        <Button kind="primary" onClick={handleConfirm}>
          Yes, continue
        </Button>
      </div>
    </div>
  </Modal>
);

LocationRemovalWarningModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

export default LocationRemovalWarningModal;
