import get from "lodash.get";
import { match } from "path-to-regexp";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ROUTES, STEPS } from "../../constants";
import useFormContext from "../../context/Form";
import { useModal } from "../../hooks";
import DiscardChangesModal from "../../modals/DiscardChangesModal";
import EditQuoteModal from "../../modals/EditQuoteModal";
import UnsavedChangesModal from "../../modals/UnsavedChangesModal";

const HistoryBlock = ({ children }) => {
  const [nextPath, setNextPath] = useState();
  const unblock = useRef();
  const { modal, showModal, closeModal } = useModal();
  const { step } = useParams();
  const formContext = useFormContext();
  const history = useHistory();

  const handleConfirmDiscard = () => {
    formContext.setValue({ isDirty: false });
    unblock.current();
    history.push(nextPath);
    closeModal();
  };

  const handleConfirmEditQuote = () => {
    unblock.current();
    history.push(nextPath);
    closeModal();
  };

  useEffect(() => {
    unblock.current = history.block((nextLocation) => {
      const matchesStepperRoutes = match(ROUTES.CONTRACT_STEPPER)(nextLocation.pathname);
      const currentStepIndex = Object.values(STEPS).findIndex((item) => item === step);
      const nextStep = get(matchesStepperRoutes, "params.step");
      const nextStepIndex = Object.values(STEPS).findIndex((item) => item === nextStep);
      const isGoingBack = currentStepIndex > nextStepIndex;
      const isForced = get(nextLocation, "state.isForced");

      setNextPath(nextLocation.pathname);

      if (isForced) {
        formContext.setValue({ isDirty: false });

        return true;
      }

      if (formContext.isDirty && !isForced && isGoingBack) {
        showModal("UnsavedChangesModal");

        return false;
      }

      if (formContext.isDirty && !isForced) {
        showModal("DiscardChangesModal");

        return false;
      }

      return true;
    });

    return () => unblock.current();
  }, [formContext, step, history, showModal]);

  return (
    <>
      {modal === "DiscardChangesModal" && (
        <DiscardChangesModal handleClose={closeModal} handleConfirm={handleConfirmDiscard} />
      )}

      {modal === "UnsavedChangesModal" && (
        <UnsavedChangesModal handleClose={closeModal} handleConfirm={handleConfirmDiscard} />
      )}

      {modal === "EditQuoteModal" && (
        <EditQuoteModal handleClose={closeModal} handleConfirm={handleConfirmEditQuote} />
      )}

      {children}
    </>
  );
};

HistoryBlock.propTypes = {
  children: PropTypes.node,
};

HistoryBlock.defaultProps = {
  children: undefined,
};

export default HistoryBlock;
