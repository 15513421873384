import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import moment from "moment";
import { compile } from "path-to-regexp";
import PropTypes from "prop-types";
import React from "react";
import { queryCache } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import Button from "../components/Button";
import Form from "../components/Form";
import InputCalendar from "../components/InputCalendar";
import InputRadio from "../components/InputRadio";
import InputSelect from "../components/InputSelect";
import Modal from "../components/Modal";
import { ROUTES, STATUSES } from "../constants";
import { dateFormatter } from "../formatters";
import { useContractQuery, useForm } from "../hooks";

const CancelPolicyModal = ({ handleClose, contractData, refData }) => {
  const { handleSubmitContract, updateContractQuery } = useContractQuery();
  const history = useHistory();
  const { contractId, contractType } = useParams();

  const { getFieldProps: getChoiceFieldProps, formValues: choiceFormValues } = useForm({
    initialState: { date_choice: undefined },
  });

  const inceptionDate = dateFormatter([contractData.period.start]);
  const isCustomDate = choiceFormValues.date_choice === "choose_date";
  const isInceptionDate = choiceFormValues.date_choice === "inception_date";

  const validationSchema = yup.object().shape({
    cancellationDate: yup
      .date()
      .min(moment.utc(contractData.period.start).subtract(1, "day").startOf("day").toDate())
      .max(moment.utc(contractData.period.end).startOf("day").toDate())
      .when("$isCustomDate", (isCustom, schema) => (isCustom ? schema.required() : schema)),
    cancellation: yup.object().shape({
      reason: yup.string().required(),
    }),
  });

  const handleSubmit = async (formValues) => {
    const newFormValues = JSON.parse(JSON.stringify(formValues));

    if (isInceptionDate) {
      set(newFormValues, "cancellationDate", contractData.period.start);
    }

    set(newFormValues, "status", STATUSES.CANCELLED);

    await handleSubmitContract(newFormValues, contractData);
  };

  const { getFieldProps, isValid, onSubmit, formValues, setFormValues } = useForm({
    initialState: {
      cancellationDate: undefined,
      cancellation: {
        reason: undefined,
      },
    },
    onSubmit: handleSubmit,
    validationSchema,
    validationOptions: { context: { isCustomDate } },
  });

  const customDate = formValues.cancellationDate;
  const cancellationReason = formValues.cancellation.reason;

  const handleCloseAndPush = () => {
    handleClose();

    if (updateContractQuery.isSuccess) {
      history.push(compile(ROUTES.CONTRACT_DETAILS)({ contractId, contractType }));
      queryCache.invalidateQueries("contract", { contractId });
    }
  };

  const handleCalendarChange = (value, name) => {
    const clonedFormValues = cloneDeep(formValues);

    set(clonedFormValues, name, moment(value).toDate());
    setFormValues(clonedFormValues);
  };

  return (
    <Modal handleClose={handleCloseAndPush} headingText="Create endorsement">
      {!updateContractQuery.isSuccess && (
        <div className="p-6">
          <p className="mb-6">
            In order to calculate your refund and cancel the policy, please provide the following
            details
          </p>

          <p className="mb-6">
            When should the policy be cancelled? <span className="text-red-900">*</span>
          </p>

          <Form onSubmit={onSubmit}>
            <InputRadio
              className="mb-6"
              labelText={`Cancel from inception date (${inceptionDate})`}
              {...getChoiceFieldProps("date_choice", "inception_date")}
            />

            <InputRadio
              className="mb-6"
              labelText="Choose a date"
              {...getChoiceFieldProps("date_choice", "choose_date")}
            />

            {isCustomDate && (
              <InputCalendar
                className="mb-6"
                labelText="What date would you like to cancel from?"
                placeholder="DD/MM/YYYY"
                dayPickerProps={{
                  disabledDays: [
                    {
                      after: moment.utc(contractData.period.end).toDate(),
                      before: moment.utc(contractData.period.start).toDate(),
                    },
                  ],
                }}
                {...getFieldProps("cancellationDate")}
                onChange={handleCalendarChange}
              />
            )}

            {(isInceptionDate || (isCustomDate && customDate)) && (
              <InputSelect
                className="mb-6"
                isRequired
                items={refData.cancellationReasons}
                key={isInceptionDate}
                labelText="What is the reason of the cancellation?"
                initialSelectedItem={cancellationReason}
                {...getFieldProps("cancellation.reason")}
              />
            )}

            {isValid && (
              <div className="flex">
                <Button
                  className="mr-4"
                  isDisabled={updateContractQuery.isLoading}
                  kind="danger-secondary"
                  type="submit"
                >
                  Yes, cancel policy
                </Button>

                <Button
                  onClick={handleCloseAndPush}
                  isDisabled={updateContractQuery.isLoading}
                  type="button"
                >
                  No, I don’t want to cancel
                </Button>
              </div>
            )}
          </Form>
        </div>
      )}

      {updateContractQuery.isSuccess && (
        <div className="p-6">
          <p className="mb-6">Your policy has successfully been cancelled</p>

          <p className="mb-6">
            Your refund will now be processed and you should expect to receive it in the next 3-5
            working days
          </p>

          <Button kind="primary" onClick={handleCloseAndPush}>
            Close window
          </Button>
        </div>
      )}
    </Modal>
  );
};

CancelPolicyModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  contractData: PropTypes.object.isRequired,
  refData: PropTypes.object.isRequired,
};

export default CancelPolicyModal;
